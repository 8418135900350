import React, { useState } from "react";
import { REASON_GROUP } from "../../queries/getReasonGroup";
import { WITHDRAWAL_STATUS } from "../../queries/getWithdrawalStatus";
import { WITHDRAW } from "../../mutations/Withdraw";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Form, Formik, Field } from "formik";
import Select from "react-select";
import { sweetAlert } from "./../common/SweetAlert";
import HeaderProfile from "../common/HeaderProfile";
import ErrorMessages from "../common/ErrorMessages";
import ErrorMessage from "../common/ErrorMessage";
import FileUpload from "../helpers/FileUpload";
import swal from "sweetalert";
import WithdrawalList from "./WithdrawalList";

const WithdrawalForm = (props) => {
  const { loading, data, refetch } = useQuery(WITHDRAWAL_STATUS, {
    fetchPolicy: "no-cache",
  });
  if (loading) return <p>Loading ...</p>;
  if (data && data.withdrawalStatus.error)
    return <ErrorMessage message={data.withdrawalStatus.error} />;
  if (data && data.withdrawalStatus.applied) return <WithdrawalList />;
  if (data && data.withdrawalStatus.success) return <Forms props={props} reload={refetch} />;
  return (
    <div>
      <b>{JSON.stringify(data)}</b>
    </div>
  );
};

const Forms = (props) => {
  const [withdraw] = useMutation(WITHDRAW);
  const { loading, data } = useQuery(REASON_GROUP);
  const [errors, setErrors] = useState();

  if (loading) return <p>Loading ...</p>;
  if (data) groupBy(data);
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-user-minus pr-1"></span>Withdrawal Request Form
        </h3>
      </div>
      <div className="card-body">
        <HeaderProfile />

        <div className="row">
          <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
            <Formik
              initialValues={{
                reason: "",
                additionalInformation: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                let tempErrors = [];

                if (!values["reason"]) {
                  tempErrors.push("Reason is required.");
                }
                if (_.isEmpty(values["additionalInformation"])) {
                  tempErrors.push("Additional Information is required.");
                }
                // if (!values["document"]) {
                //   tempErrors.push("Please attach Document.");
                // }
                setErrors(tempErrors);
                if (tempErrors.length > 0) {
                  setSubmitting(false);
                  return;
                }
                withdraw({ variables: values }).then(
                  (result) => {
                    setSubmitting(false);
                    sweetAlert(result.data.withdraw);
                    props.reload();
                    if (result.data.withdraw.success) {
                      return props.props.history.push("/withdrawals");
                    }
                  },
                  (error) => {
                    props.reload();
                    setSubmitting(false);
                    swal("Error", error);
                  }
                );
              }}
            >
              {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
                <Form>
                  <ErrorMessages errors={errors} />

                  <div className="form-group">
                    <label>Reason</label>
                    <Select
                      onChange={(e) => setFieldValue("reason", e.value, false)}
                      options={groupBy(data)}
                      formatGroupLabel={formatGroupLabel}
                    />
                  </div>

                  <div className="form-group">
                    <label>Additional Information</label>
                    <Field
                      name="additionalInformation"
                      component="textarea"
                      className="form-control"
                      rows="4"
                    />
                  </div>

                  <div className="form-group">
                    <label>Document</label>
                    <Field name="document" component={FileUpload} />
                  </div>

                  <div className="form-group">
                    <input
                      type="submit"
                      value="Submit Withdrawal Request"
                      className="btn btn-primary "
                      disabled={isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

function groupBy(data) {
  return _.chain(data.reasonGroup)
    .map(function (v, i) {
      return {
        label: v["0"],
        options: v["1"].map(function (vv, ii) {
          return {
            value: vv,
            label: vv,
          };
        }),
      };
    })
    .value();
}

export default WithdrawalForm;
