import gql from "graphql-tag";

export const STUDENT_CURRICULUM_TYPE = gql`
  {
    studentCurriculumType
  }
`;

export const STUDENT_CURRICULUM = gql`
  {
    studentCurriculum {
      id
      curriculumType
      offeredAt
      curriculumCourses {
        id
        course {
          id
          courseCode
          courseTitle
          ects
          creditHour
        }
        coursePreRequisites {
          id
          course {
            id
            courseTitle
            courseCode
          }
        }
      }
    }
  }
`;

export const STUDENT_MODULAR_CURRICULUM = gql`
  {
    studentCurriculum {
      id
      curriculumType
      offeredAt
      modularCurriculumCourses {
        id
        moduleCourse{
          id
          course {
            id
            courseCode
            courseTitle
            ects
            creditHour
            lectureHour
          }
          modular{
            id
            moduleTitle
            moduleCode
            moduleEcts
            moduleType
          }
          moduleCoursePreRequisites {
            id
            course {
              id
              courseTitle
              courseCode
            }
          }
        }
      }
    }
  }
`;

