import gql from "graphql-tag";

export default gql`
  {
    readmissions {
      id
      occupation
      leavingDate
      reasonForLeaving
      appStatus
      registration {
        student {
          applicant {
            person {
              fullName
            }
          }
        }
      }
      semester {
        name
      }
      academicYear {
        year
      }
      academicYearSemester {
        semesterName
      }
    }
  }
`;
