import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik, Form, Field } from "formik";
import WField from "../layouts/WField";
import WFormErrors from "../layouts/WFormErrors";
import FileUpload from "../helpers/FileUpload";
import { ExperienceValidator } from "../validations/FormValidation";
import { GET_EXPERIENCE } from "../../queries/find/byId";
import { EDIT_EXPERIENCE_MUTATION } from "../../mutations/experience";
import swal from "sweetalert";

function EditExperience(props) {
  const { loading, data } = useQuery(GET_EXPERIENCE, {
    variables: props.match.params,
  });
  const [updateExperience] = useMutation(EDIT_EXPERIENCE_MUTATION);
  const [updateErrors, setUpdateErrors] = useState({});

  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-edit pr-1"></span>Edit Experience
        </h3>
      </div>
      <Formik
        initialValues={{
          id: data.getExperience.id,
          companyName: data.getExperience.companyName || "",
          title: data.getExperience.title || "",
          location: data.getExperience.location || "",
          from: data.getExperience.from || "",
          to: data.getExperience.to || "",
          description: data.getExperience.description || "",
        }}
        validationSchema={ExperienceValidator}
        onSubmit={(values, { setSubmitting }) => {
          updateExperience({ variables: values }).then(
            (result) => {
              setSubmitting(false);
              swal("Update  Experience", "Experience Updated Successfully!");
              props.history.push("/student_profile");
            },
            (error) => {
              setSubmitting(false);
              swal("Error", error);
              setUpdateErrors(error.graphQLErrors[0].extensions.validation);
            }
          );
        }}
      >
        {({ isSubmitting }) => (
          <div className="card-body row">
            <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
              <WFormErrors errors={updateErrors} />
              <Form>
                <WField wf_type="text" wf_name="companyName" wf_label="Company Name" />
                <WField wf_type="text" wf_name="title" wf_label="Title" />
                <WField wf_type="text" wf_name="location" wf_label="Location" />
                <WField wf_type="date" wf_name="from" wf_label="From" />
                <WField wf_type="date" wf_name="to" wf_label="To" />
                <WField wf_type="text" wf_name="description" wf_label="Description" />
                <div className="form-group row">
                  <div className="col-sm-9 offset-3">
                    <label>Document</label>
                    <Field name="document" component={FileUpload} />
                  </div>
                </div>

                <div className="form-group">
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary ">
                    Update
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default EditExperience;
