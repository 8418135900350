import gql from "graphql-tag";

export const EDIT_EXPERIENCE_MUTATION = gql`
  mutation editExperience(
    $id: ID!
    $companyName: String!
    $title: String!
    $location: String!
    $from: String!
    $to: String!
    $description: String!
    $document: Upload
  ) {
    editExperience(
      id: $id
      companyName: $companyName
      title: $title
      location: $location
      from: $from
      to: $to
      description: $description
      document: $document
    ) {
      id
      companyName
      title
      location
      from
      to
      description
      documentUrl
    }
  }
`;

export const CREATE_EXPERIENCE_MUTATION = gql`
  mutation createExperience(
    $personId: ID!
    $companyName: String!
    $title: String!
    $location: String!
    $from: String!
    $to: String!
    $description: String!
  ) {
    createExperience(
      personId: $personId
      companyName: $companyName
      title: $title
      location: $location
      from: $from
      to: $to
      description: $description
    ) {
      id
      companyName
      title
      location
      from
      to
      description
    }
  }
`;
