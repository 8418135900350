import gql from "graphql-tag";

export const COURSES_TO_BE_EXEMPTED_AND_ALL_AYS = gql`
  {
    allAcademicYearSemester {
      id
      semesterName
    }
    coursesToBeExempted {
      id
      titleAndCode
    }
  }
`;
