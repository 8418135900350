import React from "react";
import HeaderProfile from "./../common/HeaderProfile";

const Transfer = (props) => {
  return (
    <div className="border p-4">
      <HeaderProfile />
      <h3>Transfer ...</h3>
    </div>
  );
};

export default Transfer;
