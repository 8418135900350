import React, { useState } from "react";
import query from "../../queries/getRegistrationForm";
import Status from "../../queries/getRegistrationStatus";
import { useQuery, useMutation } from "@apollo/react-hooks";
import WField from "../layouts/WField";
import ValidCourses from "./ValidCourses";
import { REGISTER } from "../../mutations/Register";
import { Form, Formik } from "formik";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import HeaderProfile from "./../common/HeaderProfile";
import ErrorMessages from "./../common/ErrorMessages";
import { sweetAlert } from "./../common/SweetAlert";
import CalenderNotActivated from "./CalenderNotActivated";
import Payment from "./Payment";
import swal from "sweetalert";
import _ from "lodash";

function getCourses(courses) {
  return _.map(courses, "id");
}

function redirect(props) {
  return props.props.history.push("/registration-slip");
}

const RegistrationForm = (props) => {
  const { loading, data } = useQuery(Status, {
    fetchPolicy: "no-cache",
  });
  if (loading) return <p>Loading ...</p>;
  if (data.registrationStatus.eligibility) {
    sweetAlert({ error: data.registrationStatus.eligibility });
    return <Redirect to="/" />;
  }
  if (data.registrationStatus.can_register) return <RegisterForm props={props} />;
  if (data.registrationStatus.payment_form)
    return <Payment props={props} message={data.registrationStatus.payment_form} />;
  if (data.registrationStatus.calendar_not_activated) return <CalenderNotActivated />;
  if (data.registrationStatus.can_view_registration_slip)
    return <Redirect to="registration-slip" />;
  if (data.registrationStatus.not_permitted) {
    sweetAlert({ error: "You are not permitted for this operation." });
    return <Redirect to="/" />;
  }

  return <div>{JSON.stringify(data.registrationStatus)}</div>;
};

const RegisterForm = (props) => {
  const [elective, setElective] = useState([]);
  const { loading, data } = useQuery(query);
  const [register] = useMutation(REGISTER);
  const [errors, setErrors] = useState();

  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-list pr-1"></span>Registration Form
        </h3>
      </div>
      <div className="card-body">
        <hr />
        <HeaderProfile />
        <Formik
          initialValues={{
            academicSemester: "",
            cafeStatus: "",
            section: "",
            bankAccount: "",
            courses: getCourses(data.registrationForm.valid_courses),
          }}
          onSubmit={(values, { setSubmitting }) => {
            values.courses = [...values.courses, ...elective.map(Number)];
            let tempErrors = [];

            if (!values["academicSemester"]) {
              tempErrors.push("Academic Semester is required.");
            }
            if (_.isEmpty(values["cafeStatus"]) && data.registrationForm.cafe_status_required) {
              tempErrors.push("Cafe Status is required.");
            }
            // if (
            //   _.isEmpty(values["bankAccount"]) ||
            //   (values["bankAccount"].length !== 13 && /^\d+$/.test(values["bankAccount"]))
            // ) {
            //   tempErrors.push(" Please provide a correct CBE Bank Account.");
            // }
            setErrors(tempErrors);
            if (tempErrors.length > 0) {
              setSubmitting(false);
              return;
            }
            register({ variables: values }).then(
              (result) => {
                setSubmitting(false);
                sweetAlert(result.data.register);
                if (result.data.register.success) {
                  redirect(props);
                }
              },
              (error) => {
                setSubmitting(false);
                swal("Error", error);
              }
            );
          }}
        >
          {({ values, touched, handleSubmit, setFieldValue, isSubmitting }) => (
            <Form>
              <ErrorMessages errors={errors} />

              <div className="row">
                <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-md-2 offset-lg-2">
                  <div className="form-group">
                    <label>Academic Semester</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={data.registrationForm.academic_semester}
                      onChange={(e) => setFieldValue("academicSemester", parseInt(e.value), false)}
                    />
                  </div>

                  {
                    data.registrationForm.cafe_status_required &&
                    <div className="form-group">
                      <label>Cafe Status</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={data.registrationForm.cafe_status}
                        onChange={(e) => setFieldValue("cafeStatus", e.value, false)}
                      />
                    </div>
                  }

                  <div className="form-group">
                    <label>Section</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={data.registrationForm.section}
                      onChange={(e) => setFieldValue("section", e.value, false)}
                    />
                  </div>

                  <WField wf_type="text" wf_name="bankAccount" wf_label="Bank Account" />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-10 offset-1">
                  <ValidCourses
                    valid_courses={data.registrationForm.valid_courses}
                    elective_courses={data.registrationForm.elective_courses}
                    setElective={setElective}
                    elective={elective}
                  />
                  <div className="text-center mt-4">
                    <input
                      type="submit"
                      value="Register"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RegistrationForm;
