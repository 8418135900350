import React from "react";
import READMISSION_STATUS from "../../queries/getReadmissionStatus";
import { useQuery } from "@apollo/react-hooks";
import HeaderProfile from "../common/HeaderProfile";
import ReadmissionForm from "./ReadmissionForm";

const Readmission = (props) => {
  const { loading, data } = useQuery(READMISSION_STATUS, {
    fetchPolicy: "no-cache",
  });
  if (loading) return <p>Loading ...</p>;
  if (data.readmissionStatus.readmission_requirement) return <ReadmissionRequirement />;
  if (data.readmissionStatus.error)
    return (
      <div className="card card-primary">
        <div className="card-header bg-info">
          <h3 className="card-title">
            <span className="fas fa-redo pr-1"></span>Readmission
          </h3>
        </div>
        <div className="card-body row">
          <HeaderProfile />
          <div className="alert alert-info col-sm-12 col-md-6 col-lg-6">
            <h5>
              <i className="icon fas fa-info"></i> Alert!
            </h5>
            {data.readmissionStatus.error}
          </div>
        </div>
      </div>
    );
  return <ReadmissionForm props={props} />;
};

const ReadmissionRequirement = (props) => {
  return (
    <div className="card card-primary">
      <div className="card-header bg-danger">
        <h3 className="card-title text-bold">
          <span className="fas fa-exclamation-triangle pr-1"></span>
          Readmission Requirement
        </h3>
      </div>
      <div className="card-body">
        <h6 className="text-danger">
          <b>You Are Completely Dismissed</b>
        </h6>
        <p>
          <u>Dear Student</u> You can't apply for Readmission because
        </p>
        <p>The minimum requirement for re-admission is as follows</p>
        <ul>
          <li>for 1st year 1st semester student the minimum CGPA is 1.00</li>
          <li>for 1st year 2nd semester student the minimum CGPA is 1.50</li>
          <li>for 2nd year or 3rd year first semester the minimum CGPA is 1.75</li>
          <li>
            If your program is a 4 year program and you are 3rd year 2nd semester or 4th year 1st
            semester student the minimum CGPA is 1.85
          </li>
          <li>
            If your program is a 5 year program and you are 3rd year 2nd semester or 4th year 1st
            semester student the minimum CGPA is 1.80
          </li>
          <li>for 4th year 2nd semester or 5th year first semester the minimum CGPA is 1.92</li>
        </ul>
      </div>
    </div>
  );
};

export default Readmission;
