import gql from "graphql-tag";

export const EDIT_EDUCATION_MUTATION = gql`
  mutation editEducation(
    $id: ID!
    $institution: String!
    $studyField: String!
    $awardType: String!
    $qualification: String!
    $from: String!
    $to: String!
    $grade: Float!
    $scale: Float!
  ) {
    editEducation(
      id: $id
      institution: $institution
      studyField: $studyField
      awardType: $awardType
      qualification: $qualification
      from: $from
      to: $to
      grade: $grade
      scale: $scale
    ) {
      id
      institution
      studyField
      qualification
      from
      to
      grade
      scale
      awardType
    }
  }
`;

export const CREATE_EDUCATION_MUTATION = gql`
  mutation createEducation(
    $personId: ID!
    $institution: String!
    $studyField: String!
    $awardType: String!
    $qualification: String!
    $from: String!
    $to: String!
    $grade: Float!
    $scale: Float!
  ) {
    createEducation(
      personId: $personId
      institution: $institution
      studyField: $studyField
      awardType: $awardType
      qualification: $qualification
      from: $from
      to: $to
      grade: $grade
      scale: $scale
    ) {
      id
      institution
      studyField
      qualification
      from
      to
      grade
      scale
      awardType
    }
  }
`;
