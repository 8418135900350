import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik, Form, Field } from "formik";
import WField from "../layouts/WField";
import WFselect from "../layouts/WFselect";
import WFormErrors from "../layouts/WFormErrors";
import FileUpload from "../helpers/FileUpload";
import { PersonValidator } from "../validations/FormValidation";
import { GET_PERSON } from "../../queries/find/byId";
import { EDIT_PERSON_MUTATION } from "../../mutations/person";
import swal from "sweetalert";
import {
  REGION_QUERY,
  MARITAL_STATUS_QUERY,
  DISABILITY_QUERY,
  NATIONALITY_QUERY,
} from "../../queries/constants";

function EditPerson(props) {
  const { loading, data } = useQuery(GET_PERSON, {
    variables: props.match.params,
  });
  const [updatePerson] = useMutation(EDIT_PERSON_MUTATION, {
    refetchQueries: [
      {
        query: GET_PERSON,
        variables: props.match.params,
      },
    ],
  });
  const [updateErrors, setUpdateErrors] = useState({});

  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-edit pr-1"></span>Edit Person
        </h3>
      </div>
      <Formik
        initialValues={{
          id: data.getPerson.id,
          amharicFirstName: data.getPerson.amharicFirstName || "",
          amharicFatherName: data.getPerson.amharicFatherName || "",
          amharicGrandFatherName: data.getPerson.amharicGrandFatherName || "",
          maritalStatus: data.getPerson.maritalStatus || "",
          nationality: data.getPerson.nationality || "",
          regionId: data.getPerson.region?.id || "",
          disability: data.getPerson.disability || "",
          dateOfBirth: data.getPerson.dateOfBirth || "",
          placeOfBirth: data.getPerson.placeOfBirth || "",
        }}
        validationSchema={PersonValidator}
        onSubmit={(values, { setSubmitting }) => {
          updatePerson({ variables: values }).then(
            (result) => {
              setSubmitting(false);
              swal("Update  Person", "Person Updated Successfully!");
              props.history.push("/student_profile");
            },
            (error) => {
              setSubmitting(false);
              swal("Error", error);
              setUpdateErrors(error.graphQLErrors[0].extensions.validation);
            }
          );
        }}
      >
        {({ isSubmitting }) => (
          <div className="card-body row">
            <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
              <WFormErrors errors={updateErrors} />
              <Form>
                <WField wf_type="text" wf_name="amharicFirstName" wf_label="ስም" />
                <WField wf_type="text" wf_name="amharicFatherName" wf_label="የአባት ስም" />
                <WField wf_type="text" wf_name="amharicGrandFatherName" wf_label="የአያት ስም" />
                <WFselect
                  ws_name="maritalStatus"
                  ws_query={MARITAL_STATUS_QUERY}
                  ws_label="Marital Status"
                />
                <WFselect
                  ws_name="nationality"
                  ws_query={NATIONALITY_QUERY}
                  ws_label="Nationality"
                  hash={true}
                />
                <WFselect ws_name="regionId" hash={true} ws_query={REGION_QUERY} ws_label="Region" />
                <WFselect ws_name="disability" ws_query={DISABILITY_QUERY} ws_label="Disability" />
                <WField wf_type="date" wf_name="dateOfBirth" wf_label="Date Of Birth" />
                <WField wf_type="text" wf_name="placeOfBirth" wf_label="Place Of Birth" />
                <div className="form-group">
                  <label>Photo</label>
                  <Field name="photo" component={FileUpload} />
                </div>

                <div className="form-group">
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary ">
                    {isSubmitting === true ? "Loading ..." : "Update"}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default EditPerson;
