import React, { useState } from "react";
import HeaderProfile from "../common/HeaderProfile";
import { sweetAlert } from "../common/SweetAlert";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { COURSES_TO_BE_EXEMPTED_AND_ALL_AYS } from "../../queries/getCoursesToBeExemptedAndAYS";
import { EXEMPT_COURSES } from "../../mutations/ExemptCourses";
import { Form, Formik, FieldArray, Field } from "formik";
import WField from "../layouts/WField";
import Select from "react-select";
import { FaTrash, FaPlus } from "react-icons/fa";
import ErrorMessages from "../common/ErrorMessages";
import FileUpload from "../helpers/FileUpload";
import swal from "sweetalert";
import _ from "lodash";

const CourseExemptionForm = (props) => {
  const { loading, data } = useQuery(COURSES_TO_BE_EXEMPTED_AND_ALL_AYS);
  const [exemptCourse] = useMutation(EXEMPT_COURSES);
  const [errors, setErrors] = useState();
  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-ban pr-1"></span>Course Exemption Form
        </h3>
      </div>
      <div className="card-body">
        <hr />
        <HeaderProfile />
        <ErrorMessages errors={errors} />
        <Formik
          initialValues={{
            instituteName: "",
            equivalentSemester: "",
            courses: [
              {
                exemptedCourse: "",
                equivalentCourse: "",
                creditHour: "",
                ects: "",
                grade: "",
              },
            ],
          }}
          onSubmit={(values, { setSubmitting }) => {
            let tempErrors = [];

            if (_.isEmpty(values["instituteName"])) {
              tempErrors.push("Institute Name is required");
            }
            if (_.isEmpty(values["equivalentSemester"])) {
              tempErrors.push("Select Equivalent Semester");
            }
            if (!values["document"]) {
              tempErrors.push("Please attach Document.");
            }
            if (_.isEmpty(values["courses"])) {
              tempErrors.push("Minimum of one Course is required");
            }
            values["courses"].map(function (course, index) {
              if (!course.exemptedCourse) {
                tempErrors.push("Exemepted Course for " + (index + 1) + " is required");
              }
              if (!course.equivalentCourse) {
                tempErrors.push("Equivalent Course for " + (index + 1) + " is required");
              }
              if (!course.creditHour) {
                tempErrors.push("Credit Hour for " + (index + 1) + " is required");
              }
              if (!course.ects) {
                tempErrors.push("Ects for " + (index + 1) + " is required");
              }
              if (!course.grade) {
                tempErrors.push("Grade or Mark for " + (index + 1) + " is required");
              }
              return tempErrors;
            });
            setErrors(tempErrors);
            if (tempErrors.length > 0) {
              setSubmitting(false);
              return;
            }
            exemptCourse({ variables: values }).then(
              (result) => {
                setSubmitting(false);
                sweetAlert(result.data.exemptCourses);
                if (result.data.exemptCourses.success) {
                  props.history.push("course-exemption-list");
                }
              },
              (error) => {
                setSubmitting(false);
                swal("Error", error);
              }
            );
          }}
        >
          {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="row">
                <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-md-2 offset-lg-2">
                  <WField wf_type="text" wf_name="instituteName" wf_label="Institute Name" />

                  <div className="form-group">
                    <label>Equivalent Semester</label>
                    <Select
                      name="equivalentSemester"
                      className="basic-single"
                      classNamePrefix="select"
                      options={groupAYS(data)}
                      onChange={(e) => {
                        setFieldValue("equivalentSemester", e.value, false);
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label>Document</label>
                    <Field name="document" component={FileUpload} />
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table   text-nowrap">
                  <thead>
                    <tr>
                      <th colSpan="2"> Course to Exempt</th>
                      <th>Equivalent Course</th>

                      <th>Cr Hr.</th>
                      <th>ECTS</th>
                      <th>Grade/Mark</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <FieldArray
                    name="courses"
                    render={(arrayHelpers) => (
                      <tbody>
                        {values.courses && values.courses.length > 0
                          ? values.courses.map((course, index) => (
                              <tr key={index}>
                                <td colSpan="2">
                                  <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name={"courses[" + index + "][exemptedCourse]"}
                                    options={groupExemptedCourses(data)}
                                    onChange={(e) => {
                                      let field = "courses[" + index + "][exemptedCourse]";
                                      setFieldValue(field, parseInt(e.value), false);
                                    }}
                                  />
                                </td>
                                <td>
                                  <Field
                                    className="form-control"
                                    id={`courses[${index}][equivalentCourse]`}
                                    name={`courses[${index}][equivalentCourse]`}
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <Field
                                    className="form-control"
                                    name={`courses[${index}][creditHour]`}
                                    type="number"
                                  />
                                </td>
                                <td>
                                  <Field
                                    className="form-control"
                                    name={`courses[${index}][ects]`}
                                    type="number"
                                  />
                                </td>
                                <td>
                                  <Field
                                    className="form-control"
                                    name={`courses[${index}][grade]`}
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <button
                                    className="btn  btn-danger"
                                    type="button"
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }} // remove a friend from the list
                                  >
                                    <FaTrash />
                                  </button>
                                </td>
                              </tr>
                            ))
                          : null}
                        <tr>
                          <td>
                            <button
                              type="button"
                              className="btn btn-link  mt-3 mb-2"
                              onClick={() =>
                                arrayHelpers.push({
                                  exemptedCourse: "",
                                  equivalentCourse: "",
                                  creditHour: "",
                                  ects: "",
                                  grade: "",
                                })
                              }
                            >
                              <FaPlus /> Add Course
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  />
                </table>
              </div>

              <div className="text-center mt-4">
                <input
                  type="submit"
                  value="Submit Course Exemption Request"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

function groupAYS(data) {
  return _.chain(data.allAcademicYearSemester)
    .map(function (v) {
      return { value: v.id, label: v.semesterName };
    })
    .value();
}
function groupExemptedCourses(data) {
  return _.chain(data.coursesToBeExempted)
    .map(function (v) {
      return { value: v.id, label: v.titleAndCode };
    })
    .value();
}

export default CourseExemptionForm;
