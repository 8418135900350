import React, { useState } from "react";
import { GET_COURSE_ASSIGNMENTS_BY_COURSE_OFFERING } from "./../../queries/find/byId";
import { useQuery } from "@apollo/react-hooks";
import Select from "react-select";
import LoadSections from "./LoadSections";
import _ from "lodash";

let courseAssignmentId = null;
const LoadInstructor = (props) => {
  // if (props.offeredCourse == 0) return null;
  const [courseAssignment, setCourseAssignment] = useState(0);
  const { loading, data } = useQuery(GET_COURSE_ASSIGNMENTS_BY_COURSE_OFFERING, {
    variables: { courseOfferingId: props.offeredCourse },
  });
  if (loading) return <p>Loading ...</p>;
  return (
    <div>
      <div className="form-group row">
        <label className="col-sm-3 col-form-label">Instructor</label>
        <div className="col-sm-9">
          <Select
            name="courseAssignment"
            options={groupBy(data)}
            onChange={(e) => {
              courseAssignmentId = e.value;
              setCourseAssignment(e.value);
            }}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
      </div>
      {courseAssignment !== 0 ? <LoadSections courseAssignment={courseAssignment} /> : ""}
    </div>
  );
};

function groupBy(data) {
  return _.chain(data.courseAssignments)
    .map(function (v, i) {
      return {
        value: v["id"],
        label: v["employee"]["person"]["fullName"] +  (v["isAddQuotaFull"] ? " => Add Quota Full" : ""),
        isDisabled:  v["isAddQuotaFull"] ? true : false
      };
    })
    .value();
}

const courseAssignmentValue = () => {
  return courseAssignmentId;
};

export { courseAssignmentValue };
export default LoadInstructor;
