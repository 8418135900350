import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import FooterAuth from "../layouts/FooterAuth";

const ForgotPassword = (props) => {
  document.getElementById("body").classList.remove("sidebar-mini");
  document.getElementById("body").classList.add("login-page");
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const submit = (event) => {
    setBtnDisabled(true);
    event.preventDefault();
    setSuccess(false);
    if (!email) {
      setBtnDisabled(false);
      return setError("Email is required.");
    } else {
      setError(null);
    }
    const signinData = new FormData();
    signinData.append("email", email);
    signinData.append("redirect_url", `${window.location.origin}/auth/password/edit`);
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/password`, signinData)
      .then((res) => {
        setBtnDisabled(false);
        setSuccess(true);
      })
      .catch((error) => {
        setBtnDisabled(false);
        if (error["message"] === "Request failed with status code 404") {
          setError("Could not found user with that email.");
        }
      });
  };

  return (
    <div className="login-box">
      <div className="login-logo">
        <a href="http://www.astu.edu.et/">
          <img
            src="/dist/img/astu_logo.svg"
            alt="AdminLTE Logo"
            className="img-circle"
            width="20%"
          />
          <br />
          <b>ASTU</b> E-Student
        </a>
      </div>
      <div className="card">
        <div className="card-body login-card-body">
          <p className="login-box-msg">
            You forgot your password? Here you can easily retrieve a new password.
          </p>
          {success ? <SuccessMessage /> : ""}
          <form onSubmit={submit}>
            <div className="input-group">
              <input
                className={`form-control`}
                id="email"
                name="email"
                type="text"
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                placeholder="Email"
                disabled={btnDisabled}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <span className="text-danger bold">{error}</span>

            <div className="row mt-4">
              <div className="col-12">
                <button type="submit" className="btn btn-primary btn-block" disabled={btnDisabled}>
                  {btnDisabled ? "Recovering..." : "Send Password Reset Instruction"}
                </button>
              </div>
            </div>
          </form>
          <p className="mt-3 mb-1">
            <Link to="/">Login</Link>
          </p>
        </div>
      </div>
      <div className="nonfooter mt-4">
        <FooterAuth />
      </div>
    </div>
  );
};

const SuccessMessage = () => {
  return (
    <div className="alert alert-info text-sm text-bold" role="alert">
      <p>We have mailed you the password reset instruction.</p>
    </div>
  );
};

export default ForgotPassword;
