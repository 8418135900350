import React from "react";
import { GET_PAYMENT } from "../queries/getPayment";
import { useQuery } from "@apollo/react-hooks";

const Payment = (props) => {
  const { loading, data, refetch } = useQuery(GET_PAYMENT);
  if (loading) return <p>Loading ...</p>;
  if (props.updateList) {
    refetch();
  }

  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-money-check-alt pr-1"></span>Payments
        </h3>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table  table-bordered text-nowrap">
            <thead>
              <tr>
                <th> Academic Semester</th>
                <th>Payment type</th>

                <th>Total (birr)</th>
                <th>Payment status</th>
              </tr>
            </thead>
            <tbody>
              {data.payment &&
                data.payment.map(({ id, aysCy, price, lbl, pType, status }) => (
                  <tr key={id}>
                    <td>{aysCy}</td>
                    <td>{pType}</td>
                    <td>{price}</td>
                    <td className={status === "Paid" ? "bold text-success" : "bold text-danger"}>
                      {status}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Payment;
