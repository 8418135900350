import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { DELETE_EXPERIENCE_MUTATION } from "../../mutations/delete/byId";

function Experiences(props) {
  const { loading, data } = useQuery(props.query, {
    variables: { personId: props.person_id },
  });
  const [deleteExperience] = useMutation(DELETE_EXPERIENCE_MUTATION, {
    update(cache, { data: { deleteExperience } }) {
      const { getExperiences } = cache.readQuery({
        query: props.query,
        variables: { personId: props.person_id },
      });
      cache.writeQuery({
        query: props.query,
        variables: { personId: props.person_id },
        data: {
          getExperiences: getExperiences.filter(
            (education) => education.id !== deleteExperience.id
          ),
        },
      });
    },
  });

  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Title</th>
                <th>Location</th>
                <th>From</th>
                <th>To</th>
                <th>Description</th>
                <th>Document</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.getExperiences.map(
                ({
                  id,
                  companyName,
                  title,
                  location,
                  from,
                  to,
                  description,
                  documentUrl,
                }) => (
                  <tr key={id}>
                    <td>{companyName}</td>
                    <td>{title}</td>
                    <td>{location}</td>
                    <td>{from}</td>
                    <td>{to}</td>
                    <td>{description}</td>
                    <td>
                      <a href={`${process.env.REACT_APP_API_URL}${documentUrl}`}>Document</a>
                    </td>
                    <td>
                      <Link to={`/student_profile/${id}/edit_experience`}>
                        Edit
                      </Link>
                      {" | "}
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          if (
                            window.confirm("Are you sure you wish to delete?")
                          )
                            deleteExperience({ variables: { id: id } });
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer">
        <Link to={`/student_profile/${props.person_id}/create_experience`}>
          New
        </Link>
      </div>
    </div>
  );
}

export default Experiences;
