import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import ListItem from "../layouts/ListItem";
import _ from "lodash";

function ContactAddress(props) {
  const { loading, data } = useQuery(props.query, {
    variables: { personId: props.person_id },
  });
  if (loading) return <p>Loading ...</p>;
  if (_.isEmpty(data.getContactAddress))
    return (
      <div>
        <p>No Contact Address Found</p>
        <Link to={`/student_profile/${props.person_id}/create_contact_address`}>
          New
        </Link>
      </div>
    );

  const {
    country,
    streetAddress,
    homeTelephone,
    mobile,
    workTelephone,
    email,
    zone,
    woreda,
    kebele
  } = data.getContactAddress;
  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <ListItem label="Country" data={country} />
        <ListItem label="Street Address" data={streetAddress} />
        <ListItem label="Home Telephone" data={homeTelephone} />
        <ListItem label="Mobile" data={mobile} />
        <ListItem label="Work Telephone" data={workTelephone} />
        <ListItem label="Email" data={email} />
        <ListItem label="Zone" data={zone?.name} />
        <ListItem label="Woreda" data={woreda} />
        <ListItem label="Kebele" data={kebele} />
      </div>
      <div className="card-footer">
        <Link to={`/student_profile/${props.person_id}/edit_contact_address`}>
          Edit
        </Link>
      </div>
    </div>
  );
}

export default ContactAddress;
