import React from "react";
import LatestEvents from "./events/LatestEvents";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Formik, Form } from "formik";
import WField from "./layouts/WField";
import swal from "sweetalert";
import { UPDATE_BANK_ACCOUNT_MUTATION } from "../mutations/updateBankAccount";
import { BankAccountValidator } from "./validations/FormValidation";

const BLANK_CBE_QUERY = gql`
  {
    blankCbe
  }
`;

function Cbe(props) {
  const [updateBankAccount] = useMutation(UPDATE_BANK_ACCOUNT_MUTATION);
	return (
		<div className="card card-primary">
	      <div className="card-header bg-info">
	        <h3 className="card-title">
	          <span className="fas fa-edit pr-1"></span>CBE Account is mandatory!
	        </h3>
	      </div>
	      <Formik
	        initialValues={{
	          bankAccount: ""
	        }}
          validationSchema={BankAccountValidator}
	        onSubmit={(values, { setSubmitting }) => {
	          updateBankAccount({ variables: values }).then(
	            (result) => {
	              setSubmitting(false);
	              swal("CBE Account", "CBE Account Saved Successfully!");
	              window.location.reload();
	            },
	            (error) => {
	              setSubmitting(false);
	            }
	          );
	        }}
	      >
	        {({ isSubmitting }) => (
	          <div className="card-body row">
	            <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
	              <Form>
	                <WField wf_type="bankAccount" wf_name="bankAccount" wf_label="CBE Account Number" />
	                <div className="form-group">
	                  <button type="submit" disabled={isSubmitting} className="btn btn-primary ">
	                    Save
	                  </button>
	                </div>
	              </Form>
	            </div>
	          </div>
	        )}
	      </Formik>
	    </div>
	);
}

const Home = () => {
  const { loading, data } = useQuery(BLANK_CBE_QUERY);
  if (loading) return <p>Loading ...</p>;

  if (data.blankCbe) return <Cbe />;
  return (
    <div>
      <LatestEvents />
    </div>
  );
};

export default Home;
