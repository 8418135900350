import gql from "graphql-tag";

export const REGISTER = gql`
  mutation register(
    $cafeStatus: String!
    $section: String
    $academicSemester: Int!
    $bankAccount: String!
    $courses: [Int!]!
  ) {
    register(
      cafeStatus: $cafeStatus
      section: $section
      academicSemester: $academicSemester
      bankAccount: $bankAccount
      courses: $courses
    )
  }
`;
