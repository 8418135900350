import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik, Form } from "formik";
import WField from "../layouts/WField";
import WFormErrors from "../layouts/WFormErrors";
import { FamilyBackgroundValidator } from "../validations/FormValidation";
import { GET_FAMILY_BACKGROUND } from "../../queries/find/byId";
import { EDIT_FAMILY_BACKGROUND_MUTATION } from "../../mutations/familyBackground";
import swal from "sweetalert";

function EditFamilyBackground(props) {
  const { loading, data } = useQuery(GET_FAMILY_BACKGROUND, {
    variables: props.match.params,
  });
  const [updateFamilyBackground] = useMutation(EDIT_FAMILY_BACKGROUND_MUTATION);
  const [updateErrors, setUpdateErrors] = useState({});

  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-edit pr-1"></span>Edit Family Background
        </h3>
      </div>
      <Formik
        initialValues={{
          id: data.getFamilyBackground.id,
          fullName: data.getFamilyBackground.fullName || "",
          relation: data.getFamilyBackground.relation || "",
          dateOfBirth: data.getFamilyBackground.dateOfBirth || "",
          educationLevel: data.getFamilyBackground.educationLevel || "",
          occupation: data.getFamilyBackground.occupation || "",
        }}
        validationSchema={FamilyBackgroundValidator}
        onSubmit={(values, { setSubmitting }) => {
          updateFamilyBackground({ variables: values }).then(
            (result) => {
              setSubmitting(false);
              swal("Update  Family Background", "Family Background Updated Successfully!");
              props.history.push("/student_profile");
            },
            (error) => {
              setSubmitting(false);
              setUpdateErrors(error.graphQLErrors[0].extensions.validation);
            }
          );
        }}
      >
        {({ isSubmitting }) => (
          <div className="card-body row">
            <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
              <WFormErrors errors={updateErrors} />
              <Form>
                <WField wf_type="text" wf_name="fullName" wf_label="Full Name" />
                <WField wf_type="text" wf_name="relation" wf_label="Relation" />
                <WField wf_type="date" wf_name="dateOfBirth" wf_label="Date of Birth" />
                <WField wf_type="text" wf_name="educationLevel" wf_label="Education Level" />
                <WField wf_type="text" wf_name="occupation" wf_label="Occupation" />
                <div className="form-group">
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary ">
                    Update
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default EditFamilyBackground;
