import gql from "graphql-tag";

export default gql`
  {
    withdrawals {
      id
      reason
      additionalInformation
      hodRecommendation
      status
      requestedDate
    }
  }
`;
