import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Formik, Form } from "formik";
import WField from "../layouts/WField";
import WFormErrors from "../layouts/WFormErrors";
import { FamilyBackgroundValidator } from "../validations/FormValidation";
import { GET_FAMILY_BACKGROUNDS } from "../../queries/find/byId";
import { CREATE_FAMILY_BACKGROUND_MUTATION } from "../../mutations/familyBackground";
import swal from "sweetalert";

function CreateFamilyBackground(props) {
  const [createFamilyBackground] = useMutation(CREATE_FAMILY_BACKGROUND_MUTATION, {
    refetchQueries: [
      {
        query: GET_FAMILY_BACKGROUNDS,
        variables: { personId: props.match.params.personId },
      },
    ],
  });
  const [createErrors, setCreateErrors] = useState({});

  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-plus pr-1"></span>Add Family Background
        </h3>
      </div>
      <Formik
        initialValues={{
          personId: props.match.params.personId,
          fullName: "",
          relation: "",
          dateOfBirth: "",
          educationLevel: "",
          occupation: "",
        }}
        validationSchema={FamilyBackgroundValidator}
        onSubmit={(values, { setSubmitting }) => {
          createFamilyBackground({ variables: values }).then(
            (result) => {
              setSubmitting(false);
              swal("Create Family Background", "Family Background Created Successfully!");
              props.history.push("/student_profile");
            },
            (error) => {
              setSubmitting(false);
              setCreateErrors(error.graphQLErrors[0].extensions.validation);
            }
          );
        }}
      >
        {({ isSubmitting }) => (
          <div className="card-body row">
            <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
              <WFormErrors errors={createErrors} />
              <Form>
                <WField wf_type="text" wf_name="fullName" wf_label="Full Name" />
                <WField wf_type="text" wf_name="relation" wf_label="Relation" />
                <WField wf_type="date" wf_name="dateOfBirth" wf_label="Date of Birth" />
                <WField wf_type="text" wf_name="educationLevel" wf_label="Education Level" />
                <WField wf_type="text" wf_name="occupation" wf_label="Occupation" />
                <div className="form-group">
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary ">
                    Create
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default CreateFamilyBackground;
