import gql from "graphql-tag";

export default gql`
  {
    registrationSlip {
      id
      cafeStatus
      academicYearSemester {
        id
        semesterName
      }
      student {
        id
        idNumber
        section
        classYear
        status
        program {
          id
          fullName
        }
        applicant {
          id
          admissionYear
          admission {
            id
            name
          }
          person {
            id
            fullName
          }
        }
      }
      courseEnrollments {
        id
        instructorName
        course {
          id
          courseCode
          courseTitle
          ects
          creditHour
        }
      }
    }
  }
`;
