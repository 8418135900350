import React from "react";

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="float-right d-none d-sm-block">
        <small>
          <b>Version</b> 3.0
        </small>
      </div>
      <small>
        Copyright &copy; 2021 <a href="http://wie.et">Winner Systems</a>.
      </small>
      All rights reserved.
    </footer>
  );
};

export default Footer;
