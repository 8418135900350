import React from "react";
import _ from "lodash";

const ExemptedCourses = (props) => {
  if (_.isEmpty(props.data)) return null;
  return (
    <div>
      <div className="mb-4">
        <h6 className="text-bold">Exempted Courses</h6>
        <div className="table-responsive">
          <table className="table table-striped text-nowrap">
            <thead bgcolor="brown" className="thead text-white">
              <tr>
                <th>Code</th>
                <th>Course Title</th>
                <th>Credit Hour</th>
                <th>Ects</th>
                <th>Grade</th>
                <th>Grade Point</th>
              </tr>
            </thead>
            <tbody>
              {groupByCourseExemptionDetail(props.data).map(
                ({ id, course, equivalentCourseCreditHour, equivalentCourseEcts, gradeOrMark }) => (
                  <tr key={id}>
                    <td>{course.courseCode}</td>
                    <td>{course.courseTitle}</td>
                    <td>{equivalentCourseCreditHour}</td>
                    <td>{equivalentCourseEcts}</td>
                    <td>{gradeOrMark}</td>
                    <td>Ex</td>
                  </tr>
                )
              )}
            </tbody>
            <tfoot bgcolor="darkgray" align="left" className="tfoot">
              <tr>
                <td colSpan="6"></td>
              </tr>
              <tr>
                <td colSpan="6"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};
function groupByCourseExemptionDetail(data) {
  let ced = _.map(data, "courseExemptionDetails");
  ced = _.flatten(ced);
  ced = _.filter(ced, { approval: true });
  return ced;
}

export default ExemptedCourses;
