import React from "react";
import query from "../../queries/getGpaStatus";
import ListItem from "../layouts/ListItem";

import { useQuery } from "@apollo/react-hooks";

function GpaStatus() {
  const { loading, data } = useQuery(query);
  if (loading) return <p>Loading ...</p>;
  if (!data) return <p>No GPA Status.</p>;

  const { status, semesterGpa, cumulativeGpa } = data.gpaStatus;
  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <h5>Grade Point Average (GPA) summary</h5>
        <hr />
        <ListItem label="Academic status" data={status} />
        <ListItem label="Semester GPA" data={semesterGpa} />
        <ListItem label="Cumulative GPA" data={cumulativeGpa} />
      </div>
    </div>
  );
}

export default GpaStatus;
