import React from "react";
import query from "../../queries/getTransfers";
import _ from "lodash";
import { useQuery } from "@apollo/react-hooks";

function Transfers() {
  const { loading, data } = useQuery(query);
  if (loading) return <p>Loading ...</p>;
  if (_.isEmpty(data.transfers))
    return <p className="error-info">No Transfer History</p>;
  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Transfer type</th>
                <th>To institution</th>
                <th>Program </th>
                <th>Effective academic year and semester</th>
                <th>Reason</th>
                <th>hod1_comment</th>
                <th>hod1_status</th>
                <th>hod2_comment</th>
                <th>hod2_status</th>
              </tr>
            </thead>
            <tbody>
              {data.transfers.map(
                ({
                  id,
                  transferType,
                  toInstitution,
                  effectiveAcademicYearAndSemester,
                  reason,
                  hod1Comment,
                  hodStatus1,
                  hod2Comment,
                  hodStatus2,
                  program,
                }) => (
                  <tr key={id}>
                    <td>{transferType}</td>
                    <td>{toInstitution}</td>
                    <td>{program}</td>
                    <td>{effectiveAcademicYearAndSemester}</td>
                    <td>{reason}</td>
                    <td>{hod1Comment}</td>
                    <td>{hodStatus1} </td>
                    <td>{hod2Comment}</td>
                    <td>{hodStatus2} </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Transfers;
