import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { LAST_WITHDRAWAL } from "../../queries/getLastWithdrawal";
import ListItem from "../layouts/ListItem";

const WithdrawalList = () => {
  const { loading, data } = useQuery(LAST_WITHDRAWAL, {
    fetchPolicy: "no-cache",
  });
  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-user-minus pr-1"></span>Withdrawal
        </h3>
      </div>
      <div className="card-body">
        <ListItem label="Full Name" data={data.lastWithdrawal.student.applicant.person.fullName} />
        <ListItem label="ID NUmber" data={data.lastWithdrawal.student.idNumber} />

        <ListItem label="Withdrawal Reason" data={data.lastWithdrawal.reason} />

        <ListItem label="Addition Information" data={data.lastWithdrawal.additionalInformation} />

        <ListItem label="Requested date" data={data.lastWithdrawal.requestedDate} />

        <p>
          <strong className="label-colen">Status: </strong>
          <strong
            className={
              data.lastWithdrawal.status === 1
                ? "text-success"
                : data.lastWithdrawal.status === 0
                ? "text-danger"
                : "text-primary"
            }
          >
            {data.lastWithdrawal.status === 1
              ? "Approved"
              : data.lastWithdrawal.status === 0
              ? "Declined"
              : "Pending"}
          </strong>
        </p>
        <a
          className="btn btn-link"
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_API_URL}${data.lastWithdrawal.documentUrl}`}
        >
          View Document
        </a>
      </div>
    </div>
  );
};

export default WithdrawalList;
