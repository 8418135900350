import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient } from "apollo-client";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { createBrowserHistory } from "history";
import { Route } from "react-router-dom";
import { Router } from "react-router";
import App from "./components/App";
import "./assets/stylesheets/index.css";

const history = createBrowserHistory();

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      "access-token": localStorage.getItem("access-token"),
      client: localStorage.getItem("client"),
      expiry: localStorage.getItem("expiry"),
      uid: localStorage.getItem("uid"),
    },
  };
});

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_GRAPHQL}`,
  credentials: `${process.env.REACT_APP_CREDENTIALS}`,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // graphQLErrors.map(({ message, locations, path }) =>
    // console.log(
    //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
    // )
    // );
    // history.push("/student_profile");
  }

  if (networkError) {
    if (networkError.response && networkError.response.status === 401) {
      localStorage.clear();
      client.resetStore();
      history.push("/");
      window.location.reload();
    } else {
      // console.log(`[Network error]: ${networkError}`);
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router history={history}>
      <Route path="/" component={App} />
    </Router>
  </ApolloProvider>,
  document.getElementById("root")
);
