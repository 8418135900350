import gql from "graphql-tag";

export const EDIT_FAMILY_BACKGROUND_MUTATION = gql`
  mutation editFamilyBackground(
    $id: ID!
    $fullName: String!
    $relation: String!
    $dateOfBirth: String!
    $educationLevel: String!
    $occupation: String!
  ) {
    editFamilyBackground(
      id: $id
      fullName: $fullName
      relation: $relation
      dateOfBirth: $dateOfBirth
      educationLevel: $educationLevel
      occupation: $occupation
    ) {
      id
      fullName
      relation
      dateOfBirth
      educationLevel
      occupation
    }
  }
`;

export const CREATE_FAMILY_BACKGROUND_MUTATION = gql`
  mutation createFamilyBackground(
    $personId: ID!
    $fullName: String!
    $relation: String!
    $dateOfBirth: String!
    $educationLevel: String!
    $occupation: String!
  ) {
    createFamilyBackground(
      personId: $personId
      fullName: $fullName
      relation: $relation
      dateOfBirth: $dateOfBirth
      educationLevel: $educationLevel
      occupation: $occupation
    ) {
      id
    }
  }
`;
