import gql from "graphql-tag";

export const DELETE_EDUCATION_MUTATION = gql`
  mutation deleteEducation($id: ID!) {
    deleteEducation(id: $id) {
      id
    }
  }
`;

export const DELETE_FAMILY_BACKGROUND_MUTATION = gql`
  mutation deleteFamilyBackground($id: ID!) {
    deleteFamilyBackground(id: $id) {
      id
    }
  }
`;

export const DELETE_EXPERIENCE_MUTATION = gql`
  mutation deleteExperience($id: ID!) {
    deleteExperience(id: $id) {
      id
    }
  }
`;
