import React from "react";
import { GET_COURSE_ASSIGNMENT_SECTIONS_BY_COURSE_ASSIGNMENT } from "../../queries/find/byId";
import { useQuery } from "@apollo/react-hooks";
import Select from "react-select";
import _ from "lodash";

let section = null;

const LoadSections = (props) => {
  // if (props.courseAssignment == 0) return null;
  const { loading, data } = useQuery(GET_COURSE_ASSIGNMENT_SECTIONS_BY_COURSE_ASSIGNMENT, {
    variables: { courseAssignmentId: props.courseAssignment },
    fetchPolicy: "no-cache",
  });
  if (loading) return <p>Loading ...</p>;
  return (
    <div className="form-group row">
      <label className="col-sm-3 col-form-label">Section</label>
      <div className="col-sm-9">
        <Select
          name="section"
          options={groupBy(data)}
          onChange={(e) => {
            section = e.value;
          }}
          classNamePrefix="select"
        />
      </div>
    </div>
  );
};

function groupBy(data) {
  return _.chain(data.courseAssignmentSections)
    .map(function (v, i) {
      return {
        value: v["sectionNo"],
        label: v["name"],
      };
    })
    .value();
}

const sectionValue = () => {
  return section;
};

export { sectionValue };

export default LoadSections;
