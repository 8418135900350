import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";

function DepartmentPreferences(props) {
  const { loading, data } = useQuery(props.query, {
    variables: { personId: props.person_id },
  });

  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Preference</th>
                <th>Program</th>
              </tr>
            </thead>
            <tbody>
              {data.getDepartmentPreferences.map(
                ({ id, program, preference }) => (
                  <tr key={id}>
                    <td>{preference}</td>
                    <td>{program.fullName}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer">
        <Link to={`/student_profile/${props.person_id}/edit_preferences`}>
          Edit
        </Link>
      </div>
    </div>
  );
}

export default DepartmentPreferences;
