import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import ListItem from "../layouts/ListItem";
import _ from "lodash";

function EmergencyContact(props) {
  const { loading, data } = useQuery(props.query, {
    variables: { personId: props.person_id },
  });
  if (loading) return <p>Loading ...</p>;
  if (_.isEmpty(data.getEmergencyContact))
    return (
      <div>
        <p>No EmergencyContact Found</p>
        <Link
          to={`/student_profile/${props.person_id}/create_emergency_contact`}
        >
          New
        </Link>
      </div>
    );

  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <ListItem label="Full Name" data={data.getEmergencyContact.fullName} />
        <ListItem
          label="Relationship"
          data={data.getEmergencyContact.relationship}
        />
        <ListItem
          label="Home Telephone"
          data={data.getEmergencyContact.homeTelephone}
        />
        <ListItem label="Mobile" data={data.getEmergencyContact.mobile} />
        <ListItem
          label="Work Telephone"
          data={data.getEmergencyContact.workTelephone}
        />
      </div>
      <div className="card-footer">
        <Link to={`/student_profile/${props.person_id}/edit_emergency_contact`}>
          Edit
        </Link>
      </div>
    </div>
  );
}

export default EmergencyContact;
