import React from "react";

function FileUpload(props) {
  const { field, form } = props;

  const handleChange = (e) => {
    const file = e.currentTarget.files[0];
    const reader = new FileReader();
    document.getElementById("photoLabel").innerHTML = file.name;
    reader.readAsDataURL(file);
    form.setFieldValue(field.name, file);
  };

  return (
    <div className="custom-file">
      <input
        id="photo"
        type={"file"}
        onChange={(o) => handleChange(o)}
        className={"custom-file-input"}
      />
      <label className="custom-file-label" htmlFor="photo" id="photoLabel">
        Choose file
      </label>
    </div>
  );
}

export default FileUpload;
