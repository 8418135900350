import gql from "graphql-tag";

export const EDIT_EMERGENCY_CONTACT_MUTATION = gql`
  mutation editEmergencyContact(
    $id: ID!
    $fullName: String!
    $relationship: String!
    $homeTelephone: String!
    $mobile: String!
    $workTelephone: String!
  ) {
    editEmergencyContact(
      id: $id
      fullName: $fullName
      relationship: $relationship
      homeTelephone: $homeTelephone
      mobile: $mobile
      workTelephone: $workTelephone
    ) {
      id
      fullName
      relationship
      homeTelephone
      mobile
      workTelephone
    }
  }
`;

export const CREATE_EMERGENCY_CONTACT_MUTATION = gql`
  mutation createEmergencyContact(
    $personId: ID!
    $fullName: String!
    $relationship: String!
    $homeTelephone: String!
    $mobile: String!
    $workTelephone: String!
  ) {
    createEmergencyContact(
      personId: $personId
      fullName: $fullName
      relationship: $relationship
      homeTelephone: $homeTelephone
      mobile: $mobile
      workTelephone: $workTelephone
    ) {
      id
      fullName
      relationship
      homeTelephone
      mobile
      workTelephone
    }
  }
`;
