import gql from "graphql-tag";

export default gql`
  {
    studentAcademicYearSemesters {
      id
      semesterName
    }
  }
`;
