import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik, Form, Field, FieldArray } from "formik";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import { GET_DEPARTMENT_PREFERENCES } from "../../queries/find/byId";
import { EDIT_DEPARTMENT_PREFERENCES_MUTATION } from "../../mutations/departmentPreferences";
import swal from "sweetalert";

function EditPreferences(props) {
  const personId = props.match.params.personId;
  const { loading, data } = useQuery(GET_DEPARTMENT_PREFERENCES, {
    variables: { personId: personId },
  });
  const [updateDepartmentPreferences] = useMutation(EDIT_DEPARTMENT_PREFERENCES_MUTATION, {
    refetchQueries: [
      {
        query: GET_DEPARTMENT_PREFERENCES,
        variables: { personId: personId },
      },
    ],
  });

  if (loading) return <p>Loading ...</p>;
  let preferences = data.getDepartmentPreferences.map((ps) => ps.program);
  let preferenceArray = data.getDepartmentPreferences.map((np) => np.program.id);
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-edit pr-1"></span>Rearrange Preferences
        </h3>
      </div>
      <Formik
        initialValues={{
          personId: personId,
          newPreferences: preferenceArray,
        }}
        onSubmit={(values, { setSubmitting }) => {
          updateDepartmentPreferences({ variables: values }).then(
            (result) => {
              setSubmitting(false);
              swal("Update  Preferences", "Preferences Updated Successfully!");
              props.history.push("/student_profile");
            },
            (error) => {
              setSubmitting(false);
              swal(
                "Update  Preferences Error",
                error.graphQLErrors[0].extensions.validation,
                "error"
              );
            }
          );
        }}
        render={({ values }) => (
          <div className="card-body row">
            <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
              <Form>
                <FieldArray
                  name="newPreferences"
                  render={(arrayHelpers) => (
                    <div className="list-group">
                      {values.newPreferences.map((program, index) => (
                        <div key={index} className="list-group-item">
                          <button
                            className="btn  btn-secondary mr-1"
                            type="button"
                            onClick={() => {
                              if (index > 0) {
                                arrayHelpers.swap(index, index - 1);
                              }
                            }}
                          >
                            <FaArrowCircleUp />
                          </button>
                          <button
                            className="btn  btn-secondary mr-2"
                            type="button"
                            onClick={() => {
                              if (index < values.newPreferences.length - 1) {
                                arrayHelpers.swap(index, index + 1);
                              }
                            }}
                          >
                            <FaArrowCircleDown />
                          </button>
                          <Field type="hidden" name={`newPreferences[${index}]`} />
                          <label>{preferences.filter((pr) => pr.id === program)[0].fullName}</label>
                          <span className="btn float-right">{index + 1}</span>
                        </div>
                      ))}
                      <div className="list-group-item">
                        <button type="submit" className="btn  btn-primary">
                          Update Preferences
                        </button>
                      </div>
                    </div>
                  )}
                />
              </Form>
            </div>
          </div>
        )}
      />
    </div>
  );
}

export default EditPreferences;
