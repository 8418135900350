import gql from "graphql-tag";

export default gql`
  {
    studentGradeComplaints {
      id
      oldLetterGrade
      newLetterGrade
      approvalStatus
      studentGrade {
        id
        courseEnrollment {
          id
          course {
            id
            courseTitle
          }
        }
      }
      courseAssignment {
        id
        employee {
          id
          person {
            id
            fullName
          }
        }
      }
    }
  }
`;
