import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Formik, Form } from "formik";
import WField from "../layouts/WField";
import WFselect from "../layouts/WFselect";
import WFormErrors from "../layouts/WFormErrors";
import { ContactAddressValidator } from "../validations/FormValidation";
import { GET_CONTACT_ADDRESS } from "../../queries/find/byId";
import { CREATE_CONTACT_ADDRESS_MUTATION } from "../../mutations/contactAddress";
import { NATIONALITY_QUERY } from "../../queries/constants";
import swal from "sweetalert";
import Regions from "./Regions";

function CreateContactAddress(props) {
  const [createContactAddress] = useMutation(CREATE_CONTACT_ADDRESS_MUTATION, {
    refetchQueries: [
      {
        query: GET_CONTACT_ADDRESS,
        variables: { personId: props.match.params.personId },
      },
    ],
  });
  const [createErrors, setCreateErrors] = useState({});

  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-edit pr-1"></span>Add Contact Address
        </h3>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          personId: props.match.params.personId,
          country: "",
          zoneId: "",
          woreda: "",
          kebele: "",
          streetAddress: "",
          homeTelephone: "",
          mobile: "",
          workTelephone: "",
          email: "",
        }}
        validationSchema={ContactAddressValidator}
        onSubmit={(values, { setSubmitting }) => {
          createContactAddress({ variables: values }).then(
            (result) => {
              setSubmitting(false);
              swal("Create Contact Address", "Contact Address Created Successfully!");
              props.history.push("/student_profile");
            },
            (error) => {
              setSubmitting(false);
              setCreateErrors(error.graphQLErrors[0].extensions.validation);
            }
          );
        }}
      >
        {({ isSubmitting }) => (
          <div className="card-body row">
            <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
              <WFormErrors errors={createErrors} />
              <Form>
                <WFselect
                  ws_name="country"
                  ws_query={NATIONALITY_QUERY}
                  ws_label="Country"
                  hash={true}
                />
                <Regions />
                <WField wf_type="text" wf_name="woreda" wf_label="Woreda" />
                <WField wf_type="text" wf_name="kebele" wf_label="Kebele" />
                <WField wf_type="text" wf_name="streetAddress" wf_label="Street Address" />
                <WField wf_type="text" wf_name="homeTelephone" wf_label="Home Telephone" />
                <WField wf_type="text" wf_name="mobile" wf_label="Mobile" />
                <WField wf_type="text" wf_name="workTelephone" wf_label="Work Telephone" />
                <WField wf_type="email" wf_name="email" wf_label="Email" />
                <div className="form-group">
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary ">
                    Create
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default CreateContactAddress;
