import gql from "graphql-tag";

export const ADD_COURSE = gql`
  mutation addCourse(
    $courseOffering: ID!
    $courseAssignment: ID!
    $section: ID!
    $requestFor: String!
  ) {
    addCourse(
      courseOffering: $courseOffering
      courseAssignment: $courseAssignment
      section: $section
      requestFor: $requestFor
    )
  }
`;
