import React from "react";
import { DROP_COURSES } from "../../queries/getDropCourses";
import { DROP_COURSE_ENROLLMENT } from "../../mutations/DropCourse";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Select from "react-select";
import { Form, Formik } from "formik";
import { sweetAlert } from "../common/SweetAlert";
import swal from "sweetalert";
import _ from "lodash";

const DropCourse = (props) => {
  const { loading, data } = useQuery(DROP_COURSES, {
    fetchPolicy: "no-cache",
  });
  const [dropCourse] = useMutation(DROP_COURSE_ENROLLMENT);
  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card">
      <div className="card-header bg-info">Drop Courses</div>
      <div className="card-body">
        <div className="row">
          <div className="col-8 offset-2">
            <Formik
              initialValues={{
                courses: null,
              }}
              onSubmit={(values, { setSubmitting }) => {
                if (!values["courses"]) {
                  setSubmitting(false);
                  return sweetAlert({ error: "Courses can't be empty." });
                }
                dropCourse({ variables: values }).then(
                  (result) => {
                    setSubmitting(false);
                    sweetAlert(result.data.dropCourse);
                    if (result.data.dropCourse.success) {
                      props.reset();
                      return props.props.history.push("/add-drop");
                    }
                  },
                  (error) => {
                    setSubmitting(false);
                    swal("Error", error);
                  }
                );
              }}
            >
              {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
                <Form>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Courses</label>
                    <div className="col-sm-9">
                      <Select
                        isMulti
                        name="courses"
                        options={groupBy(data)}
                        onChange={(e) => {
                          setFieldValue("courses", _.map(e, "value"), false);
                        }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      <small id="emailHelp" className="form-text text-muted">
                        You can select multiple courses
                      </small>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary " disabled={isSubmitting}>
                      Submit Request
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

function groupBy(data) {
  return _.chain(data.dropCourses)
    .map(function (v, i) {
      return {
        value: Number(v["id"]),
        label: v["courseTitle"] + " (" + v["courseCode"] + ")",
      };
    })
    .value();
}

export default DropCourse;
