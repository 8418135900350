import gql from "graphql-tag";

export const EDIT_APPLICANT_MUTATION = gql`
  mutation editApplicant(
    $id: ID!
    $admissionId: ID!
    $classYearId: ID!
    $programId: ID!
    $academicYearId: ID!
    $tuitionType: String!
    $fullNameInAmharic: String!
    $registrationNumber: String!
    $matriculationResult: Int!
  ) {
    editApplicant(
      id: $id
      admissionId: $admissionId
      classYearId: $classYearId
      programId: $programId
      academicYearId: $academicYearId
      tuitionType: $tuitionType
      fullNameInAmharic: $fullNameInAmharic
      registrationNumber: $registrationNumber
      matriculationResult: $matriculationResult
    ) {
      id
      fullNameInAmharic
      admissionStatus
      declaration
      tempIdNumber
      registrationNumber
      matriculationResult
      tuitionType
    }
  }
`;
