import React from "react";
import query from "../../queries/getRegistrations";
import _ from "lodash";
import { useQuery } from "@apollo/react-hooks";

function RegistrationShow(props) {
  const { loading, data } = useQuery(query, {
    variables: { id: props.ays_id },
  });
  if (loading) return <p>Loading ...</p>;

  const total_credit = (data) => {
    var total = _.reduce(
      data,
      function (memoizer, value) {
        return memoizer + parseInt(value.course.creditHour);
      },
      0
    );
    return total;
  };

  const total_ects = (data) => {
    var total = _.reduce(
      data,
      function (memoizer, value) {
        return memoizer + parseInt(value.course.ects);
      },
      0
    );
    return total;
  };

  const { isModular, academicYearSemester, courseEnrollments } = data.enrollmentRecord;
  return (
    <div className="card">
      <div className="card-header bg-info">
        <h5 className="card-title">{academicYearSemester.semesterName}</h5>
      </div>
      <div className="card-body table-responsive p-0">
        <table className="table table-striped  text-nowrap">
          <thead>
            <tr>
              <React.Fragment>
                <th>Course Code</th>
                <th>Course Title</th>
              </React.Fragment>
              {isModular === true ? (
                <React.Fragment>
                  <th>Module Code</th>
                  <th>Module Title</th>
                  <th>ECTS</th>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <th>Credit Hours</th>
                </React.Fragment>
              )}
            </tr>
          </thead>
          <tbody>
            {courseEnrollments.map(({ id, course, studentGrade }) => (
              <tr key={id}>
                <React.Fragment>
                  <td>{course.courseCode}</td>
                  <td>{course.courseTitle}</td>
                </React.Fragment>
                {isModular === true && studentGrade !== null && studentGrade.modular !== null ? (
                  <React.Fragment>
                    <td>{studentGrade.modular.moduleCode}</td>
                    <td>{studentGrade.modular.moduleTitle}</td>
                    <td>{course.ects}</td>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <td>{course.creditHour}</td>
                  </React.Fragment>
                )}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr bgcolor="silver">
              {isModular === true ? (
                <React.Fragment>
                  <th colSpan="4">Total</th>
                  <th>{total_ects(courseEnrollments)}</th>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <th colSpan="2">Total</th>
                  <th>{total_credit(courseEnrollments)}</th>
                </React.Fragment>
              )}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default RegistrationShow;
