import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import React from "react";
import { ADD_DROP_LIST } from "../../queries/getAddDropList";

const AddDropList = (props) => {
  const { loading, data, refetch } = useQuery(ADD_DROP_LIST);
  if (loading) return <p>Loading ...</p>;
  if (props.updateList) {
    refetch();
  }
  if (_.isEmpty(data.addDropList)) return null;
  return (
    <div className="table-responsive">
      <table className="table  table-bordered text-nowrap">
        <thead>
          <tr>
            <th>Course</th>
            <th>Offering Program</th>
            <th>Instructor</th>
            <th>Section</th>
            <th>Request</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.addDropList.map(
            ({
              id,
              requestFor,
              confirmationStatus,
              course,
              courseAssignment,
              section,
              registration,
            }) => (
              <tr key={id}>
                <td>{course.codeAndTitle}</td>
                <td>
                  {requestFor === "add" && !_.isEmpty(courseAssignment)
                    ? courseAssignment.offeredCourse.curriculum.program.name
                    : null}
                </td>
                <td>
                  {requestFor === "add" && !_.isEmpty(courseAssignment)
                    ? courseAssignment.instructorName
                    : null}
                </td>
                <td>{requestFor === "add" ? section.name : null}</td>
                <td>{requestFor}</td>
                <td>{confirmationStatus}</td>
                <td>{/* <DeleteAddDrop refetch={refetch} courseAddDropId={id} /> */}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AddDropList;
