import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";

function CourseOfferingNotDefined() {
  return (
    <div className="mt-4 d-flex flex-row justify-content-center align-items-center">
      <div className="card mt-4 text-center" style={{ width: 30 + "em" }}>
        <div className="card-body">
          <div className="alert alert-danger mt-3">
            <FontAwesomeIcon icon={faBookOpen} size="4x" />
            <p className="font-weight-bold mt-2 h7">
              Course offering not defined. Please contact your department.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseOfferingNotDefined;
