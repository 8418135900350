import gql from "graphql-tag";

export const LAST_WITHDRAWAL = gql`
  {
    lastWithdrawal {
      id
      reason
      additionalInformation
      requestedDate
      status
      documentUrl
      student {
        idNumber
        applicant {
          person {
            fullName
          }
        }
      }
    }
  }
`;
