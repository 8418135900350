import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { sweetAlert } from "../common/SweetAlert";
import * as qs from "query-string";

const PasswordEdit = (props) => {
  document.getElementById("body").classList.remove("sidebar-mini");
  document.getElementById("body").classList.add("login-page");
  const parsedHeader = qs.parse(props.location.search);

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [passwordConfirmationError, setPasswordConfirmationError] = useState(null);

  const submit = (event) => {
    setBtnDisabled(true);
    event.preventDefault();
    const newPassword = new FormData();
    newPassword.append("password", password);
    newPassword.append("password_confirmation", password_confirmation);

    if (!password) {
      setBtnDisabled(false);
      return setPasswordError("Password is required");
    }
    if (password.length < 8) {
      setBtnDisabled(false);
      return setPasswordError("Password is too short. Password must be 8 characters long.");
    }
    setPasswordError("");
    if (!password_confirmation) {
      setBtnDisabled(false);
      return setPasswordConfirmationError("Password Confirmation is required.");
    }
    if (password !== password_confirmation) {
      setBtnDisabled(false);
      return setPasswordConfirmationError("Password Confirmation must match Password.");
    }
    setPasswordConfirmationError("");

    axios
      .put(`${process.env.REACT_APP_API_URL}/auth/password`, newPassword, {
        headers: {
          "access-token": parsedHeader["access-token"],
          client: parsedHeader["client"],
          uid: parsedHeader["uid"],
        },
      })
      .then((res) => {
        sweetAlert({ success: "Password has been reseted." });
        props.history.push("/");
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <div className="login-box">
      <div className="login-logo">
        <a href="http://www.astu.edu.et/">
          <img
            src="/dist/img/astu_logo.svg"
            alt="AdminLTE Logo"
            className="img-circle"
            width="20%"
          />
          <br />
          <b>ASTU</b> E-Student
        </a>
      </div>
      <div className="card">
        <div className="card-body login-card-body">
          <p className="login-box-msg">
            You are only one step a way from your new password, recover your password now.
          </p>
          <form onSubmit={submit}>
            <div class="input-group">
              <input
                disabled={btnDisabled}
                className="form-control"
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                placeholder="Password"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <span className="text-danger bold">{passwordError}</span>

            <div class="input-group mt-4">
              <input
                disabled={btnDisabled}
                className="form-control"
                id="password_confirmation"
                name="password_confirmation"
                type="password"
                value={password_confirmation}
                onChange={({ target }) => setPasswordConfirmation(target.value)}
                placeholder="Confirm Password"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <span className="text-danger bold">{passwordConfirmationError}</span>

            <div class="row mt-4">
              <div class="col-12">
                <button type="submit" className="btn btn-primary btn-block" disabled={btnDisabled}>
                  {btnDisabled ? "Changing..." : "Change password "}
                </button>
              </div>
            </div>
          </form>
          <p class="mt-3 mb-1">
            <Link to="/">Login</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PasswordEdit;
