import gql from "graphql-tag";

export const EDIT_PERSON_MUTATION = gql`
  mutation editPerson(
    $id: ID!
    $amharicFirstName: String!
    $amharicFatherName: String!
    $amharicGrandFatherName: String!
    $maritalStatus: String!
    $nationality: String!
    $regionId: ID!
    $disability: String!
    $dateOfBirth: String!
    $placeOfBirth: String!
    $photo: Upload
  ) {
    editPerson(
      id: $id
      amharicFirstName: $amharicFirstName
      amharicFatherName: $amharicFatherName
      amharicGrandFatherName: $amharicGrandFatherName
      maritalStatus: $maritalStatus
      nationality: $nationality
      regionId: $regionId
      disability: $disability
      dateOfBirth: $dateOfBirth
      placeOfBirth: $placeOfBirth
      photo: $photo
    ) {
      id
      maritalStatus
      nationality
      regionId
      disability
      dateOfBirth
      placeOfBirth
      photoUrl
    }
  }
`;
