import React, { useState } from "react";
import READMISSION_FORM_DATA from "../../queries/getReadmissionFormData";
import { useQuery, useMutation } from "@apollo/react-hooks";
import HeaderProfile from "../common/HeaderProfile";
import { Form, Formik } from "formik";
import Select from "react-select";
import { READMISSION_REQUEST } from "../../mutations/ReadmissionRequest";
import ErrorMessages from "../common/ErrorMessages";
import { sweetAlert } from "./../common/SweetAlert";
import swal from "sweetalert";
import _ from "lodash";

const ReadmissionForm = (props) => {
  const [errors, setErrors] = useState();
  const [readmissionRequest] = useMutation(READMISSION_REQUEST);
  const { loading, data } = useQuery(READMISSION_FORM_DATA, {
    fetchPolicy: "no-cache",
  });
  if (loading) return <p>Loading ...</p>;

  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-redo pr-1"></span>Readmission Form
        </h3>
      </div>
      <div className="card-body">
        <HeaderProfile />
        <div className="row">
          <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-md-2 offset-lg-2">
            <Formik
              initialValues={{
                reason: null,
                other: null,
                semester: null,
                academicYear: null,
                leavingDate: null,
                academicYearSemesterId: data.readmissionFormData.academic_year_semester_id,
                registrationId: data.readmissionFormData.registration_id,
              }}
              onSubmit={(values, { setSubmitting }) => {
                let tempErrors = [];

                if (!values["reason"]) {
                  tempErrors.push("Reason for leaving is required.");
                }
                if (!values["academicYear"]) {
                  tempErrors.push("Leaving Acadamic Year is required.");
                }
                if (!values["semester"]) {
                  tempErrors.push("Leaving Semester is required.");
                }
                setErrors(tempErrors);
                if (tempErrors.length > 0) {
                  setSubmitting(false);
                  return;
                }
                readmissionRequest({ variables: values }).then(
                  (result) => {
                    setSubmitting(false);
                    sweetAlert(result.data.readmissionRequest);
                    if (result.data.readmissionRequest.success) {
                      return props.props.history.push("/");
                    }
                  },
                  (error) => {
                    setSubmitting(false);
                    swal("Error", error);
                  }
                );
              }}
            >
              {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
                <Form>
                  <ErrorMessages errors={errors} />

                  <div className="form-group">
                    <label>* Reason for leaving</label>
                    <Select
                      onChange={(e) => setFieldValue("reason", e.value, false)}
                      options={groupBy(data)}
                      formatGroupLabel={formatGroupLabel}
                    />
                  </div>

                  <div className="form-group">
                    <label>Other reason</label>
                    <textarea
                      className="form-control"
                      name="other"
                      id="other"
                      onChange={(e) => setFieldValue("other", e.target.value, false)}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label>* Leaving Acadamic Year</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={data.readmissionFormData.academic_years}
                      onChange={(e) => setFieldValue("academicYear", e.value, false)}
                    />
                  </div>

                  <div className="form-group">
                    <label>Leaving Semester</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={data.readmissionFormData.semesters}
                      onChange={(e) => setFieldValue("semester", e.value, false)}
                    />
                  </div>

                  <div className="form-group">
                    <label>Leaving Date</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => setFieldValue("leavingDate", e.target.value, false)}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="submit"
                      value="Send Readmission Request"
                      className="btn  btn-primary"
                      disabled={isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

function groupBy(data) {
  return _.chain(data.reasonGroup)
    .map(function (v, i) {
      return {
        label: v["0"],
        options: v["1"].map(function (vv, ii) {
          return {
            value: vv,
            label: vv,
          };
        }),
      };
    })
    .value();
}

export default ReadmissionForm;
