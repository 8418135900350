import React from "react";
import { Field, ErrorMessage } from "formik";
import { useQuery } from "@apollo/react-hooks";

function WFselect(props) {
  const { loading, error, data } = useQuery(props.ws_query,{
    variables: props.variables,
  });
  return (
    <div className="form-group">
      <label className="" htmlFor={props.ws_name}>
        {props.ws_label}
      </label>
      {loading === true ? (
        <Loading />
      ) : error === true ? (
        <Error />
      ) : (
        <React.Fragment>
          <Field
            name={props.ws_name}
            component="select"
            placeholder="Please Select..."
            className="form-control select2bs4"
          >
            <option value="null">Please Select...</option>
            {props.hash === true
              ? data[Object.keys(data)[0]].map((option, index) => {
                  return (
                    <option key={option.id} value={option.id}>
                      {option[Object.keys(option)[1]]}
                    </option>
                  );
                })
              : data[Object.keys(data)[0]].map((option, index) => {
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
          </Field>
          <ErrorMessage name={props.ws_name} component="div" className="text-danger" />
        </React.Fragment>
      )}
    </div>
  );
}

function Loading(props) {
  return <span className="form-control border-0">Loading ...</span>;
}

function Error(props) {
  return <span className="form-control border-0">Error :(</span>;
}

export default WFselect;
