import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik, Form } from "formik";
import WField from "../layouts/WField";
import WFormErrors from "../layouts/WFormErrors";
import { EmergencyContactValidator } from "../validations/FormValidation";
import { GET_EMERGENCY_CONTACT } from "../../queries/find/byId";
import { EDIT_EMERGENCY_CONTACT_MUTATION } from "../../mutations/emergencyContact";
import swal from "sweetalert";

function EditEmergencyContact(props) {
  const { loading, data } = useQuery(GET_EMERGENCY_CONTACT, {
    variables: props.match.params,
  });
  const [updateEmergencyContact] = useMutation(EDIT_EMERGENCY_CONTACT_MUTATION);
  const [updateErrors, setUpdateErrors] = useState({});

  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-edit pr-1"></span>Edit Emergency Contact
        </h3>
      </div>
      <Formik
        initialValues={{
          id: data.getEmergencyContact.id,
          fullName: data.getEmergencyContact.fullName || "",
          relationship: data.getEmergencyContact.relationship || "",
          homeTelephone: data.getEmergencyContact.homeTelephone || "",
          mobile: data.getEmergencyContact.mobile || "",
          workTelephone: data.getEmergencyContact.workTelephone || "",
        }}
        validationSchema={EmergencyContactValidator}
        onSubmit={(values, { setSubmitting }) => {
          updateEmergencyContact({ variables: values }).then(
            (result) => {
              setSubmitting(false);
              swal("Update  Emergency Contact", "Emergency Contact Updated Successfully!");
              props.history.push("/student_profile");
            },
            (error) => {
              setSubmitting(false);
              setUpdateErrors(error.graphQLErrors[0].extensions.validation);
            }
          );
        }}
      >
        {({ isSubmitting }) => (
          <div className="card-body row">
            <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
              <WFormErrors errors={updateErrors} />
              <Form>
                <WField wf_type="text" wf_name="fullName" wf_label="Full Name" />
                <WField wf_type="text" wf_name="relationship" wf_label="Relationship" />
                <WField wf_type="text" wf_name="homeTelephone" wf_label="Home Telephone" />
                <WField wf_type="text" wf_name="mobile" wf_label="Mobile" />
                <WField wf_type="text" wf_name="workTelephone" wf_label="Work Telephone" />
                <div className="form-group">
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary ">
                    Update
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default EditEmergencyContact;
