import React from "react";
import { ZONES_QUERY } from "../../queries/constants";
import WFselect from "../layouts/WFselect";

export default function Zones(props) {
  return (
    <WFselect
      ws_name="zoneId"
      ws_query={ZONES_QUERY}
      variables={{ regionId: props.region_id }}
      ws_label="Zone"
      hash={true}
    />
  );
}
