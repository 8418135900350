import gql from "graphql-tag";

export default gql`
  {
    transfers {
      id
      transferType
      toInstitution
      effectiveAcademicYearAndSemester
      reason
      hod1Comment
      hodStatus1
      hod2Comment
      hodStatus2
      program {
        name
      }
    }
  }
`;
