import gql from "graphql-tag";

export default gql`
  {
    studentActiveSemester {
      id
      semesterName
    }
  }
`;
