import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Formik, Form } from "formik";
import WField from "../layouts/WField";
import WFormErrors from "../layouts/WFormErrors";
import { EducationValidator } from "../validations/FormValidation";
import { GET_EDUCATIONS } from "../../queries/find/byId";
import { CREATE_EDUCATION_MUTATION } from "../../mutations/education";
import swal from "sweetalert";

function CreateEducation(props) {
  const [createEducation] = useMutation(CREATE_EDUCATION_MUTATION, {
    refetchQueries: [
      {
        query: GET_EDUCATIONS,
        variables: { personId: props.match.params.personId },
      },
    ],
  });
  const [createErrors, setCreateErrors] = useState({});

  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-plus pr-1"></span>Add Eductaion
        </h3>
      </div>
      <Formik
        initialValues={{
          personId: props.match.params.personId,
          institution: "",
          studyField: "",
          qualification: "",
          from: "",
          to: "",
          grade: "",
          scale: "",
          awardType: "",
        }}
        validationSchema={EducationValidator}
        onSubmit={(values, { setSubmitting }) => {
          createEducation({ variables: values }).then(
            (result) => {
              setSubmitting(false);
              swal("Create Education", "Education Created Successfully!");
              props.history.push("/student_profile");
            },
            (error) => {
              setSubmitting(false);
              setCreateErrors(error.graphQLErrors[0].extensions.validation);
            }
          );
        }}
      >
        {({ isSubmitting }) => (
          <div className="card-body row">
            <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
              <WFormErrors errors={createErrors} />
              <Form>
                <WField wf_type="text" wf_name="institution" wf_label="Institution" />
                <WField wf_type="text" wf_name="studyField" wf_label="Study Field" />
                <WField wf_type="text" wf_name="awardType" wf_label="Award Type" />
                <WField wf_type="text" wf_name="qualification" wf_label="Qualification" />
                <WField wf_type="date" wf_name="from" wf_label="From" />
                <WField wf_type="date" wf_name="to" wf_label="To" />
                <WField wf_type="number" wf_name="grade" wf_label="Grade" />
                <WField wf_type="number" wf_name="scale" wf_label="Scale" />
                <div className="form-group">
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary ">
                    Create
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default CreateEducation;
