import gql from "graphql-tag";

export const EDIT_DEPARTMENT_PREFERENCES_MUTATION = gql`
  mutation editDepartmentPreferences($personId: ID!, $newPreferences: [ID!]!) {
    editDepartmentPreferences(personId: $personId, newPreferences: $newPreferences) {
      id
      program {
        id
        fullName
      }
      preference
    }
  }
`;
