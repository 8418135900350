import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { LATEST_EVENTS } from "../../queries/getLatestEvents";
import Moment from "react-moment";
import HeaderProfile from "../common/HeaderProfile";

const LatestEvents = () => {
  const { loading, data } = useQuery(LATEST_EVENTS);
  if (loading) return <p>Loading ...</p>;

  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <i className="fas fa-calendar mr-1"></i> Upcoming Events
        </h3>
      </div>
      <div className="card-body">
        <HeaderProfile />
        {data && data.latestEvents.length > 0 ? (
          <div>
            <p className="bold mb-3 text-black">
              <i className="fas fa-calendar"></i> <b>Upcomping Events</b>
            </p>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Activity</th>
                    <th scope="col">Admission For</th>
                  </tr>
                </thead>
                <tbody>
                  {data.latestEvents.map(
                    ({ id, startDate, endDate, event, academicYearSemester, admissions }) => (
                      <tr key={id}>
                        <th>
                          <Moment format="MMMM D, YYYY" withTitle>
                            {startDate}
                          </Moment>
                          {" - "}
                          <Moment format="MMMM D, YYYY" withTitle>
                            {endDate}
                          </Moment>
                        </th>
                        <td className="bold">
                          {event.title} {academicYearSemester.semesterName}
                        </td>
                        <td>
                          {admissions.map(({ id, name }) => (
                            <span className="italic" key={id}>
                              {name}
                            </span>
                          ))}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p>No Upcoming Events.</p>
        )}
      </div>
    </div>
  );
};

export default LatestEvents;
