import React from "react";
import { useQuery } from "@apollo/react-hooks";
import query from "../queries/getStudentGradeComplaints";

function StudentGradeComplaints(props) {
  const { loading, data } = useQuery(query);
  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-gavel pr-1"></span>Grade Complaint List
        </h3>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table  table-bordered text-nowrap">
            <thead>
              <tr>
                <th>course Title</th>
                <th>Employee Full Name</th>
                <th>Old Grade</th>
                <th>Updated Grade</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data.studentGradeComplaints.map(
                ({
                  id,
                  oldLetterGrade,
                  newLetterGrade,
                  approvalStatus,
                  studentGrade,
                  courseAssignment,
                }) => (
                  <tr key={id}>
                    <td>{studentGrade.courseEnrollment.course.courseTitle}</td>
                    <td>{courseAssignment.employee.person.fullName}</td>
                    <td>{oldLetterGrade}</td>
                    <td>{newLetterGrade}</td>
                    <td>{approvalStatus}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default StudentGradeComplaints;
