import React from "react";

function ValidCourses(props) {
  const handleSelection = (e) => {
    if (props.elective.indexOf(e.target.value) === -1) {
      props.setElective([...props.elective, e.target.value]);
    } else {
      const index = props.elective.indexOf(e.target.value);

      props.setElective(props.elective.filter((_, i) => i !== index));
    }
  };
  return (
    <div className="table-responsive">
      <table className="table  table-bordered text-nowrap">
        <thead>
          <tr>
            <th scope="col">Course Type</th>
            <th scope="col">Course Title</th>
            <th scope="col">Course Code</th>
            <th scope="col">Credit Hour</th>
          </tr>
        </thead>
        <tbody>
          {props.valid_courses.map(({ id, course_title, course_code, credit_hour, ects }) => (
            <tr key={id}>
              <td></td>
              <td>{course_title}</td>
              <td>{course_code}</td>
              <td>{credit_hour}</td>
            </tr>
          ))}
          {props.elective_courses.map(({ id, course_title, course_code, credit_hour, ects }) => (
            <tr key={id}>
              <td>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`elective${id}`}
                    value={id}
                    onClick={handleSelection}
                  />
                  <label className="form-check-label" htmlFor={`elective${id}`}>
                    Elective
                  </label>
                </div>
              </td>
              <td>{course_title}</td>
              <td>{course_code}</td>
              <td>{credit_hour}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ValidCourses;
