import React from "react";
import query from "../../queries/getStudentActiveSemester";
import { useQuery } from "@apollo/react-hooks";

function ActiveSemester() {
  const { loading, data } = useQuery(query);
  if (loading) return <p className="text-info nav-link">Loading ...</p>;
  if (!data) return <p className="text-danger nav-link">No Active Semester for you!</p>;

  const { semesterName } = data.studentActiveSemester;
  return <p className="text-info nav-link">{semesterName}</p>;
}

export default ActiveSemester;
