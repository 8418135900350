import gql from "graphql-tag";

export default gql`
  {
    costSharingByAys {
      id
      semesterName
      costSharing {
        id
        serviceTotal
        services {
          service
          amount
        }
      }
    }
  }
`;
