import gql from "graphql-tag";

export const GET_PAYMENT = gql`
  {
    payment {
      status
      lbl
      employee {
        id
        person {
          id
          fullName
        }
      }
      pType
      aysCy
      price
      paymentStatus
    }
  }
`;
