import gql from "graphql-tag";

export const ADD_COURSES = gql`
  {
    addCourses {
      id
      course {
        id
        courseCode
        courseTitle
        ects
        creditHour
      }
      curriculum {
        program {
          id
          fullName
        }
      }
    }
  }
`;
