import React from "react";
import query from "../../queries/getGradeReport";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { COMPLAINT_QUERY } from "../../queries/calendar";

function GradeReportShow(props) {
  const { loading: calendarLoading, data: calendarData } = useQuery(COMPLAINT_QUERY);
  const { loading: gradeReportLoading, data: gradeReportData } = useQuery(query, {
    variables: { id: props.ays_id },
  });
  if (gradeReportLoading || calendarLoading) return <p>Loading ...</p>;
  if (!gradeReportData.gradeReport.gpa)
    return <p className="text-danger text-bold">Status not determined for this semester</p>;

  const {
    isModular,
    prevGpa,
    gpa,
    academicYearSemester,
    courseEnrollments,
  } = gradeReportData.gradeReport;
  const conplaintIsActive = calendarData && calendarData.complaintCalendar[props.ays_id]
  return (
    <div className="mb-4">
      <p>
        <b>{academicYearSemester.semesterName}</b>
      </p>
      <div className="table-responsive">
        <table className="table  table-striped table-bordered text-nowrap">
          <thead>
            <tr>
              {isModular === true ? (
                <React.Fragment>
                  <th>Module Code</th>
                  <th>Module Title</th>
                  <th>ECTS</th>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <th>Course Code</th>
                  <th>Course Title</th>
                  <th>Credit Hour</th>
                </React.Fragment>
              )}
              <React.Fragment>
                <th>Grade</th>
                <th>Grade Point</th>
                <th>Repeated Grade letter</th>
                {conplaintIsActive ? (<th></th>):(null)}
              </React.Fragment>
            </tr>
          </thead>
          <tbody>
            {courseEnrollments.map(({ id, studentGrade, course }) => (
              <tr key={id}>
                {isModular === true && studentGrade.modular != null ? (
                  <React.Fragment>
                    <td>{studentGrade.modular.moduleCode}</td>
                    <td>{studentGrade.modular.moduleTitle}</td>
                    <td>{course.ects}</td>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <td>{course.courseCode}</td>
                    <td>{course.courseTitle}</td>
                    <td>{course.creditHour}</td>
                  </React.Fragment>
                )}
                <React.Fragment>
                  <td>{studentGrade.letterGrade}</td>
                  <td>{studentGrade.gradePoint}</td>
                  <td>{studentGrade.repeatedLetterGrade}</td>
                  {(conplaintIsActive && studentGrade.complainable) ? (
                    studentGrade.gradeComplaint == null ? (
                      <td>
                        <Link to={`/student_profile/${studentGrade.id}/create_grade_complaint`}>
                          Complain
                        </Link>
                      </td>
                    ) : (
                      <td>
                        <Link
                          to={`/student_profile/${studentGrade.gradeComplaint.id}/edit_grade_complaint`}
                        >
                          Edit Complain
                        </Link>
                      </td>
                    )
                  ) : (
                    <td></td>
                  )}
                </React.Fragment>
              </tr>
            ))}
          </tbody>
          <tfoot bgcolor="silver">
            <tr>
              <th colSpan={2}>Total</th>
              <th>{gpa.currentSemesterTotalCreditHour}</th>
              <th></th>
              <th colSpan={conplaintIsActive ? 3: 2}>{gpa.currentSemesterTotalGradePoint}</th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="table-responsive col-sm-12 col-lg-6 col-md-6">
        <table className="table  table-striped table-bordered text-nowrap">
          <thead>
            <tr>
              {isModular === true ? (
                <React.Fragment>
                  <th></th>
                  <th>ECTS</th>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <th></th>
                  <th>Credit Hour</th>
                </React.Fragment>
              )}
              <React.Fragment>
                <th>Grade Points</th>
                <th>Grade Point Average</th>
              </React.Fragment>
            </tr>
          </thead>
          <tbody>
            {prevGpa !== null ? (
              <React.Fragment>
                <tr>
                  <td>Previous Semester Total</td>
                  <td>{prevGpa.currentSemesterTotalCreditHour}</td>
                  <td>{prevGpa.currentSemesterTotalGradePoint}</td>
                  <td>{prevGpa.semesterGpa}</td>
                </tr>
                <tr>
                  <td>Previous Total</td>
                  <td>{prevGpa.totalCreditHour}</td>
                  <td>{prevGpa.totalGradePoint}</td>
                  <td>{prevGpa.cumulativeGpa}</td>
                </tr>
              </React.Fragment>
            ) : (
              <tr>
                <td colSpan={4}>There is No previous Gpa!</td>
              </tr>
            )}
            <tr>
              <td>Semester Total</td>
              <td>{gpa.currentSemesterTotalCreditHour}</td>
              <td>{gpa.currentSemesterTotalGradePoint}</td>
              <td>{gpa.semesterGpa}</td>
            </tr>
            <tr>
              <td>Cumulative Average</td>
              <td>{gpa.totalCreditHour}</td>
              <td>{gpa.totalGradePoint}</td>
              <td>{gpa.cumulativeGpa}</td>
            </tr>
          </tbody>
          <tfoot bgcolor="silver">
            <tr>
              <th colSpan={2}>Academic Status</th>
              <th colSpan={2}>{gpa.status}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default GradeReportShow;
