import React from "react";
import { useQuery } from "@apollo/react-hooks";
import REGISTRATION_STATUS from "../../queries/getRegistrationStatus";
import CalenderNotActivated from "./CalenderNotActivated";
import CourseOfferingNotDefined from "./CourseOfferingNotDefined";
import IncompleteList from "../common/IncompleteList";
import { sweetAlert } from "./../common/SweetAlert";
import { Redirect } from "react-router-dom";
import Payment from "./Payment";

const RegistrationHome = (props) => {
  const { loading, data } = useQuery(REGISTRATION_STATUS, {
    fetchPolicy: "no-cache",
  });
  if (loading) return <p>Loading ...</p>;
  if (!data) {
    sweetAlert({ error: "You are not permitted for this operation." });
    return <Redirect to="/" />;
  }
  if (data.registrationStatus.eligibility) {
    sweetAlert({ error: data.registrationStatus.eligibility });
    return <Redirect to="/" />;
  }
  if (data.registrationStatus.incomplete_list)
    return <IncompleteList data={data.registrationStatus.incomplete_list} />;
  if (data.registrationStatus.not_permitted) {
    sweetAlert({ error: "You are not permitted for this operation." });
    return <Redirect to="/" />;
  }
  if (data.registrationStatus.can_view_registration_slip)
    return <Redirect to="/registration-slip" />;
  if (data.registrationStatus.calendar_not_activated) return <CalenderNotActivated />;
  if (data.registrationStatus.course_offering_not_defined) return <CourseOfferingNotDefined />;
  if (data.registrationStatus.eligibility)
    return <p className="bold">{data.registrationStatus.eligibility}</p>;
  if (data.registrationStatus.payment_form)
    return <Payment props={props} message={data.registrationStatus.payment_form} />;
  if (data.registrationStatus.can_register) return <Redirect to="/registration-form" />;
  return <div>{data.registrationStatus}</div>;
};

export default RegistrationHome;
