import gql from "graphql-tag";

export const ADD_DROP_LIST = gql`
  {
    addDropList {
      id
      requestFor
      confirmationStatus
      course {
        creditHour
        ects
        codeAndTitle
      }
      courseAssignment {
        instructorName
        offeredCourse {
          curriculum {
            program {
              name
            }
          }
        }
      }
      section {
        name
      }
      registration {
        academicYearSemester {
          semesterName
        }
      }
    }
  }
`;
