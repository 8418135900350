import React from "react";
import query from "../../queries/getAssessmentResultForEnrollment";
import { useQuery } from "@apollo/react-hooks";

function AssessmentResultShow(props) {
  const { loading, data } = useQuery(query, {
    variables: { id: props.course_enrollment_id },
  });

  if (loading) return <p>Loading ...</p>;

  const {
    instructorName,
    course,
    sumOfMaximumMark,
    sumOfResults,
    studentGrade,
    assessmentResults,
  } = data.assessmentResultForEnrollment;
  return (
    <div className="card" key={instructorName}>
      <div className="card-body">
        <div className="row">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <p>
              <b>Course Title:</b> {course.courseTitle}
            </p>
            <p>
              <b>Course Code:</b> {course.courseCode}
            </p>
            <p>
              <b>Intructor:</b> {instructorName}
            </p>
          </div>
          <div className="col-sm-12 col-md-8 col-lg-8 table-responsive">
            <table className="table table-striped text-nowrap">
              <thead className="thead-light">
                <tr>
                  <th>SN</th>
                  <th>Assessment Name</th>
                  <th>Assessment Type</th>
                  <th>Maximum Mark</th>
                  <th>Result</th>
                  <th>Grade</th>
                </tr>
              </thead>
              <tbody>
                {assessmentResults.map(({ id, result, assessment }, index) => (
                  <tr key={id}>
                    <td>{index + 1}</td>
                    <td>{assessment.assessmentName}</td>
                    <td>{assessment.assessmentType}</td>
                    <td>{assessment.maximumMark}</td>
                    <td>{result}</td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th style={{ textAlign: "right" }} colSpan="3">
                    Totals
                  </th>
                  <th align="left">{sumOfMaximumMark}</th>
                  <th align="left">
                    {sumOfResults}/{sumOfMaximumMark}
                  </th>
                  <td align="left" className="bold">
                    {studentGrade ? studentGrade.letterGrade : ""}
                  </td>

                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssessmentResultShow;
