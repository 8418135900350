import gql from "graphql-tag";

export default gql`
  {
    studentAttendance {
      id
      courseAssignmentSection {
        id
        attendanceAll
        courseAssignment {
          id
          instructorName
          offeredCourse {
            id
            course {
              id
              courseTitle
            }
          }
        }
      }
    }
  }
`;
