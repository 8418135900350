import React from "react";
import { Link } from "react-router-dom";
import ActiveSemester from "../common/ActiveSemester";

const PermittedLinks = () => {
  const toggleSidebar = (e) => {
    let body = document.getElementById("body");
    if (body.classList.contains("sidebar-open")) {
      document.getElementById("body").classList.remove("sidebar-open");
      document.getElementById("body").classList.add("sidebar-collapse");
    }
  };

  return (
    <aside className="main-sidebar sidebar-light-primary elevation-1">
      <Link to="/" className="brand-link text-center" onClick={toggleSidebar}>
        <img src="/dist/img/astu_logo.svg" alt="AdminLTE Logo" className="img-circle" width="40%" />
      </Link>

      <div className="sidebar">
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column nav-child-indent nav-flat"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <ActiveSemester />
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/student_profile" onClick={toggleSidebar}>
                <i className="nav-icon fas fa-user"></i>
                <p>Student Profile</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/enrollment_record" onClick={toggleSidebar}>
                <i className="nav-icon fas fa-newspaper"></i>
                <p>Enrollment Record</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/academic_history" onClick={toggleSidebar}>
                <i className="nav-icon fas fa-history"></i>
                <p>Academic History</p>
              </Link>
            </li>
            <li className="nav-item has-treeview" id="one">
              <Link
                to="#"
                className="nav-link"
                onClick={(e) => {
                  document.getElementById("one").classList.toggle("menu-open");
                }}
              >
                <i className="nav-icon fas fa-graduation-cap"></i>
                <p>
                  Academics
                  <i className="right fas fa-angle-left"></i>
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/curriculum" className="nav-link" onClick={toggleSidebar}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>Curriculum</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/registrations" className="nav-link" onClick={toggleSidebar}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>Registration</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/course-audit" className="nav-link" onClick={toggleSidebar}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>Course Audit</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/payment" className="nav-link" onClick={toggleSidebar}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>Payment</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item has-treeview" id="two">
              <Link
                className="nav-link"
                to="#"
                onClick={(e) => {
                  document.getElementById("two").classList.toggle("menu-open");
                }}
              >
                <i className="nav-icon fas fa-question-circle"></i>
                <p>
                  Request
                  <i className="right fas fa-angle-left"></i>
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/add-drop" className="nav-link" onClick={toggleSidebar}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>Add Drop</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/withdrawals" className="nav-link" onClick={toggleSidebar}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>Withdrawal</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/clearances" className="nav-link" onClick={toggleSidebar}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>Clearances</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/complaint-results" className="nav-link" onClick={toggleSidebar}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>Compliant Results</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/course-exemption-list" className="nav-link" onClick={toggleSidebar}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>Course Exemption</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/dormitory" onClick={toggleSidebar}>
                <i className="nav-icon fas fa-building"></i>
                <p>Dormitory</p>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default PermittedLinks;
