import React from "react";
import { HEADER_PROFILE } from "./../../queries/getHeaderProfile";
import { useQuery } from "@apollo/react-hooks";
import ListItem from "./../layouts/ListItem";

const HeaderProfile = (props) => {
  const { loading, data } = useQuery(HEADER_PROFILE);
  if (loading) return <p>Loading ...</p>;
  return (
    <div>
      <div className="row">
        <div className="col-md-2 col-lg-2 col-sm-12">
          <img
            src={data.headerProfile.applicant.person.photoUrl}
            className="img-thumbnail"
            alt="Cinque Terre"
            width="100%"
          />
        </div>
        <div className="col-md-5 col-lg-5 col-sm-12">
          <ListItem label="Full Name" data={data.headerProfile.applicant.person.fullName} />
          <ListItem label="ID Number" data={data.headerProfile.idNumber} />
          <ListItem label="Admission Year" data={data.headerProfile.applicant.admissionYear} />
          <ListItem label="Dormitory" data={data.headerProfile.dormitoryView} />
        </div>
        <div className="col-md-5 col-lg-5 col-sm-12">
          <ListItem label="Program" data={data.headerProfile.program.name} />
          <ListItem label="Admission" data={data.headerProfile.applicant.admission.name} />
          <ListItem label="Class Year" data={data.headerProfile.classYear} />
          <ListItem label="Section" data={data.headerProfile.section} />
        </div>
      </div>
      <hr />
    </div>
  );
};

export default HeaderProfile;
