import gql from "graphql-tag";

export default gql`
  {
    studentCourseEnrollments {
      id
      course {
        id
        titleAndCode
      }
    }
  }
`;
