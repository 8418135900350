import gql from "graphql-tag";

export const GET_ASESSMENT_RESULT = gql`
  {
    assessmentResult {
      id
      instructorName
      sumOfMaximumMark
      sumOfResults
      course {
        id
        courseTitle
        courseCode
      }
      studentGrade {
        id
        letterGrade
      }
      assessmentResults {
        id
        result
        assessment {
          id
          assessmentName
          maximumMark
          assessmentType
        }
      }
    }
  }
`;
