import gql from "graphql-tag";

export const GET_PERSON = gql`
  query getPerson($id: ID!) {
    getPerson(id: $id) {
      id
      firstName
      fatherName
      grandFatherName
      amharicFirstName
      amharicFatherName
      amharicGrandFatherName
      gender
      maritalStatus
      nationality
      region{
        id
        name
      }
      disability
      dateOfBirth
      fullNameInAmharic
      amharicFullName
      placeOfBirth
      photoUrl
      user {
        userName
      }
      applicant {
        student {
          classYear
          program {
            name
          }
        }
      }
    }
  }
`;

export const GET_APPLICANT = gql`
  query getApplicant($personId: ID!) {
    getApplicant(personId: $personId) {
      id
      student {
        id
        idNumber
      }
      structure {
        id
        name
      }
      admission {
        id
        fullName
      }
      classYear {
        id
        name
      }
      program {
        id
        fullName
      }
      academicYear {
        id
        year
      }
      registrationNumber
      matriculationResult
      tuitionType
    }
  }
`;

export const GET_CONTACT_ADDRESS = gql`
  query getContactAddress($personId: ID!) {
    getContactAddress(personId: $personId) {
      id
      country
      streetAddress
      homeTelephone
      mobile
      workTelephone
      email
      zone{
        id
        name
      }
      woreda
      kebele
    }
  }
`;

export const GET_EDUCATION = gql`
  query getEducation($id: ID!) {
    getEducation(id: $id) {
      id
      institution
      studyField
      qualification
      from
      to
      grade
      scale
      educationType
      awardType
    }
  }
`;

export const GET_EDUCATIONS = gql`
  query getEducations($personId: ID!) {
    getEducations(personId: $personId) {
      id
      institution
      studyField
      qualification
      from
      to
      grade
      scale
      educationType
      awardType
    }
  }
`;

export const GET_EMERGENCY_CONTACT = gql`
  query getEmergencyContact($personId: ID!) {
    getEmergencyContact(personId: $personId) {
      id
      fullName
      relationship
      homeTelephone
      mobile
      workTelephone
    }
  }
`;

export const GET_EXPERIENCE = gql`
  query getExperience($id: ID!) {
    getExperience(id: $id) {
      id
      companyName
      title
      location
      from
      to
      description
    }
  }
`;

export const GET_EXPERIENCES = gql`
  query getExperiences($personId: ID!) {
    getExperiences(personId: $personId) {
      id
      companyName
      title
      location
      from
      to
      description
      documentUrl
    }
  }
`;

export const GET_FAMILY_BACKGROUND = gql`
  query getFamilyBackground($id: ID!) {
    getFamilyBackground(id: $id) {
      id
      fullName
      relation
      dateOfBirth
      educationLevel
      occupation
    }
  }
`;

export const GET_FAMILY_BACKGROUNDS = gql`
  query getFamilyBackgrounds($personId: ID!) {
    getFamilyBackgrounds(personId: $personId) {
      id
      fullName
      relation
      dateOfBirth
      educationLevel
      occupation
    }
  }
`;

export const GET_COURSE_ASSIGNMENTS_BY_COURSE_OFFERING = gql`
  query courseAssignments($courseOfferingId: ID!) {
    courseAssignments(courseOfferingId: $courseOfferingId) {
      id
      isAddQuotaFull
      employee {
        id
        person {
          fullName
        }
      }
    }
  }
`;

export const GET_COURSE_ASSIGNMENT_SECTIONS_BY_COURSE_ASSIGNMENT = gql`
  query courseAssignmentSections($courseAssignmentId: ID!) {
    courseAssignmentSections(courseAssignmentId: $courseAssignmentId) {
      id
      name
      sectionNo
    }
  }
`;

export const GET_DEPARTMENT_PREFERENCES = gql`
  query getDepartmentPreferences($personId: ID!) {
    getDepartmentPreferences(personId: $personId) {
      id
      program {
        id
        fullName
      }
      preference
    }
  }
`;

export const GET_GRADE_COMPLAINT = gql`
  query getGradeComplaint($id: ID!) {
    getGradeComplaint(id: $id) {
      id
      reason
      others
      documentUrl
    }
  }
`;

export const GET_MESSAGE = gql`
  query getMessage($id: ID!) {
    getMessage(id: $id) {
      id
      sender {
        id
        person {
          id
          fullName
        }
      }
      subject
      body
    }
  }
`;

export const GET_ASSIGNMENT = gql`
  query getAssignment($id: ID!) {
    getAssignment(id: $id) {
      id
      name
      description
      canSubmit
      canLead
      assignmentType
      dueDate
      documentName
      documentUrl
      myGroup {
        id
        groupNumber
        groupLeader {
          id
          idNumber
        }
        students {
          id
          idNumber
          applicant {
            id
            person {
              id
              fullName
            }
          }
        }
      }
      courseAssignment {
        id
        offeredCourse {
          id
          course {
            id
            titleAndCode
          }
        }
      }
    }
  }
`;

export const GET_ANNOUNCEMENT = gql`
  query GET_ANNOUNCEMENT($id: ID!) {
    getAnnouncement(id: $id) {
      id
      academicYearSemester {
        id
        semesterName
      }
      title
      description
      createdAt
    }
  }
`;
