import gql from "graphql-tag";

export default gql`
  query enrollmentRecord($id: ID!) {
    enrollmentRecord(id: $id) {
      id
      curriculumType
      status
      cafeStatus
      isModular
      classYear {
        id
      }
      academicYearSemester {
        id
        semesterName
      }
      courseEnrollments {
        id
        addDropStatus
        course {
          id
          courseTitle
          courseCode
          ects
          creditHour
        }
        studentGrade {
          id
          letterGrade
          gradePoint
          modular {
            id
            moduleTitle
            moduleCode
            moduleEcts
            moduleType
          }
        }
      }
    }
  }
`;
