import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik, Form } from "formik";
import WField from "../layouts/WField";
import WFselect from "../layouts/WFselect";
import WFormErrors from "../layouts/WFormErrors";
import { ContactAddressValidator } from "../validations/FormValidation";
import { GET_CONTACT_ADDRESS } from "../../queries/find/byId";
import { EDIT_CONTACT_ADDRESS_MUTATION } from "../../mutations/contactAddress";
import { NATIONALITY_QUERY } from "../../queries/constants";
import swal from "sweetalert";
import Regions from "./Regions";

function EditContactAddress(props) {
  const { loading, data } = useQuery(GET_CONTACT_ADDRESS, {
    variables: props.match.params,
  });

  const [updateContactAddress] = useMutation(EDIT_CONTACT_ADDRESS_MUTATION);
  const [updateErrors, setUpdateErrors] = useState({});

  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-edit pr-1"></span>Edit Contact Address
        </h3>
      </div>
      <Formik
        initialValues={{
          id: data.getContactAddress.id,
          country: data.getContactAddress.country || "",
          zoneId:  data.getContactAddress.zone?.id || "",
          woreda:  data.getContactAddress.woreda || "",
          kebele:  data.getContactAddress.kebele || "",
          streetAddress: data.getContactAddress.streetAddress || "",
          homeTelephone: data.getContactAddress.homeTelephone || "",
          mobile: data.getContactAddress.mobile || "",
          workTelephone: data.getContactAddress.workTelephone || "",
          email: data.getContactAddress.email || "",
        }}
        validationSchema={ContactAddressValidator}
        onSubmit={(values, { setSubmitting }) => {
          updateContactAddress({ variables: values }).then(
            (result) => {
              setSubmitting(false);
              swal("Update Contact Address", "Contact Address Updated Successfully!");
              props.history.push("/student_profile");
            },
            (error) => {
              setSubmitting(false);
              setUpdateErrors(error.graphQLErrors[0].extensions.validation);
            }
          );
        }}
      >
        {({ isSubmitting }) => (
          <div className="card-body row">
            <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
              <WFormErrors errors={updateErrors} />
              <Form>
                <WFselect
                  ws_name="country"
                  ws_query={NATIONALITY_QUERY}
                  ws_label="Country"
                  hash={true}
                />
                <Regions />
                <WField wf_type="text" wf_name="woreda" wf_label="Woreda" />
                <WField wf_type="text" wf_name="kebele" wf_label="Kebele" />
                <WField wf_type="text" wf_name="streetAddress" wf_label="Street Address" />
                <WField wf_type="text" wf_name="homeTelephone" wf_label="Home Telephone" />
                <WField wf_type="text" wf_name="mobile" wf_label="Mobile" />
                <WField wf_type="text" wf_name="workTelephone" wf_label="Work Telephone" />
                <WField wf_type="email" wf_name="email" wf_label="Email" />
                <div className="form-group">
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary ">
                    Update
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default EditContactAddress;
