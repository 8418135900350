import gql from "graphql-tag";

export const EXEMPT_COURSES = gql`
  mutation exemptCourses(
    $instituteName: String!
    $document: Upload
    $equivalentSemester: String!
    $courses: [JSON!]!
  ) {
    exemptCourses(
      instituteName: $instituteName
      document: $document
      equivalentSemester: $equivalentSemester
      courses: $courses
    )
  }
`;
