import React, { useState } from 'react';
import { useMutation } from "@apollo/react-hooks";
import { Formik, Form } from "formik";
import WField from "../layouts/WField";
import WFormErrors from "../layouts/WFormErrors";
import { UpdatePasswordValidator } from "../validations/FormValidation";
import { UPDATE_PASSWORD_MUTATION } from "../../mutations/updatePassword";
import { useQuery } from "@apollo/react-hooks";
import query from "../../queries/getUser"
import swal from "sweetalert";


function UpdatePassword(props) {
	const { loading, data } = useQuery(query);
  	const [updatePassword] = useMutation(UPDATE_PASSWORD_MUTATION);
  	const [updateErrors, setUpdateErrors] = useState({});
	if (loading) return <p>Loading ...</p>;
	return (
		<div className="card card-primary">
	      <div className="card-header bg-info">
	        <h3 className="card-title">
	          <span className="fas fa-edit pr-1"></span>Update Password
	        </h3>
	      </div>
	      <Formik
	        initialValues={{
	          email: data.user.email,
	          currentPassword: "",
	          newPassword: "",
	          confirmPassword: "",
	        }}
	        validationSchema={UpdatePasswordValidator}
	        onSubmit={(values, { setSubmitting }) => {
	          updatePassword({ variables: values }).then(
	            (result) => {
	              setSubmitting(false);
	              swal("Update  Password", "Password Updated Successfully!");
	              props.history.push("/student_profile");
	            },
	            (error) => {
	              setSubmitting(false);
	              setUpdateErrors(error.graphQLErrors[0].extensions.validation);
	            }
	          );
	        }}
	      >
	        {({ isSubmitting }) => (
	          <div className="card-body row">
	            <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
	              <WFormErrors errors={updateErrors} />
	              <Form>

	                <WField wf_type="email" wf_name="email" wf_label="Email" />
	                <WField wf_type="password" wf_name="currentPassword" wf_label="Current Password" />
	                <WField wf_type="password" wf_name="newPassword" wf_label="New Password" />
	                <WField wf_type="password" wf_name="confirmPassword" wf_label="Confirm Password" />
	                <div className="form-group">
	                  <button type="submit" disabled={isSubmitting} className="btn btn-primary ">
	                    Update
	                  </button>
	                </div>
	              </Form>
	            </div>
	          </div>
	        )}
	      </Formik>
	    </div>
	);
}

export default UpdatePassword;
