import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { CLEARANCE_FORM_DATA } from "../../queries/getClearanceFormData";
import { SUBMIT_CLEARANCE_REQUEST } from "../../mutations/Clearance";
import HeaderProfile from "../common/HeaderProfile";
import Select from "react-select";
import { Form, Formik } from "formik";
import { sweetAlert } from "../common/SweetAlert";
import ErrorMessages from "../common/ErrorMessages";
import swal from "sweetalert";
import { CLEARANCE_LIST } from "../../queries/getClearanceFormData";
import _ from "lodash";

const Clearance = (props) => {
  const [clearance] = useMutation(SUBMIT_CLEARANCE_REQUEST, {
    refetchQueries: [{ query: CLEARANCE_LIST }],
    awaitRefetchQueries: true,
  });
  const { loading, data } = useQuery(CLEARANCE_FORM_DATA);
  const [errors, setErrors] = useState();

  if (loading) return <p>Loading ...</p>;

  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-check pr-1"></span>Clearance Form
        </h3>
      </div>
      <div className="card-body">
        <hr />
        <HeaderProfile />
        <div className="row">
          <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
            <Formik
              initialValues={{
                academicYearSemester: "",
                reason: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                let tempErrors = [];

                if (!values["academicYearSemester"]) {
                  tempErrors.push("Academic Semester is required.");
                }
                if (_.isEmpty(values["reason"])) {
                  tempErrors.push("Reason is required.");
                }
                setErrors(tempErrors);
                if (tempErrors.length > 0) {
                  setSubmitting(false);
                  return;
                }
                clearance({ variables: values }).then(
                  (result) => {
                    setSubmitting(false);
                    sweetAlert(result.data.clearance);
                    if (result.data.clearance.success) {
                      props.history.push("/clearances");
                    }
                  },
                  (error) => {
                    setSubmitting(false);
                    swal("Error", error);
                  }
                );
              }}
            >
              {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
                <Form>
                  <ErrorMessages errors={errors} />

                  <div className="form-group row">
                    <label className="col-form-label col-sm-3">Academic year semester</label>
                    <div className="col-sm-9">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={[data.clearanceFormData.activeAcademicYearSemeters]}
                        onChange={(e) =>
                          setFieldValue("academicYearSemester", parseInt(e.value), false)
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-form-label col-sm-3">Reasons</label>
                    <div className="col-sm-9">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={groupBy(data)}
                        onChange={(e) => setFieldValue("reason", e.value, false)}
                      />
                    </div>
                  </div>

                  <div className="text-center mt-4">
                    <input
                      type="submit"
                      value="Submit Clearance Request"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

function groupBy(data) {
  return _.chain(data.clearanceFormData.clearanceReasons)
    .map(function (v) {
      return { value: v, label: v };
    })
    .value();
}

export default Clearance;
