import * as Yup from "yup";
const REQUIRED = "Required";
const EMAIL = "Invalid Email";

export const BankAccountValidator = Yup.object().shape({
  bankAccount: Yup.string().matches(/^\d+$/,"Only Numbers!").length(13).required(REQUIRED),
});

export const UpdatePasswordValidator = Yup.object().shape({
  email: Yup.string().email().required(REQUIRED),
  currentPassword: Yup.string().required(REQUIRED),
  newPassword: Yup.string().min(8).matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,"Minimum eight characters, at least one uppercase, one lowercase and one number!").required(REQUIRED),
  confirmPassword: Yup.string().min(8).required(REQUIRED),
});

export const PersonValidator = Yup.object().shape({
  maritalStatus: Yup.string().required(REQUIRED),
  nationality: Yup.string().required(REQUIRED),
  dateOfBirth: Yup.date().required(REQUIRED),
  placeOfBirth: Yup.string().required(REQUIRED),
});

export const ApplicantValidator = Yup.object().shape({
  // tuitionType: Yup.string().required(REQUIRED)
});

export const ContactAddressValidator = Yup.object().shape({
  country: Yup.string().required(REQUIRED),
  mobile: Yup.string().required(REQUIRED),
  zoneId: Yup.string().required("Region and zone is Required"),
  woreda: Yup.string().required(REQUIRED),
  kebele: Yup.string().required(REQUIRED),
  email: Yup.string().required(REQUIRED).email(EMAIL),
});

export const EmergencyContactValidator = Yup.object().shape({
  fullName: Yup.string().required(REQUIRED),
  relationship: Yup.string().required(REQUIRED),
  mobile: Yup.string().required(REQUIRED),
});

export const FamilyBackgroundValidator = Yup.object().shape({
  fullName: Yup.string().required(REQUIRED),
});

export const EducationValidator = Yup.object().shape({
  institution: Yup.string().required(REQUIRED),
  grade: Yup.string().required(REQUIRED),
  scale: Yup.string().required(REQUIRED),
});

export const ExperienceValidator = Yup.object().shape({
  companyName: Yup.string().required(REQUIRED),
  title: Yup.string().required(REQUIRED),
  location: Yup.string().required(REQUIRED),
});

export const GradeComplaintValidator = Yup.object().shape({});
