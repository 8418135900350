import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_PERSON } from "../../queries/find/byId";
import { useApolloClient } from "@apollo/react-hooks";
import axios from "axios";
import { createBrowserHistory } from "history";

const Navbar = () => {
  const client = useApolloClient();

  const { loading, data } = useQuery(GET_PERSON, {
    variables: { id: 121 },
  });
  if (loading) return null;
  const { firstName, fatherName, photoUrl, applicant } = data.getPerson;

  const handleClick = (e) => {
    e.preventDefault();
    const history = createBrowserHistory();

    const headers = {
      "access-token": localStorage.getItem("access-token"),
      client: localStorage.getItem("client"),
      uid: localStorage.getItem("uid"),
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/auth/sign_out`, {
        headers: headers,
      })
      .then((res) => {
        localStorage.clear();
        client.resetStore();
        history.push("/");
        window.location.reload();
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className="nav-link" data-widget="pushmenu" to="#">
            <i className="fas fa-bars"></i>
          </Link>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown">
          <Link className="nav-link" data-toggle="dropdown" to="#">
            <i className="far fa-user"></i>
          </Link>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <Link to="#" className="dropdown-item">
              <div className="media">
                <img
                  src={photoUrl}
                  alt="student-profile"
                  width="100%"
                  className="img-size-50 mr-3 img-circle"
                />
                <div className="media-body">
                  <h3 className="dropdown-item-title">
                    {firstName} {fatherName}
                    <span className="float-right text-sm text-danger">
                      <i className="fas fa-user"></i>
                    </span>
                  </h3>
                  <p className="text-sm">{applicant.student.program.name}</p>
                  <p className="text-sm text-muted">{applicant.student.classYear}</p>
                </div>
              </div>
            </Link>
            <div className="dropdown-divider"></div>
            <Link to="/change-password" className="dropdown-item">
              <i className="fas fa-user mr-2"></i> Change Password
            </Link>
            <Link to="/" className="dropdown-item" onClick={handleClick}>
              <i className="fas fa-sign-out-alt mr-2"></i> Log out
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
