import gql from "graphql-tag";

export const CLEARANCE_FORM_DATA = gql`
  {
    clearanceFormData
  }
`;

export const CLEARANCE_LIST = gql`
  {
    clearanceList {
      id
      reason
      hodStatus
      policeStatus
      sscStatus
      proctorStatus
      librarianStatus
      academicYearSemester {
        semesterName
      }
      student {
        idNumber
        applicant {
          person {
            fullName
          }
        }
      }
    }
  }
`;
