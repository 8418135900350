import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Formik, Form } from "formik";
import WField from "../layouts/WField";
import WFormErrors from "../layouts/WFormErrors";
import { EmergencyContactValidator } from "../validations/FormValidation";
import { GET_EMERGENCY_CONTACT } from "../../queries/find/byId";
import { CREATE_EMERGENCY_CONTACT_MUTATION } from "../../mutations/emergencyContact";
import swal from "sweetalert";

function CreateEmergencyContact(props) {
  const [createEmergencyContact] = useMutation(CREATE_EMERGENCY_CONTACT_MUTATION, {
    refetchQueries: [
      {
        query: GET_EMERGENCY_CONTACT,
        variables: { personId: props.match.params.personId },
      },
    ],
  });
  const [createErrors, setCreateErrors] = useState({});

  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-edit pr-1"></span>Edit Emergency Contact
        </h3>
      </div>
      <Formik
        initialValues={{
          personId: props.match.params.personId,
          fullName: "",
          relationship: "",
          homeTelephone: "",
          mobile: "",
          workTelephone: "",
        }}
        validationSchema={EmergencyContactValidator}
        onSubmit={(values, { setSubmitting }) => {
          createEmergencyContact({ variables: values }).then(
            (result) => {
              setSubmitting(false);
              swal("Create  Emergency Contact", "Emergency Contact Created Successfully!");
              props.history.push("/student_profile");
            },
            (error) => {
              setSubmitting(false);
              setCreateErrors(error.graphQLErrors[0].extensions.validation);
            }
          );
        }}
      >
        {({ isSubmitting }) => (
          <div className="card-body row">
            <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
              <WFormErrors errors={createErrors} />
              <Form>
                <WField wf_type="text" wf_name="fullName" wf_label="Full Name" />
                <WField wf_type="text" wf_name="relationship" wf_label="Relationship" />
                <WField wf_type="text" wf_name="homeTelephone" wf_label="Home Telephone" />
                <WField wf_type="text" wf_name="mobile" wf_label="Mobile" />
                <WField wf_type="text" wf_name="workTelephone" wf_label="Work Telephone" />
                <div className="form-group">
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary ">
                    Create
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default CreateEmergencyContact;
