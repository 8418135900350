import React from "react";
import { MdError } from "react-icons/all";
import _ from "lodash";

const ErrorMessages = (props) => {
  if (_.isEmpty(props.errors)) return null;
  return (
    <div>
      <div className="alert alert-danger" role="alert">
        <h4 className="alert-heading">
          <MdError /> Please check the following errors
        </h4>
        <ul>
          {props.errors.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ErrorMessages;
