import React from "react";
import { PENDING_COURSE_EXEMPTIONS } from "../../queries/getPendingCourseExemptions";
import { DELETE_COURSE_EXEMPTION } from "./../../mutations/DeleteCourseExemption";
import { useQuery, useMutation } from "@apollo/react-hooks";
import ListItem from "../layouts/ListItem";
import { FaTrash, FaCheck, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { sweetAlert } from "../common/SweetAlert";
import swal from "sweetalert";
import _ from "lodash";

const CourseExemptionList = () => {
  const { loading, data, refetch } = useQuery(PENDING_COURSE_EXEMPTIONS);
  const [deleteCourseExemption] = useMutation(DELETE_COURSE_EXEMPTION);
  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-ban pr-1"></span>Course Exemption List
        </h3>
      </div>
      <div className="card-body">
        {_.isEmpty(data.pendingCourseExemptions) ? <p>No Course Exemption</p> : null}

        {data.pendingCourseExemptions.map(
          ({
            instituteName,
            academicYear,
            documentUrl,
            exemptionEquivalentAcademicSemesters,
            courseExemptionDetails,
          }) => (
            <div className="mb-3" key={instituteName}>
              <div className="row">
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <ListItem label="Institute Name" data={instituteName} />
                  <ListItem label="Academic Year" data={academicYear.year} />
                  <a
                    className="btn btn-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_API_URL}${documentUrl}`}
                  >
                    View Document
                  </a>
                  <br />

                  <strong className="label-colen">Equivalent Academic Semester</strong>
                  <ul>
                    {exemptionEquivalentAcademicSemesters.map(({ id, academicYearSemester }) => (
                      <li key={id}>{academicYearSemester.semesterName}</li>
                    ))}
                  </ul>
                </div>
                <div className="table-responsive">
                  <div className="col-sm-12 col-md-9 col-lg-9">
                    <table className="table  table-bordered">
                      <thead>
                        <tr>
                          <th>Course To be Exempted</th>
                          <th>Equivalent Course</th>
                          <th>Credit Hour</th>
                          <th>ECTS</th>
                          <th>Grade/Mark</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {courseExemptionDetails.map(
                          ({
                            id,
                            course,
                            equivalentCourseName,
                            equivalentCourseCreditHour,
                            equivalentCourseEcts,
                            gradeOrMark,
                            approval,
                          }) => (
                            <tr key={id}>
                              <td>{course.titleAndCode}</td>
                              <td>{equivalentCourseName}</td>
                              <td>{equivalentCourseCreditHour}</td>
                              <td>{equivalentCourseEcts}</td>
                              <td>{gradeOrMark}</td>
                              <td>
                                <strong
                                  className={
                                    approval === 1
                                      ? "text-success"
                                      : approval === 0
                                      ? "text-danger"
                                      : "text-primary"
                                  }
                                >
                                  {approval === 1
                                    ? "Approved"
                                    : approval === 0
                                    ? "Declined"
                                    : "Pending"}
                                </strong>
                              </td>
                              <td>
                                {approval === 1 ? (
                                  <span className="text-success">
                                    <FaCheck />
                                  </span>
                                ) : approval === 0 ? (
                                  <span className="text-danger">
                                    <FaTimes />
                                  </span>
                                ) : (
                                  <button
                                    className="btn  btn-danger"
                                    type="button"
                                    onClick={(e) => handleClick(id)}
                                  >
                                    <FaTrash />
                                  </button>
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
        <div className="tetx-center">
          <Link className="btn btn-primary" to="/course-exemption-form">
            New Course Exemption
          </Link>
        </div>
      </div>
    </div>
  );

  function handleClick(id) {
    deleteCourseExemption({
      variables: { courseExemptionId: Number(id) },
    }).then(
      (result) => {
        sweetAlert(result.data.deleteCourseExemption);
        if (result.data.deleteCourseExemption.success) {
          refetch();
        }
      },
      (error) => {
        swal("Error", error);
      }
    );
  }
};

export default CourseExemptionList;
