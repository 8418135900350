import gql from "graphql-tag";

export const STUDENT_TRANSCRIPT = gql`
  {
    studentTranscript {
      id
      curriculumType
      classYear {
        id
        name
      }
      academicYearSemester {
        id
        semesterName
      }
      courseEnrollments {
        id
        course {
          id
          courseCode
          courseTitle
          ects
          creditHour
        }
        studentGrade {
          id
          letterGrade
          gradePoint
        }
      }

      gpa {
        id
        semesterEcts
        currentSemesterTotalCreditHour
        currentSemesterTotalGradePoint
        semesterGpa

        totalCreditHour
        totalGradePoint
        cumulativeGpa
        status
      }
    }

    pendingCourseExemptions {
      id
      instituteName
      academicYear {
        year
      }
      exemptionEquivalentAcademicSemesters {
        id
        academicYearSemester {
          semesterName
        }
      }
      courseExemptionDetails {
        id
        course {
          courseCode
          courseTitle
          titleAndCode
        }
        equivalentCourseName
        equivalentCourseCreditHour
        equivalentCourseEcts
        gradeOrMark
        approval
      }
    }
  }
`;
