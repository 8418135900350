import React from "react";
import query from "../../queries/getStudentAttendance";
import { useQuery } from "@apollo/react-hooks";

function Attendance() {
  const { loading, data } = useQuery(query);
  if (loading) return <p>Loading ...</p>;

  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table  table-striped table-bordered text-nowrap">
            <thead>
              <tr>
                <th>Course</th>
                <th>Instructor</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data.studentAttendance &&
                data.studentAttendance.map(
                  ({ id, courseAssignmentSection }) => (
                    <tr key={id}>
                      <td>
                        {
                          courseAssignmentSection?.courseAssignment?.offeredCourse?.course?.courseTitle
                        }
                      </td>
                      <td>
                        {
                          courseAssignmentSection?.courseAssignment
                            .instructorName
                        }
                      </td>
                      <td>
                        {courseAssignmentSection?.attendanceAll.map(
                          (value, index) => (
                            <p key={index} className="m-0">
                              <b>{value[0] + ": "}</b> {value[1]}
                            </p>
                          )
                        )}
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Attendance;
