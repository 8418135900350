import gql from "graphql-tag";

export default gql`
  {
    addDrops {
      id
      requestFor
      confirmationStatus
      course {
        creditHour
        ects
        codeAndTitle
      }
      courseAssignment {
        instructorName
      }
      section {
        name
      }
      registration {
        academicYearSemester {
          semesterName
        }
      }
    }
  }
`;
