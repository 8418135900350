import React from "react";

function CalenderNotActivated() {
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fa fa-registered pr-1"></span>Registration
        </h3>
      </div>
      <div className="card-body row">
        <div className="col-md-6 col-lg-6 col-sm-12">
          <p className="text-danger">
            Calender is not activated. Please check your University Calendar.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CalenderNotActivated;
