import gql from "graphql-tag";

export default gql`
  query gradeReport($id: ID!) {
    gradeReport(id: $id) {
      id
      isModular
      prevGpa {
        id
        status
        currentSemesterTotalCreditHour
        currentSemesterTotalGradePoint
        semesterGpa
        cumulativeGpa
        totalCreditHour
        totalGradePoint
      }
      gpa {
        id
        status
        currentSemesterTotalCreditHour
        currentSemesterTotalGradePoint
        semesterGpa
        cumulativeGpa
        totalCreditHour
        totalGradePoint
      }
      academicYearSemester {
        id
        semesterName
      }
      courseEnrollments {
        id
        course {
          id
          courseCode
          courseTitle
          creditHour
          ects
        }
        studentGrade {
          id
          letterGrade
          gradePoint
          repeatedLetterGrade
          complainable
          gradeComplaint {
            id
          }
          modular {
            id
            moduleTitle
            moduleCode
            moduleEcts
            moduleType
          }
        }
      }
    }
  }
`;
