import React from "react";
import _ from "lodash";

const ErrorMessage = (props) => {
  if (_.isEmpty(props.message)) return "Could not found the error message :(";
  return (
    <div className="card card-primary">
      <div className="card-header bg-danger">
        <h3 className="card-title text-bold">
          <span className="fas fa-exclamation-triangle pr-1"></span>Error
        </h3>
      </div>
      <div className="card-body">
        <p className="text-danger">{props.message}</p>
      </div>
    </div>
  );
};

export default ErrorMessage;
