import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { CLEARANCE_LIST } from "../../queries/getClearanceFormData";
import { Link } from "react-router-dom";
import _ from "lodash";

const Clearances = (props) => {
  const { loading, data } = useQuery(CLEARANCE_LIST);
  if (loading) return <p>Loading ...</p>;

  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-check pr-1"></span>Clearances
        </h3>
      </div>
      <div className="card-body">
        <List data={data} />
        <div className="text-center mt-3">
          <Link className="btn btn-primary" to="/clearance-form">
            New Clearance
          </Link>
        </div>
      </div>
    </div>
  );
};

const List = (props) => {
  if (_.isEmpty(props.data.clearanceList)) return null;
  return (
    <div className="table-responsive">
      <table className="table  table-bordered text-nowrap">
        <thead>
          <tr>
            <th rowSpan="2">Student</th>
            <th rowSpan="2">ID Number</th>
            <th rowSpan="2">Semester</th>
            <th colSpan="6" className="text-center">
              Approval Status
            </th>
          </tr>

          <tr>
            <th>HoD</th>
            <th>Library</th>
            <th>Police</th>
            <th>Proctor</th>
            <th>Ssc</th>
          </tr>
        </thead>
        <tbody>
          {props.data.clearanceList.map(
            ({
              id,
              reason,
              academicYearSemester,
              hodStatus,
              policeStatus,
              sscStatus,
              proctorStatus,
              librarianStatus,
              student,
            }) => (
              <tr key={id}>
                <td>{student.applicant.person.fullName}</td>
                <td>{student.idNumber}</td>
                <td>{academicYearSemester.semesterName}</td>
                <td>{hodStatus}</td>
                <td>{librarianStatus}</td>
                <td>{policeStatus}</td>
                <td>{proctorStatus}</td>
                <td>{sscStatus}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};
export default Clearances;
