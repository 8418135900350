import gql from "graphql-tag";

export default gql`
  {
    person {
      id
      fullName
    }
  }
`;
