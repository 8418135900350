import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import ListItem from "../layouts/ListItem";

function Person(props) {
  const { loading, data } = useQuery(props.query, {
    variables: { id: props.person_id },
  });
  if (loading) return <p>Loading ...</p>;
  const {
    user,
    firstName,
    fatherName,
    grandFatherName,
    amharicFullName,
    gender,
    maritalStatus,
    nationality,
    region,
    disability,
    dateOfBirth,
    fullNameInAmharic,
    placeOfBirth,
    photoUrl,
  } = data.getPerson;
  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-3">
            <img
              src={photoUrl}
              alt="student-profile"
              width="100%"
              className="img-size-60 img-thumbnail"
            />
          </div>
          <div className="col-12 col-md-4">
            <ListItem label="User" data={user.userName} />
            <ListItem label="First Name" data={firstName} />
            <ListItem label="Father Name" data={fatherName} />
            <ListItem label="Grand Father Name" data={grandFatherName} />
            <ListItem label="ሙሉ ስም" data={amharicFullName} />            
            <ListItem label="Gender" data={gender} />
            <ListItem label="Marital Status" data={maritalStatus} />
          </div>
          <div className="col-12 col-md-5">
            <ListItem label="Nationality" data={nationality} />
            <ListItem label="Region" data={region?.name} />
            <ListItem label="Disability" data={disability} />
            <ListItem label="Date Of Birth" data={dateOfBirth} />
            <ListItem label="Full Name In Amharic" data={fullNameInAmharic} />
            <ListItem label="place Of Birth" data={placeOfBirth} />
          </div>
        </div>
      </div>
      <div className="card-footer">
        <Link to={`/student_profile/${props.person_id}/edit_person`}>Edit</Link>
      </div>
    </div>
  );
}

export default Person;
