import React from "react";
import { GET_ASESSMENT_RESULT } from "../queries/getAssessmentResult";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";

const AssessmentResult = () => {
  const { loading, data } = useQuery(GET_ASESSMENT_RESULT);
  if (loading) return <p>Loading ...</p>;
  if (_.isEmpty(data.assessmentResult))
    return <div className="alert bold alert-danger">No Assessment taken.</div>;
  return (
    <div>
      {data.assessmentResult.map(
        ({
          instructorName,
          course,
          sumOfMaximumMark,
          sumOfResults,
          studentGrade,
          assessmentResults,
        }) => (
          <div className="border mb-3 mt-3 p-3" key={instructorName}>
            <div className="row">
              <div className="col-4">
                <p>
                  <b>Course Title:</b> {course.courseTitle}
                </p>
                <p>
                  <b>Course Code:</b> {course.courseCode}
                </p>
                <p>
                  <b>Intructor:</b> {instructorName}
                </p>
              </div>
              <div className="col-8 table-responsive">
                <table className="table table-striped ">
                  <thead className="thead-light">
                    <tr>
                      <th>SN</th>
                      <th>Assessment Name</th>
                      <th>Assessment Type</th>
                      <th>Maximum Mark</th>
                      <th>Result</th>
                      <th>Grade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assessmentResults.map(({ id, result, assessment }) => (
                      <tr key={id}>
                        <td></td>
                        <td>{assessment.assessmentName}</td>
                        <td>{assessment.assessmentType}</td>
                        <td>{assessment.maximumMark}</td>
                        <td>{result}</td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th style={{ textAlign: "right" }} colSpan="3">
                        Totals
                      </th>
                      <th align="left">{sumOfMaximumMark}</th>
                      <th align="left">
                        {sumOfResults}/{sumOfMaximumMark}
                      </th>
                      <td align="left" className="bold">
                        {studentGrade ? studentGrade.letterGrade : ""}
                      </td>

                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default AssessmentResult;
