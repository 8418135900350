import React from "react";
import HeaderProfile from "../common/HeaderProfile";
import { useQuery } from "@apollo/react-hooks";
import PAYMENT_SLIP from "../../queries/getPaymentSlip";
import _ from "lodash";
var QRCode = require("qrcode.react");

const PaymentSlip = (props) => {
  const { loading, data } = useQuery(PAYMENT_SLIP, {
    fetchPolicy: "no-cache",
  });
  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-money-check-alt pr-1"></span>Payment Slip
        </h3>
      </div>
      <div className="card-body">
        <HeaderProfile />
        <div className="table-responsive">
          <table className="table  table-bordered text-nowrap">
            <thead>
              <tr>
                <th scope="col">Course Title</th>
                <th scope="col">Course Code</th>
                <th scope="col">Credit Hour</th>
                <th scope="col">Price</th>
              </tr>
            </thead>
            <tbody>
              {data.paymentSlip.courses.map(
                ({ id, course_title, course_code, credit_hour, price }) => (
                  <tr key={id}>
                    <td>{course_title}</td>
                    <td>{course_code}</td>
                    <td>{credit_hour}</td>
                    <td>{price}</td>
                  </tr>
                )
              )}
              <tr>
                <td className="text-bold text-center">Total</td>
                <td></td>
                <td className="text-bold">
                  {_.sum(_.map(data.paymentSlip.courses, "credit_hour"))}
                </td>
                <td className="text-bold">{_.sum(_.map(data.paymentSlip.courses, "price"))}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="text-center mt-3">
          <QRCode value={data.paymentSlip.student_id} />
        </div>
      </div>
    </div>
  );
};

export default PaymentSlip;
