import React, { useState } from "react";
import PAYMENT_STATUS from "../../queries/getPaymentStatus";
import PAYMENT_COURSES from "../../queries/getPaymentCourses";
import { useQuery, useMutation } from "@apollo/react-hooks";
import PaymentCourses from "./PaymentCourses";
import { Form, Formik } from "formik";
import { COURSES_PAYMENT } from "../../mutations/CoursesPayment";
import { sweetAlert } from "./../common/SweetAlert";
import PaymentSlip from "./PaymentSlip";
import swal from "sweetalert";
import _ from "lodash";

function getCourses(courses) {
  courses = _.filter(courses, function (course) {
    return course.course_type?.name !== "Elective";
  });
  return _.map(courses, "course_id");
}
function redirect(props) {
  return props.props.history.push("/registration-slip");
}

const Payment = ({ props, message }) => {
  const { loading, data } = useQuery(PAYMENT_STATUS, {
    fetchPolicy: "no-cache",
  });
  if (loading) return <p>Loading ...</p>;
  if (data.paymentStatus.can_view_payment_form) return <PaymentForm props={props} />;
  if (data.paymentStatus.can_view_payment_slip) return <PaymentSlip />;

  return <div>{JSON.stringify(data.paymentStatus)}</div>;
};

const PaymentForm = (props) => {
  const [elective, setElective] = useState([]);
  const [courses_payment] = useMutation(COURSES_PAYMENT);
  const { loading, data } = useQuery(PAYMENT_COURSES, {
    fetchPolicy: "no-cache",
  });
  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-money-check-alt pr-1"></span>Payment Form
        </h3>
      </div>
      <div className="card-body">
        <div className="alert alert-info" role="alert">
          <p>You are not allowed to register unless you pay the registration fee!!!</p>
        </div>
        <Formik
          initialValues={{
            courses: getCourses(data.paymentCourses),
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            values.courses = [...values.courses, ...elective.map(Number)];

            courses_payment({ variables: values }).then(
              (result) => {
                setSubmitting(false);
                sweetAlert(result.data.coursesPayment);
                if (result.data.coursesPayment.success) {
                  redirect(props);
                }
              },
              (error) => {
                setSubmitting(false);
                swal("Error", error);
              }
            );
          }}
        >
          {({ values, touched, handleSubmit, setFieldValue, isSubmitting }) => (
            <Form>
              <PaymentCourses
                payment_courses={data.paymentCourses}
                setElective={setElective}
                elective={elective}
              />
              <div className="text-center mt-4">
                <input
                  type="submit"
                  disabled={isSubmitting}
                  value={isSubmitting ? "Registering..." : "Continue"}
                  className="btn btn-primary"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Payment;
