import React from "react";
import query from "../queries/getCourseAudit";
import { useQuery } from "@apollo/react-hooks";

function CourseAudit() {
  const { loading, data } = useQuery(query);
  if (loading) return <p>Loading ...</p>;

  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-list pr-1"></span>Course Audit
        </h3>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table  table-bordered text-nowrap">
            <thead className="thead-light">
              <tr>
                <th>Course Code</th>
                <th>Course Title</th>
                <th>ECTS</th>
                <th>Credit Hour</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data.courseAudit.map(
                ({ id, courseCode, courseTitle, ects, creditHour, courseDescription }) => (
                  <tr key={id}>
                    <td>{courseCode}</td>
                    <td>{courseTitle}</td>
                    <td>{ects ? ects : "-"}</td>
                    <td>{creditHour ? creditHour : "-"}</td>
                    <td
                      className={
                        courseDescription === "Taken" ? "bold text-success" : "bold text-danger"
                      }
                    >
                      {courseDescription}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CourseAudit;
