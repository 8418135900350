import React from "react";
import { useQuery } from "@apollo/react-hooks";
import PERMITTED_STATUS from "../../queries/getPermittedStatus";
import UnPermittedLinks from "../../components/layouts/UnPermittedLinks";
import PermittedLinks from "../../components/layouts/PermittedLinks";

const Sidebar = () => {
  const { data } = useQuery(PERMITTED_STATUS, {
    fetchPolicy: "no-cache",
  });
  if (data && data.permittedStatus) return <PermittedLinks />;
  return <UnPermittedLinks />;
};

export default Sidebar;
