import React from "react";
import { STUDENT_CURRICULUM, STUDENT_CURRICULUM_TYPE, STUDENT_MODULAR_CURRICULUM } from "../queries/getCurriculum";
import { useQuery } from "@apollo/react-hooks";

function ModularCurriculum() {
  const { loading, data } = useQuery(STUDENT_MODULAR_CURRICULUM);
  if (loading) return <p>Loading ...</p>;

  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-list pr-1"></span>Curriculum Courses
        </h3>
      </div>
      <div className="card-body">
        {data.studentCurriculum.map(({ id, modularCurriculumCourses, offeredAt }) => (
          <div key={id} className="mb-4">
            <h6 className="text-bold">{offeredAt}</h6>
            <div className="table-responsive">
              <table className="table table-bordered text-nowrap">
                <thead className="thead-light">
                  <tr>
                    <th>Module</th>
                    <th>Course</th>
                    <th>ECTS</th>
                    <th>Lecture Hour</th>
                    <th>Course Prerequisite/s</th>
                  </tr>
                </thead>
                <tbody>
                  {modularCurriculumCourses.map(({ id, moduleCourse }) => (
                    <tr key={id}>
                      <td>{moduleCourse.modular.moduleTitle}({moduleCourse.modular.moduleCode})</td>
                      <td>{moduleCourse.course.courseTitle}({moduleCourse.course.courseCode})</td>
                      <td>{moduleCourse.course.ects ? moduleCourse.course.ects : "-"}</td>
                      <td>{moduleCourse.course.lectureHour ? moduleCourse.course.lectureHour : "-"}</td>
                      <td>
                        <ul>
                          {moduleCourse.moduleCoursePreRequisites.map(({ id, course }) => (
                            <li key={id}>{course.courseTitle}({course.courseCode})</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function ConventionalCurriculum() {
  const { loading, data } = useQuery(STUDENT_CURRICULUM);
  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-list pr-1"></span>Curriculum Courses
        </h3>
      </div>
      <div className="card-body">
        {data.studentCurriculum.map(({ id, curriculumCourses, offeredAt }) => (
          <div key={id} className="mb-4">
            <h6 className="text-bold">{offeredAt}</h6>
            <div className="table-responsive">
              <table className="table table-bordered text-nowrap">
                <thead className="thead-light">
                  <tr>
                    <th>Course Code</th>
                    <th>Course Title</th>
                    <th>ECTS</th>
                    <th>Credit Hour</th>
                    <th>Course Prerequisite/s</th>
                  </tr>
                </thead>
                <tbody>
                  {curriculumCourses.map(({ id, course, coursePreRequisites }) => (
                    <tr key={id}>
                      <td>{course.courseCode}</td>
                      <td>{course.courseTitle}</td>
                      <td>{course.ects ? course.ects : "-"}</td>
                      <td>{course.creditHour ? course.creditHour : "-"}</td>
                      <td>
                        <ul>
                          {coursePreRequisites.map(({ id, course }) => (
                            <li key={id}>{course.courseTitle}({course.courseCode})</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function Curriculum(){
  const { loading, data } = useQuery(STUDENT_CURRICULUM_TYPE);
  if (loading) return <p>Loading ...</p>;

  return (
   data.studentCurriculumType === "Modular" ? (<ModularCurriculum></ModularCurriculum>): (<ConventionalCurriculum></ConventionalCurriculum>)
  );
}

export default Curriculum;
