import React from "react";
import AddDrops from "./enrollment_record/AddDrops";
import Registrations from "./enrollment_record/Registrations";
import Readmissions from "./enrollment_record/Readmissions";
import Transfers from "./enrollment_record/Transfers";
import Withdrawals from "./enrollment_record/Withdrawals";

function EnrollmentRecord() {
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-newspaper pr-1"></span>Enrollment Record
        </h3>
      </div>
      <div className="card-body">
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#registrations">
              Registrations
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#add_drop">
              Add-Drop
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#readmission">
              Readmission
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#transfer">
              Transfer
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#withdrawal">
              Withdrawal
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div id="registrations" className="tab-pane active">
            <Registrations />
          </div>

          <div id="add_drop" className="tab-pane fade">
            <AddDrops />
          </div>

          <div id="readmission" className="tab-pane fade">
            <Readmissions />
          </div>

          <div id="transfer" className="tab-pane fade">
            <Transfers />
          </div>

          <div id="withdrawal" className="tab-pane fade">
            <Withdrawals />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnrollmentRecord;
