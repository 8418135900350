import gql from "graphql-tag";

export const CREATE_GRADE_COMPLAINT_MUTATION = gql`
  mutation createGradeComplaint($studentGradeId: ID!, $reason: String!, $others: String!, $document: Upload) {
    createGradeComplaint(studentGradeId: $studentGradeId, reason: $reason, others: $others, document: $document) {
      id
      reason
      others
      documentUrl
    }
  }
`;

export const EDIT_GRADE_COMPLAINT_MUTATION = gql`
  mutation editGradeComplaint($id: ID!, $reason: String!, $others: String!, $document: Upload) {
    editGradeComplaint(id: $id, reason: $reason, others: $others, document: $document) {
      id
      reason
      others
      documentUrl
    }
  }
`;
