import React, { useState } from "react";
import query from "../../queries/getCourseEnrollments";
import AssessmentResultShow from "./AssessmentResultShow";
import { useQuery } from "@apollo/react-hooks";

function AssessmentResult() {
  const { loading, data } = useQuery(query);
  const [courseEnrollmentId, setCourseEnrollmentId] = useState(0);

  if (loading) return <p>Loading ...</p>;

  const handleChange = (e) => {
    if (e.target.value !== "null") {
      setCourseEnrollmentId(e.target.value);
    }
  };

  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <p className="text-info">
          <strong>
            * Choose Course to view assessment result for that course
          </strong>
        </p>
        <select
          name="course_enrollment_id"
          id="course_enrollment_id"
          onChange={handleChange}
          className="form-control input-sm mb-2"
        >
          <option value="null">Please Select...</option>
          {data.studentCourseEnrollments.map(({ id, course }) => (
            <option key={id} value={id}>
              {course.titleAndCode}
            </option>
          ))}
        </select>

        {courseEnrollmentId !== 0 ? (
          <AssessmentResultShow course_enrollment_id={courseEnrollmentId} />
        ) : (
          <p>Select Course Enrollment</p>
        )}
      </div>
    </div>
  );
}

export default AssessmentResult;
