import React from "react";
import { Field, ErrorMessage } from "formik";

function WField(props) {
  return (
    <div className="form-group">
      <label htmlFor={props.wf_name} className="">
        {props.wf_label}
      </label>
      <Field
        id={props.wf_name}
        className="form-control"
        name={props.wf_name}
        type={props.wf_type}
        valeu={props.wf_value}
      />
      <ErrorMessage name={props.wf_name} component="div" className="text-danger" />
    </div>
  );
}

export default WField;
