import gql from "graphql-tag";

export const UPDATE_BANK_ACCOUNT_MUTATION = gql`
  mutation updateBankAccount($bankAccount: String!) {
    updateBankAccount(bankAccount: $bankAccount) {
      id
      idNumber
      bankAccount
    }
  }
`;
