import React, { useState } from "react";
import { ADD_DROP_STATUS } from "../../queries/getAddDropStatus";
import { useQuery } from "@apollo/react-hooks";
import AddCourse from "./AddCourse";
import DropCourse from "./DropCourse";
import AddFromAll from "./AddFromAll";
import AddDropList from "./AddDropList";
import ErrorMessage from "../common/ErrorMessage";

const AddDrop = (props) => {
  const { loading, data } = useQuery(ADD_DROP_STATUS, {
    fetchPolicy: "no-cache",
  });
  const [updateList, setUpdateList] = useState(false);
  const [addCourse, setAddCourse] = useState(0);
  const [dropCourse, setDropCourse] = useState(0);
  const [addFromAllCourse, setAddFromAllCourse] = useState(0);

  function handleClick(data) {
    if (data === "addCourse") {
      setAddCourse(1);
      setDropCourse(0);
      setAddFromAllCourse(0);
    }
    if (data === "dropCourse") {
      setDropCourse(1);
      setAddCourse(0);
      setAddFromAllCourse(0);
    }
    if (data === "addFromAllCourse") {
      setAddFromAllCourse(1);
      setAddCourse(0);
      setDropCourse(0);
    }
  }
  const reset = () => {
    setAddFromAllCourse(0);
    setAddCourse(0);
    setDropCourse(0);
    setUpdateList(true);
    setUpdateList(false);
  };

  if (loading) return <p>Loading ...</p>;
  if (data && data.addDropStatus.error) return <ErrorMessage message={data.addDropStatus.error} />;

  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-exchange-alt pr-1"></span>Course Add Drop
        </h3>
      </div>
      <div className="card-body">
        <div className="text-center">
          <p>
            <strong>To add courses from Your curriculum</strong>
          </p>
          <button className="btn btn-link" onClick={(e) => handleClick("addCourse")}>
            Add Course
          </button>
          |
          <button className="btn btn-link" onClick={(e) => handleClick("dropCourse")}>
            Drop Course
          </button>
          <p>
            <strong>To Add courses from all curriculum</strong>
          </p>
          <button className="btn btn-link" onClick={(e) => handleClick("addFromAllCourse")}>
            Add from all course
          </button>
        </div>
        {addCourse === 1 ? (
          <AddCourse reset={reset} props={props} />
        ) : dropCourse === 1 ? (
          <DropCourse reset={reset} props={props} />
        ) : addFromAllCourse === 1 ? (
          <AddFromAll reset={reset} props={props} />
        ) : (
          ""
        )}
        <hr />
        <AddDropList updateList={updateList} />
      </div>
    </div>
  );
};

export default AddDrop;
