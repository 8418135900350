import swal from "sweetalert";

const sweetAlert = function (message) {
  if (message.success) {
    swal({
      title: "Success",
      text: message.success,
      icon: "success",
      button: "Ok",
    });
  }
  if (message.error) {
    swal({
      title: "Error",
      text: message.error,
      icon: "error",
      button: "Ok",
    });
  }
  if (message.warning) {
    swal({
      title: message.warning,
      text: "",
      icon: "warning",
      button: "Ok",
    });
  }
};

export { sweetAlert };
