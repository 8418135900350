import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik, Form } from "formik";
import WField from "../layouts/WField";
import WFormErrors from "../layouts/WFormErrors";
import { EducationValidator } from "../validations/FormValidation";
import { GET_EDUCATION } from "../../queries/find/byId";
import { EDIT_EDUCATION_MUTATION } from "../../mutations/education";
import swal from "sweetalert";

function EditEducation(props) {
  const { loading, data } = useQuery(GET_EDUCATION, {
    variables: props.match.params,
  });
  const [updateEducation] = useMutation(EDIT_EDUCATION_MUTATION);
  const [updateErrors, setUpdateErrors] = useState({});

  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-edit pr-1"></span>Edit Education
        </h3>
      </div>
      <Formik
        initialValues={{
          id: data.getEducation.id,
          institution: data.getEducation.institution || "",
          studyField: data.getEducation.studyField || "",
          awardType: data.getEducation.awardType || "",
          qualification: data.getEducation.qualification || "",
          from: data.getEducation.from || "",
          to: data.getEducation.to || "",
          grade: data.getEducation.grade || 0.0,
          scale: data.getEducation.scale || 0.0,
        }}
        validationSchema={EducationValidator}
        onSubmit={(values, { setSubmitting }) => {
          updateEducation({ variables: values }).then(
            (result) => {
              setSubmitting(false);
              swal("Update Education", "Education Updated Successfully!");
              props.history.push("/student_profile");
            },
            (error) => {
              setSubmitting(false);
              setUpdateErrors(error.graphQLErrors[0].extensions.validation);
            }
          );
        }}
      >
        {({ isSubmitting }) => (
          <div className="card-body row">
            <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
              <WFormErrors errors={updateErrors} />
              <Form>
                <WField wf_type="text" wf_name="institution" wf_label="Institution" />
                <WField wf_type="text" wf_name="studyField" wf_label="Study Field" />
                <WField wf_type="text" wf_name="awardType" wf_label="Award Type" />
                <WField wf_type="text" wf_name="qualification" wf_label="Qualification" />
                <WField wf_type="date" wf_name="from" wf_label="From" />
                <WField wf_type="date" wf_name="to" wf_label="To" />
                <WField wf_type="number" wf_name="grade" wf_label="Grade" />
                <WField wf_type="number" wf_name="scale" wf_label="Scale" />

                <div className="form-group">
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary ">
                    Update
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default EditEducation;
