import gql from "graphql-tag";

export const PENDING_COURSE_EXEMPTIONS = gql`
  {
    pendingCourseExemptions {
      id
      instituteName
      documentUrl
      academicYear {
        year
      }
      exemptionEquivalentAcademicSemesters {
        id
        academicYearSemester {
          semesterName
        }
      }
      courseExemptionDetails {
        id
        course {
          courseCode
          courseTitle
          titleAndCode
        }
        equivalentCourseName
        equivalentCourseCreditHour
        equivalentCourseEcts
        gradeOrMark
        approval
      }
    }
  }
`;
