import React from "react";
import query from "../../queries/getWithdrawals";
import _ from "lodash";
import { useQuery } from "@apollo/react-hooks";

function Withdrawals() {
  const { loading, data } = useQuery(query);
  if (loading) return <p>Loading ...</p>;
  if (_.isEmpty(data.withdrawals))
    return <p className="error-info">No Withdrawal History</p>;
  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Reason</th>
                <th>Additional information</th>
                <th>Hod recommendation</th>
                <th>Status</th>
                <th>Requested date</th>
              </tr>
            </thead>
            <tbody>
              {data.withdrawals.map(
                ({
                  id,
                  reason,
                  additionalInformation,
                  hodRecommendation,
                  status,
                  requestedDate,
                }) => (
                  <tr key={id}>
                    <td>{reason}</td>
                    <td>{additionalInformation}</td>
                    <td>{hodRecommendation}</td>
                    <td>{status}</td>
                    <td>{requestedDate}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Withdrawals;
