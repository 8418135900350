import React from "react";
import Person from "./student_profile/person";
import Applicant from "./student_profile/applicant";
import ContactAddress from "./student_profile/contactAddress";
import EmergencyContact from "./student_profile/emergencyContact";
import FamilyBackgrounds from "./student_profile/familyBackgrounds";
import Educations from "./student_profile/educations";
import Experiences from "./student_profile/experiences";
import DepartmentPreferences from "./student_profile/departmentPreferences";

import query from "../queries/getPerson";
import { useQuery } from "@apollo/react-hooks";
import {
  GET_PERSON,
  GET_APPLICANT,
  GET_CONTACT_ADDRESS,
  GET_EDUCATIONS,
  GET_EMERGENCY_CONTACT,
  GET_EXPERIENCES,
  GET_FAMILY_BACKGROUNDS,
  GET_DEPARTMENT_PREFERENCES,
} from "../queries/find/byId";

function StudentProfile() {
  const { loading, data } = useQuery(query);
  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <i className="fas fa-user mr-1"></i> Student Profile
        </h3>
      </div>
      <div className="card-body">
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" data-toggle="pill" href="#personal">
              Personal
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="pill" href="#applicant">
              Applicant
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="pill" href="#contact_address">
              Contact Address
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="pill" href="#emergency_contact">
              Emergency Contact
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="pill" href="#family_background">
              Family Background
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="pill" href="#education">
              Education
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="pill" href="#experience">
              Experience
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="pill" href="#program_preference">
              Program Preference
            </a>
          </li>
        </ul>

        <div className="tab-content pt-1">
          <div id="personal" className="tab-pane active">
            <Person query={GET_PERSON} person_id={data.person.id} />
          </div>

          <div id="applicant" className="tab-pane fade">
            <Applicant query={GET_APPLICANT} person_id={data.person.id} />
          </div>

          <div id="contact_address" className="tab-pane fade">
            <ContactAddress query={GET_CONTACT_ADDRESS} person_id={data.person.id} />
          </div>

          <div id="emergency_contact" className="tab-pane fade">
            <EmergencyContact query={GET_EMERGENCY_CONTACT} person_id={data.person.id} />
          </div>

          <div id="family_background" className="tab-pane fade">
            <FamilyBackgrounds query={GET_FAMILY_BACKGROUNDS} person_id={data.person.id} />
          </div>

          <div id="education" className="tab-pane fade">
            <Educations query={GET_EDUCATIONS} person_id={data.person.id} />
          </div>
          <div id="experience" className="tab-pane fade">
            <Experiences query={GET_EXPERIENCES} person_id={data.person.id} />
          </div>

          <div id="program_preference" className="tab-pane fade">
            <DepartmentPreferences query={GET_DEPARTMENT_PREFERENCES} person_id={data.person.id} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentProfile;
