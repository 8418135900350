import React from "react";
import query from "../../queries/getReadmissions";
import _ from "lodash";
import { useQuery } from "@apollo/react-hooks";

function Readmissions() {
  const { loading, data } = useQuery(query);
  if (loading) return <p>Loading ...</p>;
  if (_.isEmpty(data.readmissions))
    return <p className="error-info">No Readmission History</p>;

  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Occupation</th>
                <th>Leaving date</th>
                <th>Leaving Semester</th>
                <th>Leaving academic year</th>
                <th>Reason for leaving</th>
                <th>Academic year semester</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data.readmissions.map(
                ({
                  id,
                  occupation,
                  leavingDate,
                  reasonForLeaving,
                  appStatus,
                  registration,
                  semester,
                  academicYear,
                  academicYearSemester,
                }) => (
                  <tr key={id}>
                    <td>{registration.student.applicant.person.fullName}</td>
                    <td>{occupation}</td>
                    <td>{leavingDate}</td>
                    <td>{semester.name}</td>
                    <td>{academicYear.name}</td>
                    <td>{reasonForLeaving}</td>
                    <td>{academicYearSemester.semesterName}</td>
                    <td>{appStatus}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Readmissions;
