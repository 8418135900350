import React, { useState } from "react";
import query from "../../queries/getAYS";
import GradeReportShow from "./GradeReportShow";
import { useQuery } from "@apollo/react-hooks";

function GradeReport() {
  const { loading, data } = useQuery(query);
  const [aysId, setAysId] = useState(0);

  if (loading) return <p>Loading ...</p>;

  const handleChange = (e) => {
    if (e.target.value !== "null") {
      setAysId(e.target.value);
    }
  };

  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <p className="text-info">
          * <strong>This is not official grade report.</strong>
        </p>
        <select
          name="ays_id"
          id="ays_id"
          onChange={handleChange}
          className="form-control input-sm mb-2"
        >
          <option value="null">Please Select...</option>
          {data.studentAcademicYearSemesters.map(({ id, semesterName }) => (
            <option key={id} value={id}>
              {semesterName}
            </option>
          ))}
        </select>

        {aysId !== 0 ? (
          <GradeReportShow ays_id={aysId} />
        ) : (
          <p>Select Academic Year Semester</p>
        )}
      </div>
    </div>
  );
}

export default GradeReport;
