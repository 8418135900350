import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import ListItem from "../layouts/ListItem";

function Applicant(props) {
  const { loading, data } = useQuery(props.query, {
    variables: { personId: props.person_id },
  });
  if (loading) return <p>Loading ...</p>;
  if (!data.getApplicant)
    return <p className="text-danger">No Applicant Info.</p>;
  const {
    student,
    structure,
    admission,
    classYear,
    program,
    academicYear,
    registrationNumber,
    matriculationResult,
    tuitionType,
  } = data.getApplicant;
  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <ListItem label="Id Number" data={student.idNumber} />
        <ListItem label="School" data={structure.name} />
        <ListItem label="Admission" data={admission.fullName} />
        <ListItem label="Class Year" data={classYear.name} />
        <ListItem label="Program" data={program.fullName} />
        <ListItem label="Academic Year" data={academicYear.year} />
        <ListItem label="Registration Number" data={registrationNumber} />
        <ListItem label="Matriculation Result" data={matriculationResult} />
        <ListItem label="Tuition Type" data={tuitionType} />
      </div>
      <div className="card-footer">
        <Link to={`/student_profile/${props.person_id}/edit_applicant`}>
          Edit
        </Link>
      </div>
    </div>
  );
}

export default Applicant;
