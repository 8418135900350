import gql from "graphql-tag";

export const DROP_COURSES = gql`
  {
    dropCourses {
      id
      courseCode
      courseTitle
      ects
      creditHour
    }
  }
`;
