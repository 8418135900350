import gql from "graphql-tag";

export default gql`
  {
    gpaStatus {
      id
      status
      semesterGpa
      cumulativeGpa
    }
  }
`;
