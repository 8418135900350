import React, { useState } from "react";
import Transcript from "./Transcript";
import GpaStatus from "./academic_history/GpaStatus";
import GradeReport from "./academic_history/GradeReport";
import AssessmentResult from "./academic_history/AssessmentResult";
import Attendance from "./academic_history/Attendance";
import CostSharing from "./academic_history/CostSharing";

const AcademicHistory = () => {
  const [showCostSharing, setShowCostSharing] = useState(false);
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-history pr-1"></span>Academic History
        </h3>
      </div>
      <div className="card-body">
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#transcript">
              Transcript
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#gpa_summary">
              GPA summary
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#grade_report">
              Grade report
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#assessment_result">
              Assessment result
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#attendance">
              Attendance
            </a>
          </li>
          {showCostSharing ? (
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#cost_sharing_history">
                Cost sharing history
              </a>
            </li>
          ) : (
            ""
          )}
        </ul>

        <div className="tab-content">
          <div id="transcript" className="tab-pane active">
            <Transcript />
          </div>

          <div id="gpa_summary" className="tab-pane fade">
            <GpaStatus />
          </div>

          <div id="grade_report" className="tab-pane fade">
            <GradeReport />
          </div>

          <div id="assessment_result" className="tab-pane fade">
            <AssessmentResult />
          </div>

          <div id="attendance" className="tab-pane fade">
            <Attendance />
          </div>

          <div id="cost_sharing_history" className="tab-pane fade">
            <CostSharing show={setShowCostSharing} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcademicHistory;
