import React, { useState } from "react";
import { ADD_COURSES } from "../../queries/getAddCourses";
import { ADD_COURSE } from "../../mutations/AddCourse";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Select from "react-select";
import LoadInstructor from "./LoadInstructor";
import { courseAssignmentValue } from "./LoadInstructor";
import { sectionValue } from "./LoadSections";
import { Form, Formik } from "formik";
import { sweetAlert } from "../common/SweetAlert";
import swal from "sweetalert";
import _ from "lodash";

const AddCourse = (props) => {
  const [offeredCourse, setOfferedCourse] = useState(0);
  const { loading, data } = useQuery(ADD_COURSES);
  const [addCourse] = useMutation(ADD_COURSE);
  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card">
      <div className="card-header bg-info">Add Course</div>
      <div className="card-body">
        <div className="row">
          <div className="col-8 offset-2">
            <Formik
              initialValues={{
                courseOffering: null,
                courseAssignment: null,
                section: null,
                requestFor: "add",
              }}
              onSubmit={(values, { resetForm, setSubmitting }) => {
                values["courseAssignment"] = courseAssignmentValue();
                values["section"] = sectionValue();
                if (!values["courseOffering"]) {
                  setSubmitting(false);
                  return sweetAlert({ error: "Course can't be empty." });
                }
                if (!values["courseAssignment"]) {
                  setSubmitting(false);
                  return sweetAlert({
                    error: "Instructor name can't be empty.",
                  });
                }
                if (!values["section"]) {
                  setSubmitting(false);
                  return sweetAlert({ error: "Section can't be empty." });
                }
                addCourse({ variables: values }).then(
                  (result) => {
                    setSubmitting(false);
                    sweetAlert(result.data.addCourse);
                    if (result.data.addCourse.success) {
                      props.reset();
                      return props.props.history.push("/add-drop");
                    }
                    resetForm();
                    props.reset();
                  },
                  (error) => {
                    setSubmitting(false);
                    swal("Error", error);
                  }
                );
              }}
            >
              {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
                <Form>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Courses</label>
                    <div className="col-sm-9">
                      <Select
                        name="courseOffering"
                        options={groupBy(data)}
                        onChange={(e) => {
                          setFieldValue("courseOffering", e.value, false);
                          setOfferedCourse(e.value);
                        }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                  </div>
                  {offeredCourse !== 0 ? (
                    <LoadInstructor offeredCourse={offeredCourse} />
                  ) : (
                    ""
                  )}
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary "
                      disabled={isSubmitting}
                    >
                      Submit Request
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

function groupBy(data) {
  return _.chain(data.addCourses)
    .map(function (v, i) {
      return {
        value: v["id"],
        label:
          v["course"]["courseTitle"] + " (" + v["course"]["courseCode"] + ")",
      };
    })
    .value();
}

export default AddCourse;
