import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik, Form } from "formik";
import WField from "../layouts/WField";
import WFselect from "../layouts/WFselect";
import WFormErrors from "../layouts/WFormErrors";
import { ApplicantValidator } from "../validations/FormValidation";
import { GET_APPLICANT } from "../../queries/find/byId";
import { EDIT_APPLICANT_MUTATION } from "../../mutations/applicant";
import swal from "sweetalert";
import {
  TUITION_TYPE_QUERY,
  ALL_ADMISSION_QUERY,
  ALL_CLASS_YEAR_QUERY,
  ALL_PROGRAM_QUERY,
  ALL_ACADEMIC_YEAR_QUERY,
} from "../../queries/constants";

function EditApplicant(props) {
  const { loading, data } = useQuery(GET_APPLICANT, {
    variables: props.match.params,
  });
  const [updateApplicant] = useMutation(EDIT_APPLICANT_MUTATION);
  const [updateErrors, setUpdateErrors] = useState({});

  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-edit pr-1"></span>Edit Applicant
        </h3>
      </div>
      <Formik
        initialValues={{
          id: data.getApplicant.id,
          admissionId: data.getApplicant.admission.id,
          classYearId: data.getApplicant.classYear.id,
          programId: data.getApplicant.program.id,
          academicYearId: data.getApplicant.academicYear.id,
          tuitionType: data.getApplicant.tuitionType || "",
          fullNameInAmharic: data.getApplicant.fullNameInAmharic || "",
          registrationNumber: data.getApplicant.registrationNumber || "",
          matriculationResult: parseInt(data.getApplicant.matriculationResult) || 0,
        }}
        validationSchema={ApplicantValidator}
        onSubmit={(values, { setSubmitting }) => {
          updateApplicant({ variables: values }).then(
            (result) => {
              setSubmitting(false);
              swal("Update Applicant", "Applicant Updated Successfully!");
              props.history.push("/student_profile");
            },
            (error) => {
              setSubmitting(false);
              setUpdateErrors([error.graphQLErrors[0].message]);
              // setUpdateErrors(error.graphQLErrors[0].extensions.validation);
            }
          );
        }}
      >
        {({ isSubmitting }) => (
          <div className="card-body row">
            <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
              <WFormErrors errors={updateErrors} />
              <Form>
                <WFselect
                  ws_name="admissionId"
                  ws_query={ALL_ADMISSION_QUERY}
                  ws_label="Admission"
                  hash={true}
                />
                <WFselect
                  ws_name="classYearId"
                  ws_query={ALL_CLASS_YEAR_QUERY}
                  ws_label="Class Year"
                  hash={true}
                />
                <WFselect
                  ws_name="programId"
                  ws_query={ALL_PROGRAM_QUERY}
                  ws_label="Program"
                  hash={true}
                />
                <WFselect
                  ws_name="academicYearId"
                  ws_query={ALL_ACADEMIC_YEAR_QUERY}
                  ws_label="Academic Year"
                  hash={true}
                />
                <WFselect
                  ws_name="tuitionType"
                  ws_query={TUITION_TYPE_QUERY}
                  ws_label="Tuition Type"
                />
                <WField wf_type="text" wf_name="fullNameInAmharic" wf_label="FullName In Amharic" />
                <WField
                  wf_type="text"
                  wf_name="registrationNumber"
                  wf_label="Registration Number"
                />
                <WField
                  wf_type="number"
                  wf_name="matriculationResult"
                  wf_label="Matriculation Result"
                />
                <div className="form-group">
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary ">
                    Update
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default EditApplicant;
