import React from "react";
import _ from "lodash";

function WFormErrors({ errors }) {
  if (_.isEmpty(errors)) {
    return <p></p>;
  } else {
    return (
      <div className="alert alert-danger alert-dismissible">
        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">
          &times;
        </button>
        <h5>
          <i className="icon fas fa-ban"></i> <strong>Validation Error!</strong>
        </h5>

        <ul>
          {errors.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      </div>
    );
  }
}

export default WFormErrors;
