import gql from "graphql-tag";

export const EDIT_CONTACT_ADDRESS_MUTATION = gql`
  mutation editContactAddress(
    $id: ID!
    $country: String!
    $streetAddress: String!
    $homeTelephone: String!
    $mobile: String!
    $workTelephone: String!
    $email: String!
    $zoneId: ID!
    $woreda: String!
    $kebele: String!
  ) {
    editContactAddress(
      id: $id
      country: $country
      streetAddress: $streetAddress
      homeTelephone: $homeTelephone
      mobile: $mobile
      workTelephone: $workTelephone
      email: $email
      zoneId: $zoneId
      woreda: $woreda
      kebele: $kebele
    ) {
      id
      country
      streetAddress
      homeTelephone
      mobile
      workTelephone
      email
      zone{
        id
        name
      }
      woreda
      kebele
    }
  }
`;

export const CREATE_CONTACT_ADDRESS_MUTATION = gql`
  mutation createContactAddress(
    $personId: ID!
    $country: String!
    $streetAddress: String!
    $homeTelephone: String!
    $mobile: String!
    $workTelephone: String!
    $email: String!
    $zoneId: ID!
    $woreda: String!
    $kebele: String!
  ) {
    createContactAddress(
      personId: $personId
      country: $country
      streetAddress: $streetAddress
      homeTelephone: $homeTelephone
      mobile: $mobile
      workTelephone: $workTelephone
      email: $email
      zoneId: $zoneId
      woreda: $woreda
      kebele: $kebele
    ) {
      id
      country
      streetAddress
      homeTelephone
      mobile
      workTelephone
      email
      zone{
        id
        name
      }
      woreda
      kebele
    }
  }
`;
