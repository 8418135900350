import gql from "graphql-tag";

export const MY_DORM = gql`
  {
    myDorm {
      id
      bed {
        name
        room {
          name
          block {
            name
          }
        }
      }
    }
  }
`;
