import gql from "graphql-tag";

export default gql`
  {
    courseAudit {
      id
      courseTitle
      courseCode
      ects
      creditHour
      courseDescription
    }
  }
`;
