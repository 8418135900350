import React from "react";

const IncompleteList = (props) => {
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-list pr-1"></span>Incomplete List
        </h3>
      </div>
      <div className="card-body">
        <p className="text-strong">You must fill the following forms before proceeding.</p>
        <ul>
          {props.data.map((field) => (
            <li className="text-danger" key={field}>
              {field}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default IncompleteList;
