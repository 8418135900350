import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import FooterAuth from "../layouts/FooterAuth";

const Login = (props) => {
  document.getElementById("body").classList.remove("sidebar-mini");
  document.getElementById("body").classList.add("login-page");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [authStatus, setAuthStatus] = useState(false);

  const submit = (event) => {
    setBtnDisabled(true);
    event.preventDefault();
    const signinData = new FormData();
    signinData.append("user_name", username);
    signinData.append("password", password);

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/sign_in`, signinData)
      .then((res) => {
        localStorage.setItem("access-token", res.headers["access-token"]);
        localStorage.setItem("client", res.headers["client"]);
        localStorage.setItem("expiry", res.headers["expiry"]);
        localStorage.setItem("token-type", res.headers["token-type"]);
        localStorage.setItem("uid", res.headers["uid"]);
        window.location.reload();
        props.setToken(res.headers["access-token"]);
      })
      .catch((error) => {
        if (401 === error.response.status) {
          if (error.response.data.errors[0] === "Invalid login credentials. Please try again.") {
            setAuthStatus(error.response.data.errors[0]);
          } else {
            setAuthStatus("Your account has been disabled please contact the registrar office.");
          }
        } else {
          // console.log(error.response.status);
        }
        setBtnDisabled(false);
      });
  };

  return (
    <div className="login-box text-center">
      <div className="login-logo">
        <a href="https://estudent.astu.edu.et/portal">
          <img
            src="/dist/img/astu_logo.svg"
            alt="AdminLTE Logo"
            className="img-circle"
            width="20%"
          />
          <br />
          <b>ASTU</b> E-Student
        </a>
      </div>
      <div className="card mb-4">
        <div className="card-body login-card-body">
          <p className="login-box-msg">To Apply please use the following link <a href="/portal">New Student Application</a></p>
          <br />
          <p className="login-box-msg">For Student Only</p>
          {authStatus && (
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
              {authStatus}
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => setAuthStatus(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
          <form onSubmit={submit}>
            <div className="input-group mb-3">
              <input
                id="user_name"
                name="user_name"
                type="text"
                value={username}
                onChange={({ target }) => setUsername(target.value)}
                className="form-control"
                placeholder="Username"
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope"></span>
                </div>
              </div>
            </div>

            <div className="input-group mb-3">
              <input
                className="form-control"
                placeholder="Password"
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={({ target }) => setPassword(target.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock"></span>
                </div>
              </div>
            </div>

            <div className="form-group text-left">
              <button type="submit" className="btn btn-primary" disabled={btnDisabled}>
                <i className="fas fa-sign-in-alt mr-2"></i>
                {btnDisabled ? "Signing you in..." : "Sign in"}
              </button>
            </div>
          </form>
          <p className="mt-4 text-left">
            <Link to="/forgot_password">Forgot Password ?</Link>
          </p>
        </div>
      </div>
      <div className="nonfooter mt-4">
        <FooterAuth />
      </div>
    </div>
  );
};

export default Login;
