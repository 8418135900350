import React from "react";

function PaymentCourses(props) {
  const handleSelection = (e) => {
    if (props.elective.indexOf(e.target.value) === -1) {
      props.setElective([...props.elective, e.target.value]);
    } else {
      const index = props.elective.indexOf(e.target.value);

      props.setElective(props.elective.filter((_, i) => i !== index));
    }
  };

  return (
    <div className="table-responsive">
      <table className="table  table-bordered text-nowrap">
        <thead>
          <tr>
            <th scope="col">Select</th>

            <th scope="col">Course Type</th>
            <th scope="col">Course Title</th>
            <th scope="col">Course Code</th>
            <th scope="col">Credit Hour</th>
            <th scope="col">Price</th>
          </tr>
        </thead>
        <tbody>
          {props.payment_courses.map(
            ({ id, course_type, course_title, course_code, credit_hour, course_id, price }) => (
              <tr key={id}>
                <td className="text-center">
                  {course_type?.name === "Elective" ? (
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`elective${course_id}`}
                        value={course_id}
                        onClick={handleSelection}
                      />
                      <label className="form-check-label" htmlFor={`elective${course_id}`}></label>
                    </div>
                  ) : (
                    ""
                  )}
                </td>
                <td>{course_type?.name}</td>
                <td>{course_title}</td>
                <td>{course_code}</td>
                <td>{credit_hour}</td>
                <td>{price}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
}

export default PaymentCourses;
