import gql from "graphql-tag";

export const REGION_QUERY = gql`
  {
    region {
      id
      name
    }
  }
`;
export const ZONES_QUERY = gql`
  query zones($regionId: ID!){
    zones(regionId: $regionId){
      id
      name
    }
  }
`;
export const MARITAL_STATUS_QUERY = gql`
  {
    maritalStatus
  }
`;
export const NATIONALITY_QUERY = gql`
  {
    nationality
  }
`;
export const DISABILITY_QUERY = gql`
  {
    disability
  }
`;

export const GRADE_COMPLAINTS_QUERY = gql`
  {
    gradeComplaints
  }
`;

export const TUITION_TYPE_QUERY = gql`
  {
    tuitionType
  }
`;

export const ALL_ADMISSION_QUERY = gql`
  {
    allAdmission {
      id
      name
    }
  }
`;
export const ALL_CLASS_YEAR_QUERY = gql`
  {
    allClassYear {
      id
      name
    }
  }
`;
export const ALL_PROGRAM_QUERY = gql`
  {
    allProgram {
      id
      name
    }
  }
`;
export const ALL_ACADEMIC_YEAR_QUERY = gql`
  {
    allAcademicYear {
      id
      year
    }
  }
`;
