import React from "react";

function ListItem({ label, data }) {
  return (
    <p>
      <strong className="label-colen">{label}: </strong>
      {data || ""}
    </p>
  );
}

export default ListItem;
