import { useMutation, useQuery } from "@apollo/react-hooks";
import React from "react";
import { Link } from "react-router-dom";
import { DELETE_FAMILY_BACKGROUND_MUTATION } from "../../mutations/delete/byId";

function FamilyBackgrounds(props) {
  const { loading, data } = useQuery(props.query, {
    variables: { personId: props.person_id },
  });
  const [deleteFamilyBackground] = useMutation(
    DELETE_FAMILY_BACKGROUND_MUTATION,
    {
      update(cache, { data: { deleteFamilyBackground } }) {
        const { getFamilyBackgrounds } = cache.readQuery({
          query: props.query,
          variables: { personId: props.person_id },
        });
        cache.writeQuery({
          query: props.query,
          variables: { personId: props.person_id },
          data: {
            getFamilyBackgrounds: getFamilyBackgrounds.filter(
              (education) => education.id !== deleteFamilyBackground.id
            ),
          },
        });
      },
    }
  );

  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table  table-striped table-bordered">
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Relation</th>
                <th>Date Of Birth</th>
                <th>Education Level</th>
                <th>Occupation</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.getFamilyBackgrounds.map(
                ({
                  id,
                  fullName,
                  relation,
                  dateOfBirth,
                  educationLevel,
                  occupation,
                }) => (
                  <tr key={id}>
                    <td>{fullName}</td>
                    <td>{relation}</td>
                    <td>{dateOfBirth}</td>
                    <td>{educationLevel}</td>
                    <td>{occupation}</td>
                    <td>
                      <Link
                        to={`/student_profile/${id}/edit_family_background`}
                      >
                        Edit
                      </Link>
                      {" | "}
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          if (
                            window.confirm("Are you sure you wish to delete?")
                          )
                            deleteFamilyBackground({ variables: { id: id } });
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer">
        <Link
          to={`/student_profile/${props.person_id}/create_family_background`}
        >
          New
        </Link>
      </div>
    </div>
  );
}

export default FamilyBackgrounds;
