import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik, Form, Field } from "formik";
import WField from "../layouts/WField";
import WFselect from "../layouts/WFselect";
import WFormErrors from "../layouts/WFormErrors";
import { GradeComplaintValidator } from "../validations/FormValidation";
import { EDIT_GRADE_COMPLAINT_MUTATION } from "../../mutations/gradeComplaint";
import { GRADE_COMPLAINTS_QUERY } from "../../queries/constants";
import { GET_GRADE_COMPLAINT } from "../../queries/find/byId";
import FileUpload from "../helpers/FileUpload";
import swal from "sweetalert";

function EditGradeComplaint(props) {
  const { loading, data } = useQuery(GET_GRADE_COMPLAINT, {
    variables: props.match.params,
  });
  const [editGradeComplaint] = useMutation(EDIT_GRADE_COMPLAINT_MUTATION);
  const [updateErrors, setUpdateErrors] = useState({});

  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-edit pr-1"></span>Edit Grade Complaint
        </h3>
      </div>
      <Formik
        initialValues={{
          id: data.getGradeComplaint.id,
          reason: data.getGradeComplaint.reason || "",
          others: data.getGradeComplaint.others || "",
        }}
        validationSchema={GradeComplaintValidator}
        onSubmit={(values, { setSubmitting }) => {
          editGradeComplaint({ variables: values }).then(
            (result) => {
              setSubmitting(false);
              swal("Update  Grade Complaint", "Grade Complaint Updated Successfully!");
              props.history.push("/academic_history");
            },
            (error) => {
              setSubmitting(false);
              setUpdateErrors(error.graphQLErrors[0].extensions.validation);
            }
          );
        }}
      >
        {({ isSubmitting }) => (
          <div className="card-body row">
            <div className="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">
              <WFormErrors errors={updateErrors} />
              <Form>
                <WFselect ws_name="reason" ws_query={GRADE_COMPLAINTS_QUERY} ws_label="Reason" />
                <WField wf_type="others" wf_name="others" wf_label="Description" />
                <div className="form-group">
                    <label>{`Document(image or pdf < 5MB)`}</label>
                    <a
                      href={`${process.env.REACT_APP_API_URL}${data.getGradeComplaint.documentUrl}`}
                      className="btn btn-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Previous Document
                    </a>
                    <Field name="document" component={FileUpload} />
                </div>
                <div className="form-group row">
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary ">
                    Update
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default EditGradeComplaint;
