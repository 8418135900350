import React from "react";
import { useQuery } from "@apollo/react-hooks";
import query from "../../queries/getCostSharing";
import _ from "lodash";

function total_amount(data) {
  var total = _.reduce(
    data,
    function (memoizer, value) {
      return memoizer + value.costSharing.serviceTotal;
    },
    0
  );
  return _.round(total, 2);
}

function total_per_service(data, index) {
  var total = _.reduce(
    data,
    function (memoizer, value) {
      return memoizer + value.costSharing.services[index]["amount"];
    },
    0
  );
  return total;
}

const CostSharing = (props) => {
  const { loading, data } = useQuery(query);
  if (loading) return <p>Loading ...</p>;
  if (data.costSharingByAys) {
    props.show(true);
  }
  if (_.isEmpty(data.costSharingByAys)) {
    if (data.costSharingByAys) {
      props.show(false);
    }
    return null;
  }

  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table  table-striped table-bordered">
            <thead>
              <tr>
                <th>Academic Semester</th>
                <th>Education(In Birr)</th>
                <th>Cafe(In Birr)</th>
                <th>Accomodation(In Birr)</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {data.costSharingByAys.map(
                ({ id, semesterName, costSharing }) => (
                  <tr key={id}>
                    <td>{semesterName}</td>
                    <td>{costSharing.services[0]["amount"]}</td>
                    <td>{costSharing.services[1]["amount"]}</td>
                    <td>{costSharing.services[2]["amount"]}</td>
                    <td>
                      <u>{costSharing.serviceTotal}</u>
                    </td>
                  </tr>
                )
              )}
            </tbody>
            <tfoot bgcolor="silver">
              <tr>
                <th>Total</th>
                <th>{total_per_service(data.costSharingByAys, 0)}</th>
                <th>{total_per_service(data.costSharingByAys, 1)}</th>
                <th>{total_per_service(data.costSharingByAys, 2)}</th>
                <th>
                  <u>{total_amount(data.costSharingByAys)}</u>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CostSharing;
