import React, { useState } from "react";
import query from "../../queries/getAYS";
import RegistrationShow from "./RegistrationShow";
import _ from "lodash";
import { useQuery } from "@apollo/react-hooks";

function Registrations() {
  const [aysId, setAysId] = useState(0);
  const { loading, data } = useQuery(query);
  if (loading) return <p>Loading ...</p>;

  const handleChange = (e) => {
    if (e.target.value !== "null") {
      setAysId(e.target.value);
    }
  };

  if (_.isEmpty(data.studentAcademicYearSemesters))
    return <p className="error-info">No Registration Found</p>;

  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <div className="form-group">
          <label>Select Registration</label>
          <select
            name="ays_id"
            id="ays_id"
            onChange={handleChange}
            className="form-control select2 mb-2"
          >
            <option value="null">Please Select...</option>
            {data.studentAcademicYearSemesters.map(({ id, semesterName }) => (
              <option key={id} value={id}>
                {semesterName}
              </option>
            ))}
          </select>
        </div>

        {aysId !== 0 ? <RegistrationShow ays_id={aysId} /> : <p></p>}
      </div>
    </div>
  );
}

export default Registrations;
