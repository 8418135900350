import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { MY_DORM } from "./../../queries/getMyDorm";
import ListItem from "./../layouts/ListItem";
import _ from "lodash";

const Dormitory = (props) => {
  const { loading, data } = useQuery(MY_DORM);
  if (loading) return <p>Loading ...</p>;
  if (_.isEmpty(data.myDorm)) return <NoDormitory />;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-building pr-1"></span>Dormitory Info
        </h3>
      </div>

      <div className="card-body">
        <ul>
          <li>
            <ListItem label="Block" data={data.myDorm.bed.room.block.name} />
          </li>
          <li>
            <ListItem label="Room" data={data.myDorm.bed.room.name} />
          </li>
          <li>
            <ListItem label="Bed" data={data.myDorm.bed.name} />
          </li>
        </ul>
      </div>
    </div>
  );
};

const NoDormitory = () => {
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-building pr-1"></span>Dormitory Info
        </h3>
      </div>
      <div className="card-body row">
          No Dormitory Information Found.
      </div>
    </div>
  );
};

export default Dormitory;
