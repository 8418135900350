import gql from "graphql-tag";

export const HEADER_PROFILE = gql`
  {
    headerProfile {
      id
      idNumber
      classYear
      dormitoryView
      section
      program {
        id
        name
      }
      applicant {
        id
        person {
          id
          photoUrl
          fullName
        }
        admissionYear
        admission {
          id
          name
        }
      }
    }
  }
`;
