import gql from "graphql-tag";

export const LATEST_EVENTS = gql`
  {
    latestEvents {
      id
      event {
        title
      }
      startDate
      endDate
      academicYearSemester {
        semesterName
      }
      admissions {
        id
        name
      }
    }
  }
`;
