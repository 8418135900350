import React from "react";
import REGISTRATION_STATUS from "../../queries/getRegistrationStatus";
import REGISTRATION_SLIP from "../../queries/getRegistrationSlip";
import { useQuery } from "@apollo/react-hooks";
import HeaderProfile from "./../common/HeaderProfile";
import { Redirect } from "react-router-dom";
import Payment from "./Payment";
import { sweetAlert } from "./../common/SweetAlert";
var QRCode = require("qrcode.react");

const RegistrationSlip = (props) => {
  const { loading, data } = useQuery(REGISTRATION_STATUS, {
    fetchPolicy: "no-cache",
  });
  if (loading) return <p>Loading ...</p>;
  if (!data) {
    sweetAlert({ error: "You are not permitted for this operation." });
    return <Redirect to="/" />;
  }
  if (data.registrationStatus.not_permitted) {
    sweetAlert({ error: "You are not permitted for this operation." });
    return <Redirect to="/" />;
  }
  if (data.registrationStatus.eligibility) {
    sweetAlert({ error: data.registrationStatus.eligibility });
    return <Redirect to="/" />;
  }
  if (data.registrationStatus.can_view_registration_slip) return <Slip />;
  if (data.registrationStatus.eligibility)
    return <p className="bold">{data.registrationStatus.eligibility}</p>;
  if (data.registrationStatus.calendar_not_activated) return <Redirect to="registration-form" />;
  if (data.registrationStatus.can_register) return <Redirect to="registration-form" />;
  if (data.registrationStatus.payment_form)
    return <Payment props={props} message={data.registrationStatus.payment_form} />;
  return <div>{JSON.stringify(data.registrationStatus)}</div>;
};

const Slip = () => {
  const { loading, data } = useQuery(REGISTRATION_SLIP);
  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card card-primary">
      <div className="card-header bg-info">
        <h3 className="card-title">
          <span className="fas fa-money-newspaper pr-1"></span>Registration Slip
        </h3>
      </div>
      <div className="card-body print_area">
        <HeaderProfile />
        <p>
          <b>Academic Year Semester:</b>
          {data.registrationSlip.academicYearSemester.semesterName}
        </p>
        <div className="table-responsive">
          <table className="table  table-bordered text-nowrap">
            <thead>
              <tr>
                <th>Course Title</th>
                <th>Course Code</th>
                <th>Credit Hour</th>
                <th>Instructor</th>
              </tr>
            </thead>
            <tbody>
              {data.registrationSlip.courseEnrollments.map(({ id, course, instructorName }) => (
                <tr key={id}>
                  <td>{course.courseTitle}</td>
                  <td>{course.courseCode}</td>
                  <td>{course.creditHour}</td>
                  <td>{instructorName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-center pt-3">
          <QRCode value={data.registrationSlip.student.idNumber} />
        </div>
      </div>
      <div className="card-footer">
        <div>
          <button className="btn btn-sm btn-primary" onClick={() => window.print()}>
            Print Registration Slip
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationSlip;
