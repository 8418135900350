import { ErrorMessage } from "formik";
import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { REGION_QUERY } from "../../queries/constants";
import Zones from "./Zones";

export default function Regions() {
  const { loading, data } = useQuery(REGION_QUERY);
  const [regionId, setRegionId] = useState(0);

  const handleChange = (e) => {
    if (e.target.value !== "null") {
      setRegionId(e.target.value);
    }
  };
  if (loading) return <p>Loading ...</p>;
  return (
    <>
      <label htmlFor="regionId">Region</label>
      <select
        name="regionId"
        id="regionId"
        onChange={handleChange}
        className="form-control input-sm mb-2"
      >
        <option value="null">Please Select...</option>
        {data.region.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </select>
      <ErrorMessage name="zoneId" component="div" className="text-danger" />

      {regionId !== 0 ? <Zones region_id={regionId} /> : null}
    </>
  );
}
