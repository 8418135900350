import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import NotFound from "./NotFound";
import Curriculum from "./Curriculum";
import CourseAudit from "./CourseAudit";
import Payment from "./Payment";
import AssessmentResult from "./AssessmentResult";
import RegistrationHome from "./registrations/RegistrationHome";
import RegistrationForm from "./registrations/RegistrationForm";
import RegistrationSlip from "./registrations/RegistrationSlip";
import Transcript from "./Transcript";
import StudentProfile from "./StudentProfile";
import EnrollmentRecord from "./EnrollmentRecord";
import AcademicHistory from "./AcademicHistory";
import EditPerson from "./forms/EditPerson";
import EditApplicant from "./forms/EditApplicant";
import EditContactAddress from "./forms/EditContactAddress";
import CreateContactAddress from "./forms/CreateContactAddress";
import CreateEmergencyContact from "./forms/CreateEmergencyContact";
import EditEmergencyContact from "./forms/EditEmergencyContact";
import EditFamilyBackground from "./forms/EditFamilyBackground";
import EditPreferences from "./forms/EditPreferences";
import CreateFamilyBackground from "./forms/CreateFamilyBackground";

import CreateGradeComplaint from "./forms/CreateGradeComplaint";
import EditGradeComplaint from "./forms/EditGradeComplaint";

import EditEducation from "./forms/EditEducation";
import CreateEducation from "./forms/CreateEducation";
import EditExperience from "./forms/EditExperience";
import CreateExperience from "./forms/CreateExperience";
import AddDrop from "./../components/add-drop/AddDrop";
import WithdrawalForm from "./../components/Withdrawal/WithdrawalForm";
import Transfer from "./transfer/Transfer";
// import IdCard from "./layouts/IdCard";
import Dormitory from "./dormitory/Dormitory";
import ClearanceForm from "./clearances/ClearanceForm";
import Clearances from "./clearances/Clearances";
import CourseExemptionForm from "./course-exemption/CourseExemptionForm";
import CourseExemptionList from "./course-exemption/CourseExemptionList";
import StudentGradeComplaints from "./StudentGradeComplaints";
import Readmission from "./readmission/Readmission";
import Login from "./auth/Login";
import Navbar from "./layouts/Navbar";
import Sidebar from "./layouts/Sidebar";
import Footer from "./layouts/Footer";
import ForgotPassword from "./auth/ForgotPassword";
import PasswordEdit from "./auth/PasswordEdit";
import UpdatePassword from "./auth/UpdatePassword";

const App = (props) => {
  const [token, setToken] = useState(localStorage["access-token"]);

  if (!token) {
    if (props.location.pathname === "/auth/password/edit") {
      return (
        <div>
          <Route exact path="/auth/password/edit" component={PasswordEdit} />
        </div>
      );
    }
    if (props.location.pathname === "/forgot_password") {
      return (
        <div>
          <Route exact path="/forgot_password" component={ForgotPassword} />
        </div>
      );
    }
    return <Login setToken={(token) => setToken(token)} />;
  }

  return (
    <div className="wrapper">
      <Navbar />
      <Sidebar />
      <div className="content-wrapper">
        <section className="content pb-1 ">
          <div className="container-fluid">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/change-password" component={UpdatePassword} />
              <Route exact path="/curriculum" component={Curriculum} />
              <Route exact path="/registrations" component={RegistrationHome} />

              <Route exact path="/registration-form" component={RegistrationForm} />
              <Route exact path="/registration-slip" component={RegistrationSlip} />
              <Route exact path="/course-audit" component={CourseAudit} />
              <Route exact path="/assessment-result" component={AssessmentResult} />
              <Route exact path="/transcript" component={Transcript} />
              <Route exact path="/payment" component={Payment} />

              <Route exact path="/student_profile" component={StudentProfile} />
              <Route exact path="/enrollment_record" component={EnrollmentRecord} />
              <Route exact path="/academic_history" component={AcademicHistory} />

              <Route exact path="/student_profile/:id/edit_person" component={EditPerson} />
              <Route
                exact
                path="/student_profile/:personId/edit_applicant"
                component={EditApplicant}
              />
              <Route
                exact
                path="/student_profile/:personId/edit_contact_address"
                component={EditContactAddress}
              />
              <Route
                exact
                path="/student_profile/:personId/create_contact_address"
                component={CreateContactAddress}
              />
              <Route
                exact
                path="/student_profile/:personId/edit_emergency_contact"
                component={EditEmergencyContact}
              />
              <Route
                exact
                path="/student_profile/:personId/create_emergency_contact"
                component={CreateEmergencyContact}
              />
              <Route
                exact
                path="/student_profile/:id/edit_family_background"
                component={EditFamilyBackground}
              />
              <Route
                exact
                path="/student_profile/:personId/create_family_background"
                component={CreateFamilyBackground}
              />
              <Route exact path="/student_profile/:id/edit_education" component={EditEducation} />
              <Route
                exact
                path="/student_profile/:personId/create_education"
                component={CreateEducation}
              />
              <Route exact path="/student_profile/:id/edit_experience" component={EditExperience} />
              <Route
                exact
                path="/student_profile/:personId/edit_preferences"
                component={EditPreferences}
              />
              <Route
                exact
                path="/student_profile/:personId/create_experience"
                component={CreateExperience}
              />

              <Route
                exact
                path="/student_profile/:studentGradeId/create_grade_complaint"
                component={CreateGradeComplaint}
              />
              <Route
                exact
                path="/student_profile/:id/edit_grade_complaint"
                component={EditGradeComplaint}
              />

              <Route exact path="/complaint-results" component={StudentGradeComplaints} />

              <Route exact path="/add-drop" component={AddDrop} />
              <Route exact path="/withdrawals" component={WithdrawalForm} />
              <Route exact path="/transfer" component={Transfer} />
              <Route exact path="/dormitory" component={Dormitory} />
              <Route exact path="/clearance-form" component={ClearanceForm} />
              <Route exact path="/clearances" component={Clearances} />
              <Route exact path="/course-exemption-form" component={CourseExemptionForm} />
              <Route exact path="/course-exemption-list" component={CourseExemptionList} />
              <Route exact path="/readmission" component={Readmission} />
              <Route path="**" component={NotFound} />
            </Switch>
          </div>
        </section>
      </div>
      <aside className="control-sidebar control-sidebar-dark">
        <div className="p-3">
          <h5>Title</h5>
          <p>Sidebar content</p>
        </div>
      </aside>
      <Footer />
    </div>
  );
};

export default App;
