import React from "react";
import { STUDENT_TRANSCRIPT } from "../queries/getTranscript";
import ExemptedCourses from "./course-exemption/ExemptedCourses";
import _ from "lodash";
import { useQuery } from "@apollo/react-hooks";

function Transcript() {
  const { loading, data } = useQuery(STUDENT_TRANSCRIPT);
  if (loading) return <p>Loading ...</p>;
  if (
    _.isEmpty(data.studentTranscript) &&
    _.isEmpty(data.pendingCourseExemptions)
  )
    return <p className="error-info">No Transcript Found</p>;
  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <p className="text-info">
          * <strong>This is not official transcript</strong>
        </p>

        <ExemptedCourses data={data.pendingCourseExemptions} />

        {data.studentTranscript.map(
          ({
            id,
            academicYearSemester,
            classYear,
            courseEnrollments,
            curriculumType,
            gpa,
          }) => (
            <div key={id} className="mb-4">
              <h6 className="text-bold">
                {academicYearSemester.semesterName} [{classYear.name}]
              </h6>
              <div className="table-responsive">
                <table className="table table-striped text-nowrap">
                  <thead bgcolor="brown" className="thead text-white">
                    <tr>
                      <th>Code</th>
                      <th>Course Title</th>
                      {curriculumType === "Conventional" ? (
                        <th>Credit Hour</th>
                      ) : (
                        <th>Ects</th>
                      )}
                      <th>Grade</th>
                      <th>Points</th>
                      <th>Averages</th>
                    </tr>
                  </thead>
                  <tbody>
                    {courseEnrollments.map(({ id, course, studentGrade }) => (
                      <tr key={id}>
                        <td>{course.courseCode}</td>
                        <td>{course.courseTitle}</td>
                        {curriculumType === "Conventional" ? (
                          <td>{course.creditHour}</td>
                        ) : (
                          <td>{course.ects}</td>
                        )}
                        <td>{studentGrade ? studentGrade.letterGrade : "-"}</td>
                        <td>{studentGrade ? studentGrade.gradePoint : "-"}</td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot
                    bgcolor="darkgray"
                    align="left"
                    className="tfoot text-bold"
                  >
                    <tr>
                      <td align="right" colSpan="2">
                        Semester Total
                      </td>

                      <td>{gpa ? gpa.currentSemesterTotalCreditHour : "-"}</td>
                      <td></td>
                      <td>{gpa ? gpa.currentSemesterTotalGradePoint : "-"}</td>
                      <td>{gpa ? gpa.semesterGpa : "-"}</td>
                    </tr>
                    <tr>
                      <td align="right">
                        Academic Status: {gpa ? gpa.status : "-"}
                      </td>
                      <td align="right">Total</td>
                      <td>{gpa ? gpa.totalCreditHour : "-"}</td>
                      <td> </td>
                      <td>{gpa ? gpa.totalGradePoint : "-"}</td>
                      <td>{gpa ? gpa.cumulativeGpa : "-"}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default Transcript;
