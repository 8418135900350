import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { DELETE_EDUCATION_MUTATION } from "../../mutations/delete/byId";

function Educations(props) {
  const { loading, data } = useQuery(props.query, {
    variables: { personId: props.person_id },
  });
  const [deleteEducation] = useMutation(DELETE_EDUCATION_MUTATION, {
    update(cache, { data: { deleteEducation } }) {
      const { getEducations } = cache.readQuery({
        query: props.query,
        variables: { personId: props.person_id },
      });
      cache.writeQuery({
        query: props.query,
        variables: { personId: props.person_id },
        data: {
          getEducations: getEducations.filter(
            (education) => education.id !== deleteEducation.id
          ),
        },
      });
    },
  });

  if (loading) return <p>Loading ...</p>;
  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Institution</th>
                <th>Study Field</th>
                <th>Qualification</th>
                <th>From</th>
                <th>To</th>
                <th>Grade</th>
                <th>Scale</th>
                <th>Education Type</th>
                <th>Award Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.getEducations.map(
                ({
                  id,
                  institution,
                  studyField,
                  qualification,
                  from,
                  to,
                  grade,
                  scale,
                  educationType,
                  awardType,
                }) => (
                  <tr key={id}>
                    <td>{institution}</td>
                    <td>{studyField}</td>
                    <td>{qualification}</td>
                    <td>{from}</td>
                    <td>{to}</td>
                    <td>{grade}</td>
                    <td>{scale}</td>
                    <td>{educationType}</td>
                    <td>{awardType}</td>
                    <td>
                      <Link to={`/student_profile/${id}/edit_education`}>
                        Edit
                      </Link>
                      {" | "}
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          if (
                            window.confirm("Are you sure you wish to delete?")
                          )
                            deleteEducation({ variables: { id: id } });
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer">
        <Link to={`/student_profile/${props.person_id}/create_education`}>
          New
        </Link>
      </div>
    </div>
  );
}

export default Educations;
