import gql from "graphql-tag";

export const READMISSION_REQUEST = gql`
  mutation readmissionRequest(
    $reason: String!
    $other: String
    $academicYear: Int!
    $semester: Int!
    $leavingDate: String
    $academicYearSemesterId: Int!
    $registrationId: Int!
  ) {
    readmissionRequest(
      reason: $reason
      other: $other
      academicYear: $academicYear
      semester: $semester
      leavingDate: $leavingDate
      academicYearSemesterId: $academicYearSemesterId
      registrationId: $registrationId
    )
  }
`;
