import React from "react";
import query from "../../queries/getAddDrops";
import _ from "lodash";
import { useQuery } from "@apollo/react-hooks";

function AddDrops() {
  const { loading, data } = useQuery(query);
  if (loading) return <p>Loading ...</p>;
  if (_.isEmpty(data.addDrops)) return <p className="error-info">No Add-Drop History</p>;

  return (
    <div className="card no-shadow mb-0">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Course</th>
                <th>C.hour</th>
                <th>ECTS</th>
                <th>Instructor</th>
                <th>Section</th>
                <th>Semester</th>
                <th>Request</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data.addDrops.map(
                ({
                  id,
                  requestFor,
                  confirmationStatus,
                  course,
                  courseAssignment,
                  section,
                  registration,
                }) => (
                  <tr key={id}>
                    <td>{course.codeAndTitle}</td>
                    <td>{course.creditHour}</td>
                    <td>{course.ects}</td>
                    {courseAssignment === null ? (
                      <React.Fragment>
                        <td></td>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <td>{courseAssignment.instructorName}</td>
                      </React.Fragment>
                    )}

                    {section === null ? (
                      <React.Fragment>
                        <td></td>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <td>{section.name}</td>
                      </React.Fragment>
                    )}

                    <td>{registration.academicYearSemester.semesterName}</td>
                    <td>{requestFor}</td>
                    <td>{confirmationStatus}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AddDrops;
